import { MdPieChart, MdOutlineGroups2 } from "react-icons/md";
import { MdAccountBalanceWallet } from "react-icons/md";
import { FaBoxOpen } from "react-icons/fa";
import { HiCurrencyDollar } from "react-icons/hi2";
// import pan from "./assets/image/pan.png";
export const sidebarMenu = [
  {
    id: 1,
    icon: MdPieChart,
    name: "Home",
    slux: "/",
  },

  {
    id: 2,
    icon: FaBoxOpen,
    name: "Products",
    subtitle: [
      {
        name1: "Product List",
        slux: "/productList",
      },
      {
        name1: "Add",
        slux: "/addProduct",
      },
    ],
  },
  {
    id: 3,
    icon: HiCurrencyDollar,
    name: "Finance",
    slux: "finance",
  },
  {
    id: 3,
    icon: MdAccountBalanceWallet,
    name: "Accounts & Settings",
    slux: "profile",
  },

  // {
  //   id: 4,
  //   icon: MdOutlineGroups2,
  //   name: "Order",
  //   subtitle: [
  //     {
  //       name1: "Sales",
  //       slux: "/sales",
  //     },
  //     {
  //       name1: "Booking",
  //       slux: "/Booking",
  //     },
  //   ],
  // },
  {
    id: 5,
    icon: MdOutlineGroups2,
    name: "Gig",
    subtitle: [
      {
        name1: "Gig List",
        slux: "/gigList",
      },
      {
        name1: "Gig Add",
        slux: "/addGig",
      },
      {
        name1: "Topup",
        slux: "/topup",
      },
    ],
  },
  {
    id: 6,
    icon: MdOutlineGroups2,
    name: "Sales",
    subtitle: [
      {
        name1: "Order",
        slux: "/order",
      },
      {
        name1: "Booking",
        slux: "/booking",
      },
      {
        name1: "Transactions",
        slux: "/transactions",
      },
    ],
  },
  // {
  //   id: 6,
  //   icon: FaShoppingCart,
  //   name: "Order",
  //   slux: "/order",
  // },
  // {
  //   id: 7,
  //   icon: BsCalendar2CheckFill,
  //   name: "Booking",
  //   slux: "/booking",
  // },
  // {
  //   id: 8,
  //   icon: FaChartLine,
  //   name: "Purcheses",
  //   slux: "/sales",
  // },
  // {
  //   id: 6,
  //   icon: MdOutlineGroups2,
  //   name: "Package",
  //   subtitle: [
  //     {
  //       name1: "Package Add",
  //     },
  //     {
  //       name1: "Package List",
  //     },
  //     {
  //       name1: "Patner Add",
  //     },
  //     {
  //       name1: "Package Patners",
  //     },
  //   ],
  // },
  // {
  //   id: 7,
  //   icon: IoIdCard,
  //   name: "Post",
  //   subtitle: [
  //     {
  //       name1: "Post",
  //       slux: "/post_table",
  //     },
  //   ],
  // },
  // {
  //   id: 2,
  //   icon: MdOutlineGroups2,
  //   name: "Category",
  //   // subtitle: [
  //   //   {
  //   //     name1: "All Categories",
  //   //   },
  //   //   {
  //   //     name1: "Add Category",
  //   //   },
  //   //   {
  //   //     name1: "Add Sub Category",
  //   //   },
  //   // ],
  //   slux: "/categories",
  // },
  // {
  //   id: 2,
  //   icon: IoIdCard,
  //   name: "Account",
  //   subtitle: [
  //     {
  //       name1: "Account",
  //     },
  //     {
  //       name1: "Affilitor Account",
  //     },
  //   ],
  // },
  // {
  //   id: 2,
  //   icon: MdOutlineGroups2,
  //   name: "Notification",
  //   subtitle: [
  //     {
  //       name1: "Add Notification",
  //     },
  //   ],
  // },
];
export const notification = [
  {
    id: 1,
    title:
      "Mi malesuada et orci at eu consectetur sed orci augue. Arcu in odio morbi ac viverra dictum. Elit augue egestas convallis arcu lacus.",
    date: "30 minutes ago",
  },
  {
    id: 2,
    title:
      "Mi malesuada et orci at eu consectetur sed orci augue. Arcu in odio morbi ac viverra dictum.",
    date: "12 hours ago",
  },
  {
    id: 3,
    title:
      "Mi malesuada et orci at eu consectetur sed orci augue. Arcu in odio morbi ac viverra dictum.",
    date: "16 October, 6PM",
  },
];
// export const information = [
//   {
//     id: 1,
//     title: "Number Of Business",
//     value: "74",
//   },
//   {
//     id: 2,
//     title: "Number Of User",
//     value: "1937",
//   },
//   {
//     id: 3,
//     title: "Affilated Products",
//     value: "2",
//   },
//   {
//     id: 4,
//     title: "Total Products",
//     value: "2",
//   },
//   {
//     id: 5,
//     title: "User Verification",
//     value: "1851",
//   },

//   {
//     id: 6,
//     title: "Business Verification",
//     value: "10",
//   },
//   {
//     id: 7,
//     title: "Number Of Sales",
//     value: "0",
//   },
//   {
//     id: 8,
//     title: "Total Sales",
//     value: "0",
//   },
//   {
//     id: 9,
//     title: "No. Of Active User",
//     value: "1",
//   },
//   {
//     id: 10,
//     title: "No. Of Marketplace",
//     value: "7",
//   },
//   {
//     id: 11,
//     title: "No. Of Affiliation",
//     value: "35",
//   },
//   {
//     id: 12,
//     title: "No. Of Post",
//     value: "1017",
//   },
//   {
//     id: 13,
//     title: "Watchtime Anonymous",
//     value: "9 h 24 m 0 s",
//   },
//   {
//     id: 1,
//     title: "Watchtime User",
//     value: "39 h 32 m 1 s",
//   },
// ];
export const TABLE_HEAD = [
  {
    id: 1,
    label: "Product Name",
  },
  {
    id: 2,
    label: "User Details",
  },
  {
    id: 3,
    label: "Business Name",
  },
  {
    id: 4,
    label: "Date",
  },
  {
    id: 5,
    label: "Payment",
  },
  {
    id: 6,
    label: "Status",
  },
  {
    id: 7,
    label: "",
  },
];

export const TABLE_HEAD_POST_REPORT = [
  {
    id: 1,
    label: "Reported Post",
  },
  {
    id: 2,
    label: "Reported To",
  },
  {
    id: 3,
    label: "Reported By",
  },
  {
    id: 4,
    label: "Date",
  },

  {
    id: 6,
    label: "Status",
  },
  {
    id: 7,
    label: "",
  },
];

export const TABLE_ROWS = [
  {
    img: "https://image.lexica.art/full_webp/24afdea4-3a9d-42f7-87d5-30b48f9afb34",
    name: "Adidas Core Black Lite Rcer...",
    email: "Size: S | Color : Red | Qty: 1",
    job: "Binayak Dhakal",
    businessLable: "Labels Nepal",
    date: "May 26, 2019",
    time: "6:30 PM",
    payment: "Paid",
    status: "CANCELLED",
  },
  {
    img: "https://image.lexica.art/full_webp/24afdea4-3a9d-42f7-87d5-30b48f9afb34",
    name: "Adidas Core Black Lite Rcer...",
    email: "Size: S | Color : Red | Qty: 1",
    job: "Binayak Dhakal",
    businessLable: "Labels Nepal",
    date: "May 26, 2019",
    time: "6:30 PM",
    payment: "Paid",
    status: "CANCELLED",
  },
  {
    img: "https://image.lexica.art/full_webp/24afdea4-3a9d-42f7-87d5-30b48f9afb34",
    name: "Adidas Core Black Lite Rcer...",
    email: "Size: S | Color : Red | Qty: 1",
    job: "Binayak Dhakal",
    businessLable: "Labels Nepal",
    date: "May 26, 2019",
    time: "6:30 PM",
    payment: "Paid",
    status: "CANCELLED",
  },
  {
    img: "https://image.lexica.art/full_webp/24afdea4-3a9d-42f7-87d5-30b48f9afb34",
    name: "Adidas Core Black Lite Rcer...",
    email: "Size: S | Color : Red | Qty: 1",
    job: "Binayak Dhakal",
    businessLable: "Labels Nepal",
    date: "May 26, 2019",
    time: "6:30 PM",
    payment: "Paid",
    status: "CANCELLED",
  },
  {
    img: "https://image.lexica.art/full_webp/24afdea4-3a9d-42f7-87d5-30b48f9afb34",
    name: "Adidas Core Black Lite Rcer...",
    email: "Size: S | Color : Red | Qty: 1",
    job: "Binayak Dhakal",
    businessLable: "Labels Nepal",
    date: "May 26, 2019",
    time: "6:30 PM",
    payment: "Paid",
    status: "CANCELLED",
  },
];

export const TABLE_ROWS_POST_Report = [
  {
    img: "https://image.lexica.art/full_webp/24afdea4-3a9d-42f7-87d5-30b48f9afb34",
    name: "Adidas Core Black Lite Rcer...",
    job: "Binayak Dhakal",
    businessLable: "Labels Nepal",
    date: "May 26, 2019",
    time: "6:30 PM",
    payment: "Paid",
    status: "Pending",
  },
  {
    img: "https://image.lexica.art/full_webp/24afdea4-3a9d-42f7-87d5-30b48f9afb34",
    name: "Adidas Core Black Lite Rcer...",
    job: "Binayak Dhakal",
    businessLable: "Labels Nepal",
    date: "May 26, 2019",
    time: "6:30 PM",
    payment: "Paid",
    status: "Pending",
  },
  {
    img: "https://image.lexica.art/full_webp/24afdea4-3a9d-42f7-87d5-30b48f9afb34",
    name: "Adidas Core Black Lite Rcer...",
    job: "Binayak Dhakal",
    businessLable: "Labels Nepal",
    date: "May 26, 2019",
    time: "6:30 PM",
    payment: "Paid",
    status: "Pending",
  },
  {
    img: "https://image.lexica.art/full_webp/24afdea4-3a9d-42f7-87d5-30b48f9afb34",
    name: "Adidas Core Black Lite Rcer...",
    job: "Binayak Dhakal",
    businessLable: "Labels Nepal",
    date: "May 26, 2019",
    time: "6:30 PM",
    payment: "Paid",
    status: "Pending",
  },
  {
    img: "https://image.lexica.art/full_webp/24afdea4-3a9d-42f7-87d5-30b48f9afb34",
    name: "Adidas Core Black Lite Rcer...",
    job: "Binayak Dhakal",
    businessLable: "Labels Nepal",
    date: "May 26, 2019",
    time: "6:30 PM",
    payment: "Paid",
    status: "Pending",
  },
];

export const TABLE_ROWS1 = [
  {
    name: "Binayak Dhakal",
    job: "Affillator",
    color: "#9C00D3",
  },
  {
    name: "Aayush Maktan",
    job: "User",
    color: "#5B3DE8",
  },
  {
    name: "Flutter Team",
    job: "User",
    color: "#5B3DE8",
  },
  {
    name: "Lexi Uplift",
    job: "Affillator",
    color: "#9C00D3",
  },
  {
    name: "Lexi Uplift",
    job: "Affillator",
    color: "#9C00D3",
  },
];
export const TABLE_ROWS2 = [
  {
    name1: "Uplift Solutions Pvt. Ltd.",
    job1: "Pending",
    color1: "#5B3DE8",
  },
  {
    name1: "Postaam Infosys",
    job1: "Verified",
    color1: "#367641",
  },
  {
    name1: "Neco Jobs Pvt. Lted.",
    job1: "Rejected",
    color1: "#CF122E",
  },
  {
    name1: "Uplift Solution Pvt. Ltd.",
    job1: "Processing",
    color1: "#FFBA49",
  },
  {
    name1: "Postaam Infosys",
    job1: "Verified",
    color1: "#367641",
  },
];
export const RECENT_POST = [
  {
    name: "New Bhojpuri Video Song 2021 Dj Gana",
    job: "Affilliator",
    color: "#9C00D3",
  },
  {
    name: "Dilwal-Superhit Full Bhojpuri Movie - Khes...",
    job: "User",
    color: "#9C00D3",
  },
  {
    name: "Chat Deni Maar Deli - Manoj Tiwari Hit Bhojpuri..",
    job: "User",
    color: "#9C00D3",
  },
  {
    name: "O Beta Ji O Babuji Kismat Ki Hawa Kabhi...",
    job: "Auction",
    color: "#9C00D3",
  },
  {
    name: "Teri Mitti - Lyrical | Kesari | Akshay Kumar...",
    job: "Marketplace",
    color: "#9C00D3",
  },
];
export const WEEKLY_SALES = [
  {
    name: "Postaam Infosys",
    job: "Rs.42,000",
  },
  {
    name: "Youtube Pty Ltd",
    job: "Rs.42,000",
  },
  {
    name: "Uplift Solution Pvt. Ltd.",
    job: "Rs.42,000",
  },
  {
    name: "Neco Jobs Ptv. Ltd.",
    job: "Rs.42,000",
  },
  {
    name: "Jonny Sin Pty. Ltd.",
    job: "Rs.42,000",
  },
];
export const USER_TABLE_HEAD = [
  {
    id: 1,
    label: "User Name",
  },
  {
    id: 2,
    label: "Phone",
  },
  {
    id: 3,
    label: "Email",
  },
  {
    id: 4,
    label: "Verification",
  },
  {
    id: 5,
    label: "Stauts",
  },
  {
    id: 6,
    label: "Role",
  },
];
export const USER_TABLE_ROWS = [
  {
    img: "https://image.lexica.art/full_webp/a16f7ea7-67cd-4441-a2c7-59501a704e33",
    name: "Binayak Dhakal",
    phone: 9851157726,
    email: "youmeetashish@gmail.com",
    time: "6:30 PM",
    payment: "Paid",
    status: "USER",
    color: "#9C00D3",
  },
  {
    img: "https://image.lexica.art/full_webp/a16f7ea7-67cd-4441-a2c7-59501a704e33",
    name: "Binayak Dhakal",
    phone: 9851157726,
    email: "youmeetashish@gmail.com",
    time: "6:30 PM",
    payment: "Paid",
    status: "AFFILIATOR",
    color: "#5B3DE8",
  },
  {
    img: "https://image.lexica.art/full_webp/a16f7ea7-67cd-4441-a2c7-59501a704e33",
    name: "Binayak Dhakal",
    phone: 9851157726,
    email: "youmeetashish@gmail.com",
    time: "6:30 PM",
    payment: "Paid",
    status: "AFFILIATOR",
    color: "#5B3DE8",
  },
  {
    img: "https://image.lexica.art/full_webp/a16f7ea7-67cd-4441-a2c7-59501a704e33",
    name: "Binayak Dhakal",
    phone: 9851157726,
    email: "youmeetashish@gmail.com",
    time: "6:30 PM",
    payment: "Paid",
    status: "USER",
    color: "#9C00D3",
  },
  {
    img: "https://image.lexica.art/full_webp/a16f7ea7-67cd-4441-a2c7-59501a704e33",
    name: "Binayak Dhakal",
    phone: 9851157726,
    email: "youmeetashish@gmail.com",
    time: "6:30 PM",
    payment: "Paid",
    status: "USER",
    color: "#9C00D3",
  },
  {
    img: "https://image.lexica.art/full_webp/a16f7ea7-67cd-4441-a2c7-59501a704e33",
    name: "Binayak Dhakal",
    phone: 9851157726,
    email: "youmeetashish@gmail.com",
    time: "6:30 PM",
    payment: "Paid",
    status: "AFFILIATOR",
    color: "#5B3DE8",
  },
  {
    img: "https://image.lexica.art/full_webp/a16f7ea7-67cd-4441-a2c7-59501a704e33",
    name: "Binayak Dhakal",
    phone: 9851157726,
    email: "youmeetashish@gmail.com",
    time: "6:30 PM",
    payment: "Paid",
    status: "USER",
    color: "#9C00D3",
  },
  {
    img: "https://image.lexica.art/full_webp/a16f7ea7-67cd-4441-a2c7-59501a704e33",
    name: "Binayak Dhakal",
    phone: 9851157726,
    email: "youmeetashish@gmail.com",
    time: "6:30 PM",
    payment: "Paid",
    status: "USER",
    color: "#9C00D3",
  },
];
export const TABLE_HEAD_CATEGORY = [
  { id: 1, label: "Category" },
  { id: 2, label: "Number of items", align: "right" },

  { id: 3, label: "Action" },
  { id: 4, label: "", align1: "block" },
];

// export const TABLE_ROWS_CATEGORY = [
//   {
//     name: "Camera",
//     job: "32",
//   },
//   {
//     name: "Desktop",
//     job: "12",
//   },
//   {
//     name: "Software",
//     job: "12",
//   },
//   {
//     name: "Fashion > Mens clothing",
//     job: "12",
//   },
//   {
//     name: "Fashion > Kids clothing",
//     job: "12",
//   },
//   {
//     name: "Grocery >  Sweets",
//     job: "12",
//   },
//   {
//     name: "Grocery >  Vegetables",
//     job: "12",
//   },
//   {
//     name: "Grocery >  Fruits",
//     job: "12",
//   },
// ];
export const PARENT_CATEGORY = [
  { value: "Electronics", label: "Electronics", id: 1 },
  { value: "Clothing", label: "Clothing", id: 2 },
  { value: "Food", label: "Food", id: 3 },
  { value: "Medicine", label: "Medicine", id: 4 },
  { value: "Gaming", label: "Gaming", id: 5 },
  { value: "Toys", label: "Toys", id: 6 },
  { value: "Accessories", label: "Accessories", id: 7 },
];

export const BUSINESS_HEAD = [
  {
    id: 1,
    label: "Business Name",
  },
  {
    id: 2,
    label: "Email",
  },
  {
    id: 3,
    label: "Phone",
  },
  {
    id: 4,
    label: "Subscription",
  },
  {
    id: 5,
    label: "Verification",
  },
  {
    id: 6,
    label: "Status",
  },
  {
    id: 7,
    label: "Role",
  },
];
export const BUSINESS_TABLE_ROW = [
  {
    img: "https://image.lexica.art/full_webp/06b12de8-8d0d-4128-9938-38e49fc7f1df",
    name: "Uplift Solutions Pvt. Ltd.",
    phone: 9851157726,
    email: "youmeetashish@gmail.com",
    status: "B2C",
    color: "#9C00D3",
    Subscription: "Starter",
  },
  {
    img: "https://image.lexica.art/full_webp/06b12de8-8d0d-4128-9938-38e49fc7f1df",
    name: "Uplift Solutions Pvt. Ltd.",
    phone: 9851157726,
    email: "youmeetashish@gmail.com",
    status: "B2C",
    color: "#9C00D3",
    Subscription: "Mid",
  },
  {
    img: "https://image.lexica.art/full_webp/06b12de8-8d0d-4128-9938-38e49fc7f1df",
    name: "Uplift Solutions Pvt. Ltd.",
    phone: 9851157726,
    email: "youmeetashish@gmail.com",
    status: "B2B",
    color: "#5B3DE8",
    Subscription: "Expert",
  },
  {
    img: "https://image.lexica.art/full_webp/06b12de8-8d0d-4128-9938-38e49fc7f1df",
    name: "Uplift Solutions Pvt. Ltd.",
    phone: 9851157726,
    email: "youmeetashish@gmail.com",
    status: "B2C",
    color: "#9C00D3",
    Subscription: "Starter",
  },
  {
    img: "https://image.lexica.art/full_webp/06b12de8-8d0d-4128-9938-38e49fc7f1df",
    name: "Uplift Solutions Pvt. Ltd.",
    phone: 9851157726,
    email: "youmeetashish@gmail.com",
    status: "B2B",
    color: "#5B3DE8",
    Subscription: "Starter",
  },
  {
    img: "https://image.lexica.art/full_webp/06b12de8-8d0d-4128-9938-38e49fc7f1df",
    name: "Uplift Solutions Pvt. Ltd.",
    phone: 9851157726,
    email: "youmeetashish@gmail.com",
    status: "B2C",
    color: "#9C00D3",
    Subscription: "Starter",
  },
  {
    img: "https://image.lexica.art/full_webp/06b12de8-8d0d-4128-9938-38e49fc7f1df",
    name: "Uplift Solutions Pvt. Ltd.",
    phone: 9851157726,
    email: "youmeetashish@gmail.com",
    status: "B2B",
    color: "#5B3DE8",
    Subscription: "Mid",
  },
  {
    img: "https://image.lexica.art/full_webp/06b12de8-8d0d-4128-9938-38e49fc7f1df",
    name: "Uplift Solutions Pvt. Ltd.",
    phone: 9851157726,
    email: "youmeetashish@gmail.com",
    status: "B2C",
    color: "#9C00D3",
    Subscription: "Expert",
  },
];
export const USER_VERIFICATION_TABLE_HEAD = [
  {
    id: 1,
    label: "User Name",
  },
  {
    id: 2,
    label: "Phone",
  },
  {
    id: 3,
    label: "Email",
  },
  {
    id: 4,
    label: "Verification",
  },

  {
    id: 5,
    label: "",
  },
];
export const USER_POST_TABLE_HEAD = [
  {
    id: 1,
    label: "Post Title",
  },
  {
    id: 2,
    label: "Post Type",
  },
  {
    id: 3,
    label: "Image",
  },
  {
    id: 4,
    label: "Videos",
  },
  {
    id: 5,
    label: "User Name",
  },
  {
    id: 6,
    label: "Views",
  },
  {
    id: 7,
    label: "Likes",
  },
  {
    id: 8,
    label: "Comments",
  },
  {
    id: 9,
    label: "Shares",
  },
  {
    id: 10,
    label: "Tags",
  },
  {
    id: 11,
    label: "Reports",
  },
];
export const USER_VERIFICATION_TABLE_ROWS = [
  {
    img: "https://image.lexica.art/full_webp/4f8a8a47-e066-4b39-88ed-63230aad76a3",
    name: "Binayak Dhakal",
    phone: 9851157726,
    email: "youmeetashish@gmail.com",
    status: "Pending",
    color: "#FFC807",
  },
  {
    img: "https://image.lexica.art/full_webp/4f8a8a47-e066-4b39-88ed-63230aad76a3",
    name: "Binayak Dhakal",
    phone: 9851157726,
    email: "youmeetashish@gmail.com",
    status: "Pending",
    color: "#FFC807",
  },
  {
    img: "https://image.lexica.art/full_webp/4f8a8a47-e066-4b39-88ed-63230aad76a3",
    name: "Binayak Dhakal",
    phone: 9851157726,
    email: "youmeetashish@gmail.com",
    status: "Not Verified",
    color: "#D60F0F",
  },
  {
    img: "https://image.lexica.art/full_webp/4f8a8a47-e066-4b39-88ed-63230aad76a3",
    name: "Binayak Dhakal",
    phone: 9851157726,
    email: "youmeetashish@gmail.com",
    status: "Pending",
    color: "#FFC807",
  },
  {
    img: "https://image.lexica.art/full_webp/4f8a8a47-e066-4b39-88ed-63230aad76a3",
    name: "Binayak Dhakal",
    phone: 9851157726,
    email: "youmeetashish@gmail.com",
    status: "In Progress",
    color: "#FF7E07",
  },
  {
    img: "https://image.lexica.art/full_webp/4f8a8a47-e066-4b39-88ed-63230aad76a3",
    name: "Binayak Dhakal",
    phone: 9851157726,
    email: "youmeetashish@gmail.com",
    status: "Not Verified",
    color: "#D60F0F",
  },
  {
    img: "https://image.lexica.art/full_webp/4f8a8a47-e066-4b39-88ed-63230aad76a3",
    name: "Binayak Dhakal",
    phone: 9851157726,
    email: "youmeetashish@gmail.com",
    status: "Pending",
    color: "#FFC807",
  },
  {
    img: "https://image.lexica.art/full_webp/4f8a8a47-e066-4b39-88ed-63230aad76a3",
    name: "Binayak Dhakal",
    phone: 9851157726,
    email: "youmeetashish@gmail.com",
    payment: "Paid",
    status: "In Progress",
    color: "#FF7E07",
  },
];
export const USER_NAME = [
  {
    id: 1,
    name: "Binayak Dhakal",
    phone: "9851157726",
    email: "	youmeetashish@gmail.com",
    verification: "PENDING",
  },
];
export const USER_DOCUMENT = [
  {
    id: 1,
    image:
      "https://image.lexica.art/full_webp/4f8a8a47-e066-4b39-88ed-63230aad76a3",
    title: "Profile Photo",
  },
  {
    id: 2,
    image:
      "https://image.lexica.art/full_webp/4f8a8a47-e066-4b39-88ed-63230aad76a3",
    title: "Citizenship",
  },
  {
    id: 3,
    image:
      "https://image.lexica.art/full_webp/4f8a8a47-e066-4b39-88ed-63230aad76a3",
    title: "PAN",
  },
];
export const TABLE_HEAD_GIG_LIST = [
  "S/N",
  "Gig Title",
  "No. of Creator",
  "Git Type",
  "No. of img/vid.",
  // "Timeframe",
  // "Ad Duration",
  // "No. of View",
  // "No. of engagment",
  "Created At",
  "Action",
];

export const TABLE_ROWS_Package_TABLE = [
  {
    name: "No. of Creator",
    job: "2",
    date: "5",
    platinum: "7",
  },
  {
    name: "Duration",
    job: "7 Day",
    date: "10 Day",
    platinum: "20 Day",
  },
  {
    name: "Content Per Creator",
    job: "Photo 3 Video 0",
    date: "Photo 3 Video 1 | Both Photo 2 - Video 1",
    platinum: "Photo 3 Video 1 | Both Photo 2 - Video 1",
  },
  {
    name: "Ads Run Per Period",
    job: "1 time",
    date: "2 times",
    platinum: "2 times",
  },
  {
    name: "Content Revision",
    job: "1",
    date: "2",
    platinum: "2",
  },
  {
    name: "Boosting",
    subName: "(equal to package amount)",
    job: "Free",
    date: "Free",
    platinum: "Free",
  },
];

export const TABLE_ORDER_HEAD = [
  "S/N",
  "Order Number",
  "Total Quantity",
  "Payment Method",
  "Total Price",
  "Data",
  "Action",
];
export const TABLE_ORDER_BOOK_HEAD = [
  "S/N",
  "Booking Number",
  "No. of People",
  "Total Amount",
  "Date",
  "Action",
];

// order table (order.tsx)

export const TABLE_HEAD_ORDER = [
  "Sub Order",
  "Product Name",
  "Product",
  "Date",
  "Status",
  "Payment Method",
  "Payment Status",
  "Total",
  "Action",
];

// Booking table (booking.tsx)
export const TABLE_HEAD_BOOKING = [
  "Booking Number",
  "No. of People",
  "Total Amountt",
  "Date",
  "Action",
];

// SubBusinessList listing (subBusinessList.tsx)
export const SUB_BUSINESS_LABEL = [
  {
    id: 1,
    label: "Legal Name",
  },
  {
    id: 2,
    label: "Owner",
  },
  {
    id: 3,
    label: "Business Category",
  },
  {
    id: 4,
    label: "Address",
  },
  {
    id: 5,
    label: "Region",
  },
  {
    id: 6,
    label: "Zone",
  },
  {
    id: 7,
    label: "Country",
  },
  {
    id: 8,
    label: "Area",
  },
  {
    id: 9,
    label: "Person in Charge",
  },
  {
    id: 10,
    label: "Person in Charge Email",
  },
  {
    id: 11,
    label: "Person in Charge Phone",
  },
  {
    id: 12,
    label: "Business PAN/VAT",
  },
];

export const FINANCE_SALES_LIST = [
  "Transaction ID",
  "Method",
  "Amount",
  "Status",
];
