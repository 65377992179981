import { Button, Spinner } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import {
  CountryListProps,
  ProvinceListProps,
  RootBusinessProfile,
  RootBusinessProfileUpdate,
  getBusinessProfile,
  getCountryList,
  getProvinceList,
  putSellerAccount,
} from "../../../../utils/business";
import { RxCross2 } from "react-icons/rx";
import { RiImageAddFill } from "react-icons/ri";
type FormValue = {
  businessName: string;
  personInChargePhone: string;
  personInChargeName: string;
  personInChargeEmail: string;
  country: string;
  region: string;
  zone: string;
  city: string;
  area: string;
  address: string;
  registrationNumber: string;
  vatNumber: string;
  categories: string[];
  profileFile: File[];
  registerationFile: File[];
  vatFile: File[];
};

interface BusinessAccountProps {
  businessProfile: RootBusinessProfile;
  handleOpen: any;
}

const BusinessAccount = ({
  businessProfile,
  handleOpen,
}: BusinessAccountProps) => {
  const [loading, setLoading] = useState(false);
  const [loadingP, setLoadingP] = useState(true);
  const form = useForm<FormValue>();
  const { register, handleSubmit, formState, setValue } = form;
  const { errors } = formState;
  const [countryList, setCountryList] = useState<CountryListProps[]>([]);
  const [provinceList, setProvinceList] = useState<ProvinceListProps[]>([]);
  const [selectedImageFiles, setSelectedImageFiles] = useState<File[]>([]);
  const [
    selectedBusinessRegistrationFiles,
    setSelectedBusinessRegistrationFiles,
  ] = useState<File[]>([]);
  const [selectedBusinessVatFiles, setSelectedBusinessVatFiles] = useState<
    File[]
  >([]);
  const [businessinfo, setBusinessInfo] = useState<RootBusinessProfile>();
  const [cunt, setCunt] = useState("");
  useEffect(() => {
    (async () => {
      try {
        let resCountry = await getCountryList();
        setCountryList(resCountry);

        let resProvince = await getProvinceList();
        setProvinceList(resProvince);

        setValue("businessName", businessProfile.results.businessName);
        setValue(
          "personInChargeName",
          businessProfile.results.personInChargeName
        );
        setValue(
          "personInChargePhone",
          businessProfile.results.personInChargePhone
        );
        setValue(
          "personInChargeEmail",
          businessProfile.results.personInChargeEmail
        );
        setValue("country", businessProfile.results.country);
        setValue("region", businessProfile.results.region);
        setValue("zone", businessProfile.results.zone);
        setValue("city", businessProfile.results.city);
        setValue("area", businessProfile.results.area);
        setValue("address", businessProfile.results.address);
        setValue(
          "registrationNumber",
          businessProfile.results.registrationNumber
        );
        setValue("vatNumber", businessProfile.results.vatNumber);
        setLoadingP(false);
      } catch (error) {
        console.log("Error fetching seller account:", error);
      }
    })();
  }, [setValue]);
  const onsubmit = async (data: FormValue) => {
    if (!loading) {
      setLoading(true);
      try {
        const formData = new FormData();
        formData.append("businessName", data.businessName);
        formData.append("personInChargeName", data.personInChargeName);
        formData.append("personInChargePhone", data.personInChargePhone);
        formData.append("personInChargeEmail", data.personInChargeEmail);
        formData.append("country", data.country);
        formData.append("region", data.region);
        formData.append("zone", data.zone);
        formData.append("city", data.city);
        formData.append("area", data.area);

        formData.append("address", data.address);
        formData.append("registrationNumber", data.registrationNumber);
        formData.append("vatNumber", data.vatNumber);
        if (selectedImageFiles && selectedImageFiles.length > 0) {
          const imagesArray = Array.from(selectedImageFiles);
          imagesArray.forEach((profileFile) => {
            formData.append("profileFile", profileFile);
          });
        }
        if (
          selectedBusinessRegistrationFiles &&
          selectedBusinessRegistrationFiles.length > 0
        ) {
          const businessimagesArray = Array.from(
            selectedBusinessRegistrationFiles
          );
          businessimagesArray.forEach((registerationFile) => {
            formData.append("registerationFile", registerationFile);
          });
        }

        if (selectedBusinessVatFiles && selectedBusinessVatFiles.length > 0) {
          const chequeimagesVat = Array.from(selectedBusinessVatFiles);
          chequeimagesVat.forEach((vatFile) => {
            formData.append("vatFile", vatFile);
          });
        }

        const apiResponse: RootBusinessProfileUpdate = await putSellerAccount(
          formData
        );
        setLoading(false);
        handleOpen();
        toast.success(apiResponse.data.message);
      } catch (error) {
        console.log("Error", error);
      }
    }
  };

  useEffect(() => {
    (async () => {
      let data = await getBusinessProfile();
      setBusinessInfo(data);
    })();
  }, []);
  const handleImageFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files) {
      let imageCount = 0;

      for (let i = 0; i < files.length; i++) {
        if (files[i].type.split("/")[0] === "image") {
          imageCount++;
        }
      }

      if (imageCount + selectedImageFiles.length <= 1) {
        const newImages: File[] = [];

        for (let i = 0; i < files.length; i++) {
          if (files[i].type.split("/")[0] === "image") {
            newImages.push(files[i]);
          }
        }

        setSelectedImageFiles((prevImages) => [...prevImages, ...newImages]);
      } else {
        alert("You can only select up to 1 images.");
      }
    }
  };
  const handleBusinessFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files) {
      let businessimageCount = 0;

      for (let i = 0; i < files.length; i++) {
        if (files[i].type.split("/")[0] === "image") {
          businessimageCount++;
        }
      }

      if (businessimageCount + selectedBusinessRegistrationFiles.length <= 2) {
        const newBusinessImages: File[] = [];

        for (let i = 0; i < files.length; i++) {
          if (files[i].type.split("/")[0] === "image") {
            newBusinessImages.push(files[i]);
          }
        }

        setSelectedBusinessRegistrationFiles((prevImages) => [
          ...prevImages,
          ...newBusinessImages,
        ]);
      } else {
        alert("You can only select up to 1 images.");
      }
    }
  };

  const handleBusinessVatFile = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;

    if (files) {
      let businessimageCount = 0;

      for (let i = 0; i < files.length; i++) {
        if (files[i].type.split("/")[0] === "image") {
          businessimageCount++;
        }
      }

      if (businessimageCount + selectedBusinessVatFiles.length <= 1) {
        const newBusinessImages: File[] = [];

        for (let i = 0; i < files.length; i++) {
          if (files[i].type.split("/")[0] === "image") {
            newBusinessImages.push(files[i]);
          }
        }

        setSelectedBusinessVatFiles((prevImages) => [
          ...prevImages,
          ...newBusinessImages,
        ]);
      } else {
        alert("You can only select up to 1 images.");
      }
    }
  };
  const handlePreviewBusinessVatDelete = (index: number) => {
    const datas = selectedBusinessVatFiles;
    datas.splice(index, 1);
    setSelectedBusinessVatFiles([...datas]);
  };

  const handlePreviewVideoDelete = (index: number) => {
    const datas = selectedImageFiles;
    datas.splice(index, 1);
    setSelectedImageFiles([...datas]);
  };
  const handlePreviewBusinessDelete = (index: number) => {
    const datas = selectedBusinessRegistrationFiles;
    datas.splice(index, 1);
    setSelectedBusinessRegistrationFiles([...datas]);
  };

  return (
    <section>
      <div className="">
        <h4 className="popupHeader">Business Information</h4>
        {loadingP && (
          <div className="flex justify-center items-center h-96">
            <Spinner className="h-16 w-16 text-gray-600/50" color="blue" />
          </div>
        )}
        {!loadingP && (
          <form
            className="px-8 pb-7 formStyle"
            onSubmit={handleSubmit(onsubmit)}
          >
            <div className="h-[550px] scrollbar">
              <div className="grid grid-cols-3 px-3 gap-3 ">
                <div className="">
                  <label htmlFor="Fname" className="">
                    Legal Name :
                  </label>
                  <input
                    type="text"
                    readOnly
                    className="border-2 w-full py-3 px-3 outline-none rounded-md"
                    {...register("businessName", {
                      required: "Business Name is required",
                    })}
                  />
                  <p className="text-red-500">{errors.businessName?.message}</p>
                </div>
                <div className="">
                  <label htmlFor="charge" className="">
                    Person in Charge:
                  </label>
                  <input
                    type="text"
                    className="border-2 w-full py-3 px-3 outline-none rounded-md"
                    {...register("personInChargeName")}
                  />
                </div>
                <div className="">
                  <label htmlFor="email" className="">
                    Person in Charge Mobile Number:
                  </label>
                  <input
                    type="text"
                    className="border-2 w-full py-3 px-3 outline-none rounded-md"
                    {...register("personInChargePhone")}
                  />
                </div>
                <div className="">
                  <label htmlFor="email" className="">
                    Person in Charge Email:
                  </label>
                  <input
                    type="text"
                    className="border-2 w-full py-3 px-3 outline-none rounded-md"
                    {...register("personInChargeEmail")}
                  />
                </div>
                <div className="">
                  <label htmlFor="email" className="">
                    Country <span className="text-red-500">*</span>:
                  </label>
                  <select
                    className="border-2 w-full py-3 px-3 outline-none rounded-md"
                    {...register("country", {
                      required: "Country is required",
                    })}
                    onChange={(e) => {
                      setCunt(e.target.value);
                    }}
                  >
                    {countryList.map((country, index) => (
                      <option value={country.name} key={index}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                  <p className="text-red-500">{errors.country?.message}</p>
                </div>
                <div className="">
                  <label htmlFor="email" className="">
                    Region <span className="text-red-500">*</span>:
                  </label>
                  {cunt == "Nepal" && (
                    <select
                      className="border-2 w-full py-3 px-3 outline-none rounded-md"
                      {...register("region", {
                        required: "Region is required",
                      })}
                    >
                      {provinceList.map((province, index) => (
                        <option value={province.name} key={index}>
                          {province.name}
                        </option>
                      ))}
                    </select>
                  )}
                  {cunt != "Nepal" && (
                    <input
                      type="text"
                      className="border-2 w-full py-3 px-3 outline-none rounded-md"
                      {...register("region", {
                        required: "Region is required",
                      })}
                    />
                  )}
                  <p className="text-red-500">{errors.region?.message}</p>
                </div>
                <div className="">
                  <label htmlFor="email" className="">
                    Zone <span className="text-red-500">*</span>:
                  </label>
                  <input
                    type="text"
                    className="border-2 w-full py-3 px-3 outline-none rounded-md"
                    {...register("zone", { required: "Zone is required" })}
                  />
                  <p className="text-red-500">{errors.zone?.message}</p>
                </div>
                <div className="">
                  <label htmlFor="email" className="">
                    Area <span className="text-red-500">*</span>:
                  </label>
                  <input
                    type="text"
                    className="border-2 w-full py-3 px-3 outline-none rounded-md"
                    {...register("area", { required: "Area is required" })}
                  />
                  <p className="text-red-500">{errors.area?.message}</p>
                </div>
                <div className="">
                  <label htmlFor="email" className="">
                    Address<span className="text-red-500">*</span>:
                  </label>
                  <input
                    type="text"
                    className="border-2 w-full py-3 px-3 outline-none rounded-md"
                    {...register("address", {
                      required: "Address is required",
                    })}
                  />
                  <p className="text-red-500">{errors.address?.message}</p>
                </div>

                <div className="">
                  <label htmlFor="email" className="">
                    Business Registration Number
                    <span className="text-red-500">*</span>:
                  </label>
                  <input
                    type="text"
                    className="border-2 w-full py-3 px-3 outline-none rounded-md"
                    {...register("registrationNumber", {
                      required: "Registration Number is required",
                    })}
                  />
                  <p className="text-red-500">
                    {errors.registrationNumber?.message}
                  </p>
                </div>
                <div className="">
                  <label htmlFor="email" className="">
                    Business PAN/VAT
                    <span className="text-red-500">*</span>:
                  </label>
                  <input
                    type="text"
                    className="border-2 w-full py-3 px-3 outline-none rounded-md"
                    {...register("vatNumber", {
                      required: "PAN/VAT Number is required",
                    })}
                  />
                  <p className="text-red-500">{errors.vatNumber?.message}</p>
                </div>
              </div>
              <div className="w-full flex space-x-3 px-3">
                <div className="w-1/3">
                  <div className="mt-4">
                    <label htmlFor="image" className="">
                      Business Logo:
                    </label>
                    <label
                      htmlFor="imageFile"
                      className="border-2 border-dashed cursor-pointer border-gray-300 w-full rounded-md h-40 overflow-hidden flex flex-col justify-center items-center "
                    >
                      <RiImageAddFill size={36} />
                      <p>Click to select files</p>
                    </label>
                    <input
                      type="file"
                      onChange={handleImageFile}
                      accept="image/*"
                      id="imageFile"
                      multiple={true}
                      className="hidden"
                    />
                  </div>
                  <div className="flex flex-wrap gap-3">
                    <div className="flex gap-10 mt-4">
                      <div>
                        <p className="text-[12px] font-[300]">Previous Image</p>
                        <img
                          src={businessinfo?.results.profileFile}
                          alt=""
                          className="w-20 h-20 rounded-md"
                        />
                      </div>
                      <div>
                        {selectedImageFiles.length > 0 && (
                          <p className="text-[12px] font-[300]">Newer Image</p>
                        )}
                        {selectedImageFiles.map((image, index) => {
                          return (
                            <div className="w-20 h-20 ">
                              {image.type.startsWith("image") && (
                                <div key={index} className="relative">
                                  <img
                                    src={URL.createObjectURL(image)}
                                    className="w-full h-20 rounded-md object-cover"
                                    alt={`Image ${index + 1}`}
                                    {...register("profileFile")}
                                  />

                                  <div
                                    className="absolute top-[-5px] p-1 rounded-full cursor-pointer bg-red-500 right-[-3px]"
                                    onClick={() =>
                                      handlePreviewVideoDelete(index)
                                    }
                                  >
                                    <RxCross2 size={15} color="white" />
                                  </div>
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                {/* Registration Image Upload */}
                <div className="w-1/3">
                  <div className="mt-4">
                    <label htmlFor="image" className="">
                      Upload Business Registration Document:
                    </label>
                    <label
                      htmlFor="imageBusiness"
                      className="border-2 border-dashed cursor-pointer border-gray-300 w-full rounded-md h-40 overflow-hidden flex flex-col justify-center items-center"
                    >
                      <RiImageAddFill size={36} />
                      <p>Click to select files</p>
                    </label>
                    <input
                      type="file"
                      onChange={handleBusinessFile}
                      accept="image/*"
                      id="imageBusiness"
                      multiple={true}
                      className="hidden"
                    />
                  </div>
                  <div className="flex flex-wrap gap-3">
                    <div className="flex gap-10 mt-4">
                      <div>
                        <p className="text-[12px] font-[300]">Previous Image</p>
                        <img
                          src={businessinfo?.results.registerationFile}
                          alt=""
                          className="w-20 h-20 rounded-md"
                        />
                      </div>
                      <div>
                        {selectedBusinessRegistrationFiles.length > 0 && (
                          <p className="text-[12px] font-[300]">Newer Image</p>
                        )}
                        {selectedBusinessRegistrationFiles.map(
                          (businesssImg, index) => {
                            return (
                              <div className="w-20 h-20 ">
                                {businesssImg.type.startsWith("image") && (
                                  <div key={index} className="relative">
                                    <img
                                      src={URL.createObjectURL(businesssImg)}
                                      className="w-full h-20 rounded-md object-cover"
                                      {...register("registerationFile")}
                                    />

                                    <div
                                      className="absolute top-[-5px] p-1 rounded-full cursor-pointer bg-red-500 right-[-3px]"
                                      onClick={() =>
                                        handlePreviewBusinessDelete(index)
                                      }
                                    >
                                      <RxCross2 size={15} color="white" />
                                    </div>
                                  </div>
                                )}
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-1/3">
                  <div className="mt-4">
                    <label htmlFor="image" className="">
                      Upload Business VAT/PAN Document:
                    </label>
                    <label
                      htmlFor="imageBusinessVat"
                      className="border-2 border-dashed cursor-pointer border-gray-300 w-full rounded-md h-40 overflow-hidden flex flex-col justify-center items-center"
                    >
                      <RiImageAddFill size={36} />
                      <p>Click to select files</p>
                    </label>
                    <input
                      type="file"
                      onChange={handleBusinessVatFile}
                      accept="image/*"
                      id="imageBusinessVat"
                      multiple={true}
                      className="hidden"
                    />
                  </div>
                  <div className="flex flex-wrap gap-3">
                    <div className="flex gap-10 mt-4">
                      <div>
                        <p className="text-[12px] font-[300]">Previous Image</p>
                        <img
                          src={businessinfo?.results.vatFile}
                          alt=""
                          className="w-20 h-20 rounded-md"
                        />
                      </div>
                      <div>
                        {selectedBusinessVatFiles.length > 0 && (
                          <p className="text-[12px] font-[300]">Newer Image</p>
                        )}
                        {selectedBusinessVatFiles.map((businesssImg, index) => {
                          return (
                            <div className="w-20 h-20 ">
                              {businesssImg.type.startsWith("image") && (
                                <div key={index} className="relative">
                                  <img
                                    src={URL.createObjectURL(businesssImg)}
                                    className="w-full h-20 rounded-md object-cover"
                                    {...register("vatFile")}
                                  />

                                  <div
                                    className="absolute top-[-5px] p-1 rounded-full cursor-pointer bg-red-500 right-[-3px]"
                                    onClick={() =>
                                      handlePreviewBusinessVatDelete(index)
                                    }
                                  >
                                    <RxCross2 size={15} color="white" />
                                  </div>
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full flex justify-end">
              <Button type="submit" className="submitButton">
                {loading ? "Loading" : "Update"}
              </Button>
            </div>
          </form>
        )}
      </div>
    </section>
  );
};
export default BusinessAccount;
