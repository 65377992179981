import { Spinner } from "@material-tailwind/react";
import { GigAccept, GigApprove, GigChatUserList, GigUser, GigUserListReultProps, ResulUsertChatsProps } from "../../../utils/gig";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ChatBubbleLeftIcon, CheckIcon } from "@heroicons/react/24/outline";
import { FaCheck, FaTimes } from "react-icons/fa";


type UserBoxProps = {
    data: GigUser
    buttonAction: String
    requirePosting: boolean | undefined,
    setReFetch: (value: boolean) => void;
    reFetch: boolean;
    getUserChats: (id: string, user: GigUser) => void;
}
const UserBox = (
    {
        data,
        buttonAction,
        requirePosting,
        setReFetch,
        reFetch,
        getUserChats
    }: UserBoxProps
) => {
    const [gigUserChatList, setGigUserChatList] = useState<GigUserListReultProps[]>([]);


    const [loading, setLoading] = useState(false);
    const approveGig = async (id: string, gigStatus: string) => {
        try {
            setLoading(true);
            await GigApprove({ id: id, gigStatus: gigStatus });

            toast.success(
                `Gig ${(gigStatus == "approved" || gigStatus == 'requestforposting') ? "Approved" : "Rejected"} successfully`
            );
            setLoading(false);
            setReFetch(!reFetch);
        } catch (error) {
            toast.error("Error deleting product");
            console.error("Error deleting product:", error);
        }
    };

    const acceptGig = async (id: string) => {
        try {
            let payment = prompt("Please enter the release amount")
            if (payment != '') {
                setLoading(true);
                await GigAccept({ id: id, payment: parseInt(payment!) });

                toast.success(
                    `You have release ${payment} for this user!`
                );
                setLoading(false);
                setReFetch(!reFetch);
            }

        } catch (error) {
            toast.error("Error deleting product");
            console.error("Error deleting product:", error);
        }
    }
    useEffect(() => {
        (async () => {
            try {
                const responsChatUserList = await GigChatUserList(
                    data._id
                );
                setGigUserChatList(responsChatUserList.results);
            } catch (error) {
                console.error("Error fetching gig user chat list:", error);
            }
        })()
    }, []);

    return (
        <div className="grid grid-cols-12 items-center align-middle">
            <div className="col-span-2">
                <img
                    src={data.user.profileFile}
                    className="rounded-full h-20 w-20 object-cover"
                    alt=""
                />
            </div>
            <div className="col-span-6">
                <h4 className="font-medium text-lg text-gray-800 pb-1">
                    {" "}
                    {data.user.fullname}
                </h4>
                <h6>{data.user.followers} Followers</h6>
                <div className="flex flex-wrap gap-2 skillpills">
                    {data.user.skills?.map((ele, index) => (
                        <p key={index}>{ele}</p>
                    ))}
                </div>
            </div>

            {data.action != 'Use Postaam App' && <div className="col-span-4 text-center flex">
                {
                    gigUserChatList.length > 0 && gigUserChatList[0].lastMessage && (
                        <button
                            onClick={() => getUserChats(gigUserChatList[0]._id, data)}
                            className="border border-bluePostaam-700 text-bluePostaam-700 hover:bg-bluePostaam-700 hover:text-white w-full py-2 rounded-full justify-center flex items-center mr-3"
                        >
                            <ChatBubbleLeftIcon className="h-5 w-5" />
                        </button>
                    )
                }


                {buttonAction == "Hire" && data.gigStatus != 'completed' && !loading && (

                    <button
                        onClick={() => approveGig(data._id, requirePosting ? "requestforposting" : "approved")}
                        className="border border-bluePostaam-700 text-bluePostaam-700 hover:bg-bluePostaam-700 hover:text-white w-full py-2 rounded-full"
                    >
                        Hire
                    </button>
                )}
                {buttonAction == "Hire" && data.gigStatus != 'completed' && !loading && (
                    <button
                        onClick={() => approveGig(data._id, "rejected")}
                        className="ml-2 border border-red-700 text-red-700 hover:bg-bluePostaam-700 hover:text-white w-full py-2 rounded-full"
                    >
                        Reject
                    </button>
                )}
                {buttonAction == "Hire" && data.gigStatus == 'completed' && !loading && (
                    <button
                        className="ml-2 border bg-bluePostaam-700 text-white w-full py-2 rounded-full"
                    >
                        Completed
                    </button>
                )}
                {buttonAction == "Hire" && loading && (
                    <button className="ml-2 border  text-bluePostaam-700 hover:bg-bluePostaam-700 border-bluePostaam-700 hover:text-white w-full py-2 rounded-full justify-center flex items-center">
                        {" "}
                        <Spinner className="h-4 w-4" color="blue" />
                    </button>
                )}
                {buttonAction == "Hired" && (
                    <button className="border bg-bluePostaam-700 text-white w-full py-2 rounded-full">
                        {buttonAction}
                    </button>
                )}
                {buttonAction == "Approve" && (
                    <div className="flex">
                        <button className="border bg-bluePostaam-700 text-white w-full py-2 px-4 rounded-full" onClick={() => acceptGig(data._id)} title="Approve">
                            <FaCheck className="h-5 w-5" />
                        </button>
                        <button className="border bg-red-800 text-white w-full py-2 px-4 rounded-full" onClick={() => approveGig(data._id, 'rejected')} title="Reject">
                            <FaTimes className="h-5 w-5" />
                        </button>
                    </div>
                )}
            </div>}
            {data.action == 'Use Postaam App' && <div className="col-span-4 text-center flex">
                {
                    gigUserChatList.length > 0 && gigUserChatList[0].lastMessage && (
                        <button
                            onClick={() => getUserChats(gigUserChatList[0]._id, data)}
                            className="border border-bluePostaam-700 text-bluePostaam-700 hover:bg-bluePostaam-700 hover:text-white w-full py-2 rounded-full justify-center flex items-center mr-3"
                        >
                            <ChatBubbleLeftIcon className="h-5 w-5" />
                        </button>
                    )
                }
                {buttonAction == "Hire" && (
                    <button className="border bg-bluePostaam-700 text-white w-full py-2 rounded-full">
                        {(data.duration / 60 / 60).toFixed(2)} Hours
                    </button>
                )}
                {buttonAction == "Hired" && (
                    <button className="border bg-bluePostaam-700 text-white w-full py-2 rounded-full">
                        {(data.duration / 60 / 60).toFixed(2)} Hours
                    </button>
                )}
            </div>}
        </div>
    )
}

export default UserBox