import React, { useState } from "react";
import { Button, Card, CardBody, Typography } from "@material-tailwind/react";

import { Popup } from "../../components/Popup";
interface TransactionProps {
  title: string;
  amount: number;
  date: string;
  paymentStatus: "pending" | "completed";
}

function Transactions() {
  const TABLE_HEAD = ["S/N", "Title", "Amount", "Date", "Payment Status"];
  const [loading, setLoading] = useState(false);
  const topupList: TransactionProps[] = [
    {
      title: "test transaction",
      amount: 3000,
      date: "2024/05/05",
      paymentStatus: "completed",
    },
    {
      title: "test transaction",
      amount: 2000,
      date: "2024/05/05",
      paymentStatus: "pending",
    },
  ];

  return (
    <div className="w-full px-4">
      <div className=" flex justify-between items-center">
        <Typography variant="h4" className="font-bold mb-4">
          Balance
        </Typography>
        <Popup page="transactions" />
      </div>

      {/* <div className=" flex items-center my-2 justify-between w-[98%] ">
        {/* filter    
        <div className="w-40 ">
          <Select label="Filter">
            <Option>Vender</Option>
            <Option>User</Option>
          </Select>
        </div>

        {/* sort *
        <div className="w-40 ">
          <Select label="Sort by">
            <Option>High to Low</Option>
            <Option>Low to High</Option>
          </Select>
        </div>
      </div> */}

      <div className="w-full min-w-max overflow-x-auto">
        <Card className="h-full w-full mt-4 ">
          <CardBody className="overflow-hidden px-0 pt-0">
            <table className="w-full min-w-max table-auto text-left">
              <thead>
                <tr>
                  {TABLE_HEAD.map((head) => (
                    <th
                      key={head}
                      className="border-b-2 border-blue-gray-100  p-4"
                    >
                      <Typography
                        variant="small"
                        className="font-semibold leading-none opacity-70"
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>

              {!loading && (
                <tbody>
                  {topupList.map((ele, index) => {
                    const isLast = index === topupList.length - 1;
                    const classes = isLast
                      ? "p-4"
                      : "p-4 border-b border-blue-gray-50";

                    return (
                      <tr key={index} className="hover:bg-blue-gray-50/30">
                        <td className={classes}>
                          <Typography variant="small" className="font-normal">
                            {index + 1}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            className="font-normal capitalize"
                          >
                            {ele.title}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography variant="small" className="font-normal">
                            {ele.amount}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography variant="small" className="font-normal">
                            {ele.date}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            className={`font-medium capitalize ${
                              ele.paymentStatus === "pending"
                                ? "text-yellow-800"
                                : ele.paymentStatus === "completed"
                                ? "text-green-600"
                                : "text-red-600"
                            }`}
                          >
                            {ele.paymentStatus}
                          </Typography>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              )}

              {!loading && topupList.length > 0 && (
                <tfoot className=" ">
                  <tr>
                    <td colSpan={7} className="p-4">
                      <div className="flex px-3 gap-4 items-center justify-end">
                        <Typography variant="small" className="font-normal">
                          Total: {topupList.length}
                        </Typography>
                        <div className="flex gap-2">
                          <Button
                            size="sm"
                            // onClick={prevAction}
                            className="bg-blue-600 hover:bg-blue-700 text-white"
                          >
                            Previous
                          </Button>

                          <Button
                            size="sm"
                            // onClick={nextAction}
                            className="bg-blue-600 hover:bg-blue-700 text-white"
                          >
                            Next
                          </Button>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tfoot>
              )}

              {(loading || topupList.length) === 0 && (
                <tbody>
                  <tr>
                    <td colSpan={7}>
                      <div className="flex justify-center items-center p-8">
                        {loading ? (
                          <div className="w-10 h-10 border-4 border-t-blue-600 rounded-full animate-spin" />
                        ) : (
                          <Typography variant="small" className="text-gray-500">
                            No withdrawal requests found
                          </Typography>
                        )}
                      </div>
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export default Transactions;
