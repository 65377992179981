import { useEffect, useState } from "react"
import loginFormLogo from "../../assets/image/authbg.jpg";
import GigChatMessage from "../../components/details/gig/GigChatMessage";

const GigChatList = () => {
    const [users, setUsers] = useState<{ profile: string, name: string, userHandle: string }[]>([]);
    const [selectedUser, setSelectedUser] = useState<{ profile: string, name: string, userHandle: string }>();
    useEffect(() => {
        let list = [
            {
                profile: loginFormLogo,
                name: "Binayak DHakal",
                userHandle: "@_bina1062"
            },
            {
                profile: loginFormLogo,
                name: "ABC DHakal",
                userHandle: "@_abc1062"
            }
        ]
        setUsers(() => list)
    }, []);
    const openChat = (user: { profile: string, name: string, userHandle: string }) => {
        setSelectedUser(user);
        console.log("hello")
    }


    return (
        <div className="flex">
            <div className="bg-white w-1/4 shadow-blue-gray-50 mr-4 p-5">
                <h2 className="font-bold">Chat List</h2>
                <div className="mt-3">
                    <ul className="flex flex-col gap-3">
                        {users.map((ele, index) => <li onClick={() => openChat(ele)} key={index} className={`flex items-center gap-3 cursor-pointer border p-1 rounded-md ${selectedUser == ele ? 'bg-blue-600' : 'bg-white'}`}>
                            <div className="h-12 bg-gray-300 w-12 rounded-full overflow-hidden justify-center p-1">
                                <img src={ele.profile} alt="" className="object-cover w-full h-full" />
                            </div>
                            <div className={`${selectedUser == ele ? 'text-white' : 'text-black'}`}>
                                <strong>{ele.name}</strong>
                            </div>
                        </li>)}
                    </ul>
                </div>
            </div>
            <div className="bg-gray-100 w-3/4 p-5 shadow-blue-gray-600">
                <h2 className="font-bold">{selectedUser?.name}</h2>
                <div className="mt-3">
                    {/* {selectedUser?.name && <GigChatMessage />} */}
                </div>
                <div className="h-[80vh] flex items-center justify-center">
                    <h3 className="text-3xl font-black">
                        Choose a user
                    </h3>
                </div>
            </div>

        </div>
    )
}

export default GigChatList