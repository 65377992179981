import { Outlet } from "react-router-dom";
import { Sidebar } from "./menu/SideBar";
import Header from "./menu/Header";

const Layout = () => {
  return (
    <div className="flex flex-row h-screen w-screen overflow-hidden   bg-[#F7F8FC]">
      <div>
        <Sidebar />
      </div>
      <div className="p-4 w-full overflow-y-scroll flex-1">
        <div className="py-3 sm:px-3 px-0 mb-5 flex justify-end items-center">
          <Header />
        </div>
        <div className="mt-6  ">{<Outlet />}</div>
      </div>
    </div>
  );
};

export default Layout;
