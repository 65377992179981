import { Button, Spinner } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import {
  RootBusinessProfile,
  RootBusinessProfileUpdate,
  putSellerAccount,
} from "../../../../utils/business";
type FormValue = {
  ownerName: string;
  email: string;
  phoneNumber: number;
  bio: string;
};
interface SellerAccountProps {
  businessProfile: RootBusinessProfile;
  handleOpen: any;
}
const SellerAccount = ({ businessProfile, handleOpen }: SellerAccountProps) => {
  const [loading, setLoading] = useState(false);
  const [loadingP, setLoadingP] = useState(true);

  const form = useForm<FormValue>();
  const { register, handleSubmit, formState, setValue } = form;
  const { errors } = formState;
  useEffect(() => {
    setValue("ownerName", businessProfile.results.ownerName);
    setValue("email", businessProfile.results.email);
    setValue("bio", businessProfile.results.bio);

    setValue("phoneNumber", parseInt(businessProfile.results.phoneNumber));
    setLoadingP(false);
  }, [setValue, businessProfile]);

  const onsubmit = async (data: FormValue) => {
    if (!loading) {
      setLoading(true);
      try {
        const formData = new FormData();
        formData.append("ownerName", data.ownerName);
        formData.append("email", data.email);
        formData.append("bio", data.bio);
        formData.append("phoneNumber", data.phoneNumber.toString());
        const apiResponse: RootBusinessProfileUpdate = await putSellerAccount(
          formData
        );
        setLoading(false);
        handleOpen();
        toast.success(apiResponse.data.message);
      } catch (error) {
        console.log("Error", error);
        toast.error("Something went wrong");
      }
    }
  };
  return (
    <section>
      <div className="">
        <h4 className="popupHeader">Seller Account</h4>
        {loadingP && (
          <div className="flex justify-center items-center h-96">
            <Spinner className="h-16 w-16 text-gray-600/50" color="blue" />
          </div>
        )}
        {!loadingP && (
          <form
            className="px-8 pb-7 formStyle"
            onSubmit={handleSubmit(onsubmit)}
          >
            <div className="grid grid-cols-2 gap-4">
              <div className="mt-3">
                <label htmlFor="Fname">
                  Full Name<span className="text-red-500">*</span>:
                </label>
                <input
                  type="text"
                  {...register("ownerName", {
                    required: "Full Name is required",
                  })}
                />
                <p className="text-red-500 font-mulish">
                  {errors.ownerName?.message}
                </p>
              </div>

              <div className="mt-3">
                <label htmlFor="contact">
                  Contact Number<span className="text-red-500">*</span>:
                </label>
                <input
                  type="number"
                  {...register("phoneNumber", {
                    required: "Contact is required",
                  })}
                />
                <p className="text-red-500 font-mulish">
                  {errors.phoneNumber?.message}
                </p>
              </div>
              <div className="mt-3">
                <label htmlFor="email">
                  Email Address<span className="text-red-500">*</span>:
                </label>
                <input
                  type="text"
                  className="border-2 w-full py-3 px-3 outline-none rounded-md"
                  {...register("email", { required: "Email is required" })}
                />

                <p className="text-red-500 font-mulish">
                  {errors.email?.message}
                </p>
              </div>
              <div className="mt-3">
                <label htmlFor="bio">
                  Bio<span className="text-red-500">*</span>:
                </label>
                <input
                  type="text"
                  className="border-2 w-full py-3 px-3 outline-none rounded-md"
                  {...register("bio", { required: "Bio is required" })}
                />

                <p className="text-red-500 font-mulish">
                  {errors.bio?.message}
                </p>
              </div>
            </div>
            <div className="w-full flex justify-end">
              <Button type="submit" className="submitButton">
                {loading ? "Loading..." : "Update"}
              </Button>
            </div>
          </form>
        )}
      </div>
    </section>
  );
};

export default SellerAccount;
