import { toast } from "react-toastify";
import { RootGig, addGig } from "../../../utils/gig";
import { SubTaskProps } from "./AddGig";
import { Button, Spinner } from "@material-tailwind/react";
import { AiFillDelete } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";

interface AddSubGigProps {
  ele: SubTaskProps;
  index: number;
  _id: string;
  selectedAction: string;
  businessName: string;
  errors: any;
  handleDelete: (id: string) => void;
  setListOfSubTask: React.Dispatch<React.SetStateAction<SubTaskProps[]>>;
  listOfSubTask: SubTaskProps[];
}
const UpdateSubGig = ({
  ele,
  index,
  _id,
  selectedAction,
  businessName,
  setListOfSubTask,
  listOfSubTask,
}: AddSubGigProps) => {
  const onsubmitSubTask = async (data: SubTaskProps, index: number) => {
    try {
      listOfSubTask[index].loading = true;
      setListOfSubTask([...listOfSubTask]);
      const formData: FormData = new FormData();
      if (data._id != undefined) {
        formData.append("_id", data._id);
      }
      formData.append("connectedTo", _id);
      formData.append("title", data.title!);
      formData.append("businessName", data.businessName!);
      formData.append("businessHandle", data.businessHandle!);
      formData.append("description", data.description!);
      formData.append("gigType", "subtask");
      listOfSubTask[index].tags &&
        listOfSubTask[index].tags!.length > 0 &&
        formData.append("tags", JSON.stringify(listOfSubTask[index].tags));
      formData.append("action", selectedAction);
      formData.append("quantity", data.quantity!.toString());
      const apiResponse: RootGig = await addGig(formData);
      listOfSubTask[index].loading = false;
      listOfSubTask[index]._id = apiResponse.data.results._id;
      setListOfSubTask([...listOfSubTask]);
      toast.success("Sub Task Added successfully");
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };
  return (
    <div
      key={index}
      className="border border-gray-300 rounded-lg bg-white p-6 space-y-3 h-56 overflow-y-auto"
    >
      <div className="space-y-1">
        <div className="flex justify-between items-center">
          <label className="font-semibold">Update Task Gig Details</label>
        </div>
        <div className="flex flex-col gap-3">
          <div>
            <label htmlFor="bussinessName" className="custom-label">
              Business Name:
            </label>
            <input
              type="text"
              value={businessName}
              autoComplete="off"
              className="border-2 w-full py-3 px-3 outline-none rounded-md"
              readOnly
            />
          </div>
          <div>
            <label>Action</label>
            <input
              type="text"
              readOnly
              value={ele.action}
              className="border-2 w-full py-3 px-3 outline-none rounded-md font-Roboto"
            />
          </div>
          {ele.quantity && (
            <div>
              {ele.action === "Book A Ride" && <label>Ride Quantity</label>}
              {ele.action === "Like on Posts" && <label>No. of Post</label>}
              {ele.action === "Post Content" && <label>No. of Post</label>}
              {ele.action === "Comment on Posts" && <label>No. of Post</label>}
              {ele.action === "Use Postaam App" && (
                <label>Total Duration (Enter in hours)</label>
              )}
              <input
                type="text"
                className="border-2 w-full py-3 px-3 outline-none rounded-md font-Roboto"
                placeholder=""
                onKeyUp={(e) => {
                  let data = [...listOfSubTask];
                  data[index].quantity = parseInt(e.currentTarget.value);
                  setListOfSubTask(data);
                }}
              />
            </div>
          )}
        </div>
      </div>

      <div className="space-y-1">
        <label>Task Title</label>
        <input
          type="text"
          className="border-2 w-full py-3 px-3 outline-none rounded-md font-Roboto"
          placeholder=""
          onKeyUp={(e) => {
            let data = [...listOfSubTask];
            data[index].title = e.currentTarget.value;
            setListOfSubTask(data);
          }}
        />
      </div>

      {ele.tags && (
        <div className="space-y-1">
          <label>
            What hashtag to be used?{" "}
            <small>
              (Note: Add hashtags that best describe your product or business.)
            </small>
          </label>
          <input
            type="text"
            className="border-2 w-full py-3 px-3 outline-none rounded-md font-Roboto"
            placeholder=" "
            onKeyUp={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                let data = [...listOfSubTask];
                const value = e.currentTarget.value.trim();
                if (value) {
                  // Deep copy the tags array to ensure unique references
                  const updatedTags = [...(data[index].tags || [])];
                  updatedTags.push(value);
                  data[index] = { ...data[index], tags: updatedTags }; // Create a new object to avoid shared references
                  setListOfSubTask(data);
                  e.currentTarget.value = ""; // Clear input field
                }
              }
            }}
          />
          <div
            className={`flex flex-wrap gap-3 pt-3 ${
              ele.tags.length > 6 ? "h-16 overflow-y-scroll" : ""
            }`}
          >
            {ele.tags.map((item, i) => (
              <div
                key={i}
                className="flex items-center gap-3 p-1 rounded-md bg-gray-200 shadow-md"
              >
                <span>{item}</span>
                <span
                  onClick={() => {
                    let data = [...listOfSubTask];
                    // Deep copy the tags array to ensure unique references
                    const updatedTags = data[index].tags?.filter(
                      (del) => del !== item
                    );
                    data[index] = { ...data[index], tags: updatedTags }; // Create a new object to avoid shared references
                    setListOfSubTask(data);
                  }}
                >
                  <RxCross2 />
                </span>
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="space-y-1">
        <label>Describe your task</label>
        <textarea
          className="border-2 w-full py-3 px-3 outline-none rounded-md font-Roboto"
          placeholder=""
          rows={5}
          onKeyUp={(e) => {
            let data = [...listOfSubTask];
            data[index].description = e.currentTarget.value;
            setListOfSubTask(data);
          }}
        ></textarea>
      </div>
      <div className="flex justify-end">
        {!ele.loading && (
          <Button
            className="bg-bluePostaam-600 rounded-full py-3 text-[15px] font-bold w-48 text-white"
            onClick={() => onsubmitSubTask(ele, index)}
          >
            {ele._id ? "Update" : "Save"}
          </Button>
        )}
        {ele.loading && (
          <Button className="bg-white rounded-full py-3 text-[15px] font-bold w-48 items-center text-bluePostaam-600 justify-center flex">
            {ele.loading && <Spinner color="blue" />}
          </Button>
        )}
      </div>
    </div>
  );
};

export default UpdateSubGig;
