import React from "react";
import { IconButton } from "@material-tailwind/react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
export interface PagiationProps {
  nextAction: () => void;
  previousAction: () => void;
  currentPage: number;
  total: number;
}
const Pagination = ({
  nextAction,
  previousAction,
  currentPage,
  total,
}: PagiationProps) => {
  const [active] = React.useState(1);
  return (
    <section className="overflow-hidden flex items-center sm:justify-end justify-center  sm:gap-16 gap-0 lg:bottom-3 sm:bottom-0 md:bottom-0  relative sm:right-11 right-0">
      <div className="flex  gap-1 mt-3 items-center">
        <p className="text-[#9FA2B4] font-[400] font-mulish">
          {currentPage * 10}-{total}
        </p>
        <p className="text-[#9FA2B4] font-[400] font-mulish">of 10</p>
        <IconButton
          size="sm"
          className="bg-transparent border-none shadow-none hover:shadow-none"
          onClick={previousAction}
          disabled={active === 10}
        >
          <IoIosArrowBack className="h-6 w-6 text-[#9FA2B4]" />
        </IconButton>

        <IconButton
          size="sm"
          className="bg-transparent border-none shadow-none hover:shadow-none"
          onClick={nextAction}
          disabled={active === 10}
        >
          <IoIosArrowForward className="h-12 w-6 text-[#9FA2B4]" />
        </IconButton>
      </div>
    </section>
  );
};

export default Pagination;
