import axios from "axios";
import { baseUrl } from ".";

/* api for product list started */
export interface RootProductTableList {
  status: Number;
  data: {
    message: string;
    results: Result[];
    pagination: PaginationProps;
  };
}

export interface Result {
  _id: string;
  productName: string;
  images: Image[];
  slug: string;
  visibleStatus: boolean;
  subCategory: string;
  quantity?: number;
}
export interface Image {
  image: string;
  _id: string;
}

export interface PaginationProps {
  total: number;
  next?: number;
  previous?: number;
}

export interface UserTableList {
  page: number;
  limit: number;
  images?: string;
  productName?: string;
  status?: Boolean;
  slug?: string;
  subCategory?: string;
}

export const productTableDetails = async (
  data: UserTableList
): Promise<RootProductTableList> => {
  try {
    const res = await axios.post(baseUrl + "product/allProduct", data, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("token")!).token,
      },
    });
    return res as RootProductTableList;
  } catch (error: any) {
    console.log("Error", error);
    return error.response as RootProductTableList;
  }
};

/* api for product list Ended */

/* ================================================ */

/* api for product add Started */
export interface RootProductAdd {
  status: number;
  data: RootSingleProduct;
}

export interface formData {
  name: string;
  description: string;
}

export interface RootSingleProduct {
  massage: string;
  results: ResultSingleProduct;
}

export interface ResultSingleProduct {
  category: string;
  subCategory: string;
  thirdCategory: string;
  productName: string;
  description: string;
  taxInclude: boolean;
  payAtproperty: boolean;
  discountPercentage: number;
  commission: number;
  percentage: number;
  totalCommission: number;
  price: number;
  slug: string;
  videos: Video[];
  images: string;
  brand: string;
  tags: any[];
  title: string;
  weight: string;
  quantity: number;
  _id: string;
}
export interface Video {
  url: string;
  _id: string;
  title: string;
  duration: string;
  level: string;
}
export interface ResultCategroy {
  subCategories: SubCategory[];
  _id: string;
  name: string;
  slug: string;
  image: string;
  gradientBanner: string;
  categoryFor: string[];
  categoryType: string;
}
export interface SubCategory {
  subCategories: any[];
  _id: string;
  name: string;
  slug: string;
  connectedTo: string;
  image: string;
  gradientBanner: string;
  categoryFor: string[];
  categoryType: string;
}

export const addProductApi = async (
  data: FormData
): Promise<RootProductAdd> => {
  try {
    const res = await axios.post(`${baseUrl}product/create`, data, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("token")!).token,
      },
    });
    return res as RootProductAdd;
  } catch (error: any) {
    console.log("Error", error);
    return error.response as RootProductAdd;
  }
};
/* api for product add Ended */

/* API for product-Details started */
export interface RootProductDetails {
  message: string;
  results: ProductResults;
}

export interface ProductResults {
  attributes: any[];
  features: any[];
  properties: any[];
  reviews: any[];
  tags: Tag[];
  images: Image[];
  videos: any[];
  advertisementImages: AdvertisementImage[];
  advertisementVideos: any[];
  isFeatured: boolean;
  status: boolean;
  visibleStatus: boolean;
  minOrders: any[];
  specifications: any[];
  _id: string;
  productName: string;
  discountPercentage: number;
  commision: number;
  percentage: number;
  totalCommission: number;
  category: string;
  subCategory: string;
  thirdCategory: string;
  userHandle: string;
  companyName: string;
  commission: number;
  createdAt: string;
  updatedAt: string;
  isVariable: boolean;
  finalSubmitted: boolean;
  slug: string;
  __v: number;
  accomodation: string;
  age: string;
  altitude: string;
  availability: string;
  duration: string;
  itenary: string;
  payAtproperty: boolean;
  price: number;
  productType: string;
  taxInclude: boolean;
  tripgrade: string;
  weight: string;
  brand: string;
  quantity: number;
  affiliation: boolean;
}

export interface Tag {
  _id: string;
  tagName: string;
}

export interface Image {
  image: string;
  _id: string;
}

export interface AdvertisementImage {
  image: string;
  _id: string;
}
export interface productDetails {
  productName: string;
  tags: string;
  categroy: string;
  subCategory: string;
  thirdCategory: string;
  features: string;
  weight: string;
  commission: number;
  percentage: number;
  totalCommission: number;
  quantity: number;
  price: string;
  advertisementImages: string;
  _id: string;
  specifications: string;
  discountPercentage: number;
}
export const getProductDetails = async (
  _id: string
): Promise<RootProductDetails> => {
  try {
    const res = await axios.get(
      baseUrl + "product/getBasicProductById/" + _id,
      {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")!).token,
        },
      }
    );
    return res.data as RootProductDetails;
  } catch (error: any) {
    console.log("Error", error);
    return error.response.data as RootProductDetails;
  }
};

export const deleteProdcut = async (
  _id: string
): Promise<RootProductDetails> => {
  try {
    const res = await axios.delete(baseUrl + `product/deleteProduct/${_id}`, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("token")!).token,
      },
    });
    return res.data as RootProductDetails;
  } catch (error: any) {
    console.log("Error", error);
    return error.response.data as RootProductDetails;
  }
};

export const deleteTags = async (_id: string): Promise<RootProductDetails> => {
  try {
    const res = await axios.delete(baseUrl + `deleteTags/${_id}`, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("token")!).token,
      },
    });
    return res.data as RootProductDetails;
  } catch (error: any) {
    console.log("Error", error);
    return error.response.data as RootProductDetails;
  }
};
/* API for product-Details Ended */

/* API for search Product start */

export interface RootSearchProduct {
  status: number;
  data: {
    message: string;
    results: ResultSearchProduct[];
  };
}

export interface ResultSearchProduct {
  productName: string;
  _id: string;
  images: string;
  price: number;
}

export interface searchProps {
  productName: string;
  userHandle: string;
}

export const SearchProductName = async (
  data: searchProps
): Promise<RootSearchProduct> => {
  try {
    const res = await axios.post(
      baseUrl + "product/searchBusinessProduct",
      data,
      {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")!).token,
        },
      }
    );
    return res as RootSearchProduct;
  } catch (error: any) {
    console.log("Error", error);
    return error.response as RootSearchProduct;
  }
};

/* API for search product Ended */

// API for product Category started

export interface RootCategory {
  message: string;
  results: ResultProductCategory[];
}

export interface ResultProductCategory {
  _id: string;
  subCategories: ProductSubCategory[];
  name: string;
  percentage: number;
  slug: string;
}

export interface ProductSubCategory {
  subCategories: SubCategory2[];
  _id: string;
  name: string;
  slug: string;
}

export interface SubCategory2 {
  _id: string;
  name: string;
  slug: string;
}

export const getProductCategory = async (): Promise<RootCategory> => {
  try {
    const res = await axios.get(baseUrl + "category/categorySelects", {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("token")!).token,
      },
    });
    return res.data as RootCategory;
  } catch (error: any) {
    console.error("Error fetching product category:", error);
    return {} as RootCategory;
  }
};

export const BusinessCategory = async (): Promise<RootCategory> => {
  try {
    const res = await axios.get(baseUrl + "category/categorySelects");
    return res.data as RootCategory;
  } catch (error: any) {
    console.log("Error", error);
    return error.response.data as RootCategory;
  }
};

// API for product Category ended

// API for category started

export interface RootCategoryTable {
  message: string;
  results: CategoryResult[];
}

export interface CategoryResult {
  subCategories: SubCategory3[];
  _id: string;
  name: string;
  percentage: number;
  slug: string;
}

export interface SubCategory3 {
  index: number;
  subCategories: SubCategory2[];
  _id: string;
  name: string;
  category: string;
  userHandle: string;
  slug: string;
  __v: number;
  percentage?: number;
  deleteStatus?: boolean;
}

export interface SubCategory2 {
  index: number;
  subCategories: any[];
  _id: string;
  name: string;
  category: string;
  mainCategory: string;
  userHandle: string;
  slug: string;
  __v: number;
  percentage?: number;
  deleteStatus?: boolean;
}
export interface CategoryValue {
  name: string;
  percentage: number;
  _id: string;
}

export const CategoryList = async (): Promise<RootCategoryTable> => {
  try {
    const res = await axios.get(baseUrl + "category/categories", {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("token")!).token,
      },
    });
    return res.data as RootCategoryTable;
  } catch (error: any) {
    console.log("Error", error);
    return error.response.data as RootCategoryTable;
  }
};

// API for category ended
