import { Button, list } from "@material-tailwind/react";
import { useEffect, useRef, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { IoIosArrowDown } from "react-icons/io";
interface FilterProps {
  setSearch: (search: string | undefined) => void;
  setTypeOfOrder: (typeoforder: string | undefined) => void;
  setPaymentType: (paymentType: string | undefined) => void;
  setPaymentStatus: (paymentStatus: boolean | undefined) => void;
  handelFilter: () => void;
}
const OrderFilter = ({
  setPaymentStatus,
  setPaymentType,
  setTypeOfOrder,
  setSearch,
  handelFilter,
}: FilterProps) => {
  const [selectedPaymentStatus, setSelectedPaymentStatus] =
    useState<string>("All");
  const handlePaymentStatusChange = (e: any) => {
    const value = e.target.value;
    setSelectedPaymentStatus(value);
    if (value === "All") {
      setPaymentStatus(undefined);
    } else if (value === "Paid") {
      setPaymentStatus(true);
    } else if (value === "Not Paid") {
      setPaymentStatus(false);
    }
  };
  return (
    <section>
      <div className="p-2 rounded-md shadow-sm mt-3 flex gap-2 bg-white">
        <div className="w-full ">
          <input
            type="text"
            placeholder="Search"
            className="py-[0.6rem] px-2 border-2 outline-none rounded-md font-Roboto w-full"
            onInput={(e) => setSearch(e.currentTarget.value)}
          />
        </div>
        <div className="w-[30rem] ">
          <select
            className="border-2 py-[0.82rem] outline-none w-full px-2 rounded-md text-[0.84rem]"
            onChange={(e) => setPaymentType(e.target.value)}
          >
            <option value="" disabled selected>
              Select Payment Method
            </option>
            <option value="All">All</option>
            <option value="Cash On Delivery">Cash On Delivery</option>
            <option value="Khalti">Khalti</option>
          </select>
        </div>
        <div className="w-[30rem] ">
          <select
            className="border-2 py-[0.82rem] outline-none w-full px-2 rounded-md text-[0.84rem]"
            value={selectedPaymentStatus}
            onChange={handlePaymentStatusChange}
          >
            <option value="All">All</option>
            <option value="Paid">Paid</option>
            <option value="Not Paid">Not Paid</option>
          </select>
        </div>
        <div className="w-[30rem] ">
          <select
            onChange={(e) => setTypeOfOrder(e.target.value)}
            className="border-2 py-[0.82rem] outline-none w-full px-2 rounded-md text-[0.84rem]"
          >
            <option value="" disabled selected>
              Select Status
            </option>
            <option value="All">All</option>
            <option value="Pending">Pending</option>
            <option value="Processing">Processing</option>
            <option value="Ready To Ship">Ready To Ship</option>
            <option value="Cancelled">Cancelled</option>
            <option value="Completed">Completed</option>
            <option value="Return">Return</option>
            <option value="Exchange">Exchange</option>
          </select>
        </div>

        <div>
          <Button
            className="bg-bluePostaam-600 w-full h-[45.8px] rounded-[5px] lg:w-full hover:shadow-none font-Roboto flex justify-center items-center"
            onClick={handelFilter}
          >
            <FiSearch size={23} />
          </Button>
        </div>
      </div>
    </section>
  );
};
export default OrderFilter;
