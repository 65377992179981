import axios from "axios";
import { baseUrl } from ".";

export interface RootSales {
  status: number;
  data: {
    message: string;
    results: Result[];
    details: Details;
    pagination: Pagination;
  };
}
export interface Pagination {
  total: number;
}
export interface Details {
  totalEarning: number;
  totalPendingEarning: number;
}
export interface Result {
  productName: string;
  productId: string;
  productImage: string;
  businessTotal: number;
  total: number;
  saleStatus: string;
  salesFrom: string;
  orderCode: string;
}

export interface salesProps {
  page: number;
  limit: number;
  salesOf?: string;
  saleType?: string;
}

export const salesList = async (data: salesProps): Promise<RootSales> => {
  try {
    const res = await axios.post(baseUrl + "order/salesBusiness", data, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("token")!).token,
      },
    });
    return res as RootSales;
  } catch (error: any) {
    console.log("Error", error);
    return error.response as RootSales;
  }
};

// Order List Api started

export interface RootOrder {
  status: number;
  data: {
    message: string;
    results: OrderResult[];
    pagination: PaginationProps;
  };
}

export interface OrderResult {
  _id: string;
  images: any[];
  logs: any[];
  createdAt: string;
  updatedAt: string;
  details: Details;
  product: Product;
  billingAddress: BillingAddress;
  shippingAddress: ShippingAddress;
  orderStatus: string;
  userHandle: string;
  orderCode: string;
  subOrderCode: string;
  trakingOrder: string;
  paymentType: string;
  paymentStatus: boolean;
  shippingService: string;
  deliveryDate: string;
  checkoutUnqiueCode: string;
  __v: number;
  description?: string;
  search: string;
  typeoforder: string;
  reason?: string;
}

export interface Details {
  subTotal: number;
  shipping: number;
  points: number;
  total: number;
  totalDiscount: number;
  totalQuantity: number;
  couponName: string;
}

export interface Product {
  _id: string;
  productId: string;
  stockAvaible: boolean;
  orderType: string;
  propertyId: string;
  brand: string;
  propertyName: string;
  size: string;
  productName: string;
  productType: string;
  isVariable: boolean;
  adminHandle: string;
  image: string;
  quantity: number;
  productPrice: number;
  discountPercentage: string;
  price: number;
  addedToCheckout: boolean;
  uniqueCode: string;
}

export interface BillingAddress {
  _id: string;
  firstname: string;
  lastname: string;
  phone1: string;
  province: string;
  valley: string;
  ringRoad: string;
  email: string;
  address1: string;
  addressType: string;
  userHandle: string;
  default: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
  phone2?: string;
}

export interface ShippingAddress {
  _id: string;
  firstname: string;
  lastname: string;
  phone1: string;
  province: string;
  valley: string;
  ringRoad: string;
  email: string;
  address1: string;
  addressType: string;
  userHandle: string;
  default: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
  phone2?: string;
}

export interface PaginationProps {
  total: number;
  next?: number;
  previous?: number;
}
export interface orderProps {
  page: number;
  limit: number;
  search?: string;
  typeoforder?: string;
  paymentType?: string;
  paymentStatus?: boolean;
}
export const orderList = async (data: orderProps): Promise<RootOrder> => {
  try {
    const res = await axios.post(baseUrl + "order/orderList", data, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("token")!).token,
      },
    });
    return res as RootOrder;
  } catch (error: any) {
    console.log("Error", error);
    return error.response as RootOrder;
  }
};

// Order list Api ended

// Single Order API Started

export interface RootOrderDetails {
  message: string;
  results: SingleOrderResults;
}

export interface SingleOrderResults {
  logs: any[];
  details: Details;
  billingAddress: SingelOrderBillingAddress;
  orderCode: string;
  shippingAddress: SingleOrderShippingAddress;
  subOrderCode: string;
  product: Product;
  paymentStatus: boolean;
  paymentType: string;
  deliveryDate: string;
  orderStatus: string;
  createdAt: string;
  userHandle: string;
  reviewStatus: boolean;
}

export interface Details {
  total: number;
  subTotal: number;
  totalDiscount: number;
  shipping: number;
  points: number;
}

export interface SingelOrderBillingAddress {
  _id: string;
  firstname: string;
  lastname: string;
  phone1: string;
  phone2: string;
  province: string;
  valley: string;
  ringRoad: string;
  email: string;
  address1: string;
  addressType: string;
  userHandle: string;
  default: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface SingleOrderShippingAddress {
  _id: string;
  firstname: string;
  lastname: string;
  phone1: string;
  phone2: string;
  province: string;
  valley: string;
  ringRoad: string;
  email: string;
  address1: string;
  addressType: string;
  userHandle: string;
  default: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface Product {
  _id: string;
  productId: string;
  stockAvaible: boolean;
  orderType: string;
  propertyId: string;
  brand: string;
  propertyName: string;
  size: string;
  productName: string;
  productType: string;
  isVariable: boolean;
  adminHandle: string;
  image: string;
  quantity: number;
  productPrice: number;
  discountPercentage: string;
  price: number;
  addedToCheckout: boolean;
  uniqueCode: string;
}

export interface Product {
  _id: string;
  productId: string;
  stockAvaible: boolean;
  orderType: string;
  propertyId: string;
  brand: string;
  propertyName: string;
  size: string;
  productName: string;
  productType: string;
  isVariable: boolean;
  adminHandle: string;
  image: string;
  quantity: number;
  productPrice: number;
  discountPercentage: string;
  price: number;
  addedToCheckout: boolean;
  uniqueCode: string;
}

export const getOrderDetails = async (
  subOrderCode: string
): Promise<RootOrderDetails> => {
  try {
    const res = await axios.get(baseUrl + "order/singleOrder/" + subOrderCode, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("token")!).token,
      },
    });
    return res.data as RootOrderDetails;
  } catch (error: any) {
    console.log("Error", error);
    return error.response.data as RootOrderDetails;
  }
};

// Single Order API Ended

// Order Status API Started

export interface RootStatusOrder {
  status: number;
  data: {
    message: string;
    results: OrderStatusResult[];
  };
}

export interface OrderStatusResult {
  id: string;
  orderStatus: string;
  reason: string;
  description: string;
}

export interface StatsOrderProps {
  id: string;
  orderStatus: string;
  reason: string;
  description: string;
}

export const StatusOrder = async (
  data: StatsOrderProps
): Promise<RootStatusOrder> => {
  try {
    const res = await axios.put(baseUrl + "order/changeOrderStatus", data, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("token")!).token,
      },
    });
    return res.data as RootStatusOrder;
  } catch (error: any) {
    console.log("Error", error);
    return error.response as RootStatusOrder;
  }
};

// Order Status API Ended

// Booking List API started

export interface RootBooking {
  status: number;
  data: {
    message: string;
    results: BookingListResult[];
    pagination: PaginationProps;
  };
}

export interface BookingListResult {
  _id: string;
  bookingNumber: string;
  bookingType: string;
  numberOfAdult: number;
  bookingStatus: string;
  search: string;
  numberOfChild: number;
  createdAt: string;
  title: string;
  totalPrice: number;
}

export interface PaginationProps {
  total: number;
  next?: number;
  previous?: number;
}

export interface bookProps {
  page: number;
  limit: number;
  bookingStatus?: string;
  search?: string;
}
export const booking = async (data: bookProps): Promise<RootBooking> => {
  try {
    const res = await axios.post(baseUrl + "book/bookingListBusiness", data, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("token")!).token,
      },
    });
    return res as RootBooking;
  } catch (error: any) {
    console.log("Error", error);
    return error.response as RootBooking;
  }
};

// Booking List API Ended

// Single booking API Started

export interface RootSingleBooking {
  message: string;
  results: SingleBookingResults;
}

export interface SingleBookingResults {
  subTotal: number;
  _id: string;
  bookingNumber: string;
  bookingType: string;
  fullName: string;
  phone: string;
  email: string;
  connectedTo: string;
  numberOfAdult: number;
  numberOfChild: number;
  userHandle: string;
  tripDate: string;
  product: BookingProduct;
  nationality: string;
  paymentType: string;
  paymentStatus: boolean;
  bookingStatus: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface BookingProduct {
  attributes: Attribute[];
  features: any[];
  properties: any[];
  reviews: any[];
  tags: Tag[];
  images: string;
  videos: any[];
  isFeatured: boolean;
  status: boolean;
  visibleStatus: boolean;
  minOrders: any[];
  specifications: Specification[];
  _id: string;
  productName: string;
  quantity: number;
  discountPercentage: number;
  brand: string;
  userHandle: string;
  companyName: string;
  commission: number;
  createdAt: string;
  updatedAt: string;
  isVariable: boolean;
  finalSubmitted: boolean;
  slug: string;
  __v: number;
  price: number;
  productType: string;
  weight: number;
  category: string;
  subCategory: string;
  thirdCategory: string;
  phoneNumber: string;
}

export interface Attribute {
  key: string;
  value: string;
}

export interface Tag {
  _id: string;
  tagName: string;
}

export interface Specification {
  title: string;
  description: string;
}

export const getSingleBooking = async (
  _id: string
): Promise<RootSingleBooking> => {
  try {
    const res = await axios.get(baseUrl + `book/singleBooking/${_id}`, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("token")!).token,
      },
    });
    return res.data as RootSingleBooking;
  } catch (error: any) {
    console.log("Error", error);
    return error.response.data as RootSingleBooking;
  }
};

// Single booking API Ended

// Booking Status API Started

export interface RootStatusBooking {
  status: number;
  data: {
    message: string;
    results: BookingStautsResult[];
  };
}

export interface BookingStautsResult {
  bookingNumber: string;
  bookingStatus: string;
  reason: string;
  description: string;
}

export interface StatsBookingProps {
  bookingNumber: string;
  bookingStatus: string;
  reason: string;
  description: string;
}

export const StatusBooking = async (
  data: StatsBookingProps
): Promise<RootStatusBooking> => {
  try {
    const res = await axios.put(baseUrl + "book/changeBookingStatus", data, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("token")!).token,
      },
    });
    return res.data as RootStatusBooking;
  } catch (error: any) {
    console.log("Error", error);
    return error.response as RootStatusBooking;
  }
};

// Booking Status API Ended
