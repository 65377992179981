import { useEffect, useState } from "react";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import { Card, Button, CardBody, Spinner } from "@material-tailwind/react";
import { FaEye } from "react-icons/fa";
import { Link } from "react-router-dom";
import { TABLE_HEAD_BOOKING } from "../../../data";
import BookingFilter from "../../../pages/products/filters/bookingFilter";
import Pagination from "../../Pagination";
import {
  BookingListResult,
  PaginationProps,
  RootBooking,
  booking,
} from "../../../utils/sales";
const Booking = () => {
  const [orderData, setOrderData] = useState<BookingListResult[]>([]);
  const [loading, setLoading] = useState(false);
  const [bookingStatus, setBookingStatus] = useState<string>();
  const [bookingSearch, setBookingSearch] = useState<string>();
  const [pagination, setPagination] = useState<PaginationProps>();
  const [page, setPage] = useState(1);
  const limit = 10;

  useEffect(() => {
    const fetching = async () => {
      setLoading(true);
      const res: RootBooking = await booking({
        page: page,
        limit: limit,
        bookingStatus: "Cancelled",
      });

      if (res.status === 200) {
        setOrderData(res.data.results);
        setPagination(res.data.pagination);
        setLoading(false);
      }
    };
    fetching();
  }, [page]);
  const filterAction = async () => {
    try {
      const response: RootBooking = await booking({
        page: page,
        limit: limit,
        bookingStatus: bookingStatus?.length ? bookingStatus : undefined,
        search: bookingSearch?.length ? bookingSearch : undefined,
      });
      if (response.status === 200) {
        setOrderData(response.data.results);
        setPagination(response.data.pagination);
      } else {
        console.error("Error fetching gig list:", response);
      }
    } catch (error: any) {
      console.error("Error fetching gig list:", error);
    }
  };
  const nextAction = async () => {
    try {
      if (pagination?.next !== undefined) {
        setPage(pagination.next);
        console.log("Next arrow clicked. New skip value:", pagination.next);
        const response: RootBooking = await booking({
          page: pagination!.next!,
          limit: limit,
        });
        if (response.status === 200) {
          setOrderData(response.data.results);
          setPagination(response.data.pagination);
        } else {
          console.error("Error fetching gig list:", response);
        }
      }
    } catch (error) {
      console.error("Error fetching gig list:", error);
    }
  };

  const previousAction = async () => {
    try {
      if (pagination?.previous !== undefined) {
        setPage(pagination.previous);
        const response: RootBooking = await booking({
          page: pagination!.previous!,
          limit: limit,
        });
        if (response.status === 200) {
          setOrderData(response.data.results);
          setPagination(response.data.pagination);
        } else {
          console.error("Error fetching gig list:", response);
        }
      }
    } catch (error) {
      console.error("Error fetching gig list:", error);
    }
  };
  return (
    <section>
      <div className="bg-white mb-3 p-3 rounded-lg flex items-center justify-between">
        <h6 className="text-gray-800 font-bold text-[1.2rem]">Booking</h6>
        <Button
          className="flex items-center gap-3 bg-bluePostaam-600"
          size="sm"
        >
          <ArrowDownTrayIcon strokeWidth={2} className="h-4 w-4" />
          Export CVS
        </Button>
      </div>
      <div>
        <BookingFilter
          setBookingStatus={setBookingStatus}
          setBookingSearch={setBookingSearch}
          handleFilter={filterAction}
        />
      </div>

      <Card className="h-full w-full mt-3">
        <CardBody className="overflow-hidden px-0 py-0">
          {loading && (
            <div className=" flex justify-center items-center h-[60vh]">
              <Spinner className="h-16 w-16 text-gray-600/50" color="blue" />
            </div>
          )}
          {!loading && (
            <table className="w-full min-w-max table-auto text-left">
              <thead>
                <tr>
                  {TABLE_HEAD_BOOKING.map((head) => (
                    <th
                      key={head}
                      className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 text-sm text-blue-gray-900 font-normal leading-none opacity-70"
                    >
                      {head}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {orderData.length === 0 && (
                  <tr>
                    <td className="px-6 py-3">No Data Available</td>
                  </tr>
                )}
                {orderData.length > 0 &&
                  orderData.map((item, index) => {
                    const isLast = index === orderData.length - 1;
                    const classes = isLast
                      ? "p-4"
                      : "p-4 border-b border-blue-gray-50";

                    return (
                      <tr key={index}>
                        <td className={`${classes} font-normal text-sm`}>
                          {item.bookingNumber}
                        </td>
                        <td
                          className={`${classes} font-normal text-sm flex flex-col`}
                        >
                          <span>Adult :{item.numberOfAdult || 0}</span>
                          <span>Child :{item.numberOfChild || 0}</span>
                        </td>

                        <td className={`${classes} font-normal text-sm`}>
                          Rs. {item.totalPrice}
                        </td>
                        <td className={`${classes} font-normal text-sm`}>
                          {item.createdAt.split("T")[0]}
                        </td>

                        <td className={`${classes} font-normal text-sm`}>
                          <Link
                            to={`/singleBooking/${item._id}`}
                            className="flex justify-center items-center rounded-full bg-bluePostaam-500 shadow-none hover:shadow-none h-10 w-10"
                          >
                            <FaEye size={20} color="white" />
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          )}
        </CardBody>
        <div>
          {pagination && (
            <Pagination
              nextAction={nextAction}
              previousAction={previousAction}
              total={pagination.total}
              currentPage={page}
            />
          )}
        </div>
      </Card>
    </section>
  );
};

export default Booking;
