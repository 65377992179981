import { useEffect, useState } from "react";
import { Spinner } from "@material-tailwind/react";
import { Card, Typography, CardBody } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import { Result, RootSales, salesList, salesProps } from "../../utils/sales";
import { FINANCE_SALES_LIST } from "../../data";

const SalesData = () => {
  const [active, setActive] = useState("All Order");
  const [orderData, setOrderData] = useState<Result[]>([]);
  const [orderStatus, setOrderStatus] = useState("All Order");
  const [loading, setLoading] = useState(false);
  const handleTab = async (saleType: string) => {
    setLoading(true);
    setActive(saleType);
    try {
      const postData: salesProps = {
        page: 1,
        limit: 10,
        saleType: saleType === "All Order" ? "All Order" : saleType,
        salesOf: "Weekly",
      };
      setOrderStatus(() => saleType);
      const response: RootSales = await salesList(postData);
      if (response.status === 200) {
        setLoading(false);
        let filteredData = response.data.results;
        if (saleType !== "All Order") {
          filteredData = filteredData.filter(
            (item) => item.saleStatus === saleType
          );
        }
        setOrderData(filteredData);
      } else {
        console.error("Error fetching recent posts:", response);
      }
    } catch (error) {
      console.error("Error fetching recent posts:", error);
    }
  };

  useEffect(() => {
    handleTab(orderStatus);
  }, []);

  return (
    <section>
      <Card className="h-full w-full ">
        <CardBody className="overflow-hidden px-0 py-0">
          {loading && (
            <div className=" flex justify-center items-center h-[60vh]">
              <Spinner className="h-16 w-16 text-gray-600/50" color="blue" />
            </div>
          )}
          {!loading && (
            <table className="w-full min-w-max table-auto text-left">
              <thead>
                <tr>
                  {FINANCE_SALES_LIST.map((head) => (
                    <th
                      key={head}
                      className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 text-sm text-blue-gray-900 font-normal leading-none opacity-70"
                    >
                      {head}
                    </th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {orderData.length === 0 && (
                  <tr>
                    <td className="px-6 py-3">No Data Available</td>
                  </tr>
                )}
                {orderData.length > 0 &&
                  orderData.map((item, index) => {
                    const isLast = index === orderData.length - 1;
                    const classes = isLast
                      ? "p-4"
                      : "p-4 border-b border-blue-gray-50";

                    return (
                      <tr key={index}>
                        <td className={`${classes}`}>
                          <Link
                            to={`/SingleOrder/${item.orderCode}`}
                            className="font-normal hover:text-bluePostaam-600 hover:underline"
                          >
                            {item.orderCode}
                          </Link>
                          {item.productName}
                        </td>
                        <td className={`${classes} font-normal text-sm`}>
                          {item.salesFrom}
                        </td>
                        <td className={`${classes} font-normal text-sm`}>
                          Rs: {item.businessTotal}
                        </td>
                        <td className={`${classes} font-normal text-sm`}>
                          {item.saleStatus}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          )}
        </CardBody>
      </Card>
    </section>
  );
};
export default SalesData;
