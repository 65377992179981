import { Button, Card, Spinner, Switch } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GigDelete, GigDetails, RootObject, addGig } from "../../../utils/gig";
import GigTagsPreview from "../productDetails/components/GigTagsPreview";
import SkillPreview from "../productDetails/components/SkillPreview";
import { RxCross2 } from "react-icons/rx";
import { Results } from "../../../utils/user";
import {
  ResultSearchProduct,
  RootSearchProduct,
  SearchProductName,
} from "../../../utils/product";
import AddSubGig from "../../forms/gigForm/AddSubGig";
import UpdateSubGig from "../../forms/gigForm/UpdateSubGig";
export type FormValue = {
  title: string;
  businessName: string;
  businessHandle: string;
  descriptionTitle: string;
  description: string;
  numberofcreator: number;
  numberofimage: number;
  numberofvideo: number;
  submissionTimeframe: number;
  adDuration: number;
  offerAmountFrom: number;
  offerAmount: number;
  offerAmountTo: number;
  requirePosting: Boolean;
  multiplePost: Boolean;
  numberviews: number;
  numberengagements: number;
  level: string;
  skills: string[];
  tags: string[];
  searchCreator: number;
  gigType: string;
  specifications: Specification[];
  attributes: Attribute[];
};
type Specification = {
  title: string;
  description: string;
};
type Attribute = {
  key: string;
  value: string;
};
interface Props {
  step: number;
  id: string;
  handleOpen: () => void;
  repeaterIndex?: number;
  setUpdateLoading: React.Dispatch<React.SetStateAction<boolean>>;
  newSpecification: Boolean;
  specificationTitle: string;
}
export type SubTaskProps = {
  businessHandle?: string;
  businessName?: string;
  action?: string;
  description?: string;
  quantity?: number;
  title?: string;
  tags?: string[];
  tag?: string;
  loading: boolean;
  _id?: string;
};
const GigUpdate = ({
  step,
  id,
  handleOpen,
  setUpdateLoading,
  newSpecification,
  repeaterIndex,
}: Props) => {
  const [tags, setTags] = useState<string[]>([]);
  const [skills, setSkills] = useState<string[]>([]);
  const [_id] = useState<string>(id!);
  const [loading, setLoading] = useState(false);
  const [productSelected, setProductSelected] = useState<
    ResultSearchProduct | undefined
  >();
  const [addSubTask, setAddSubTask] = useState(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [searchList, setSearchList] = useState<ResultSearchProduct[]>([]);
  const [connectedTo, setConnectedTo] = useState("");
  const [loadingP, setLoadingP] = useState(true);
  const [offerOn, setOfferOn] = useState(false);
  const [selectedAction, setSelectedAction] = useState("None");
  const [listOfSubTask, setListOfSubTask] = useState<SubTaskProps[]>([]);

  const [specifications, setSpecifications] = useState<Specification[]>([
    { title: "", description: "" },
  ]);
  const [newSpecifications, setNewSpecifications] = useState<Specification[]>([
    { title: "", description: "" },
  ]);
  const [businessName, setBusinessName] = useState<Results>();

  const [subTasks, setSubTasks] = useState<SubTaskProps[]>([
    {
      businessName: businessName?.user.fullname,
      businessHandle: businessName?.user.userHandle,
      action: "None",
      quantity: undefined,
      title: undefined,
      tags: undefined,
      description: undefined,
      loading: false,
    },

    {
      businessName: businessName?.user.fullname,
      businessHandle: businessName?.user.userHandle,
      action: "Book A Ride",
      quantity: 1,
      title: "",
      description: "",
      loading: false,
    },

    {
      businessName: businessName?.user.fullname,
      businessHandle: businessName?.user.userHandle,
      action: "Like on Posts",
      quantity: 1,
      title: "",
      description: "",
      loading: false,
    },

    {
      businessName: businessName?.user.fullname,
      businessHandle: businessName?.user.userHandle,
      action: "Comment on Posts",
      quantity: 1,
      title: "",
      description: "",
      loading: false,
    },
    {
      businessName: businessName?.user.fullname,
      businessHandle: businessName?.user.userHandle,
      action: "Use Postaam App",
      quantity: 1,
      title: "",
      description: "",
      loading: false,
    },
    {
      businessName: businessName?.user.fullname,
      businessHandle: businessName?.user.userHandle,
      action: "Post Content",
      quantity: 1,
      title: "",
      tag: "",
      tags: [],
      description: "",
      loading: false,
    },
  ]);
  const handleRangePrice = () => {
    setOfferOn(!offerOn);
  };
  const handlePreviewTagDelete = (index: number) => {
    const datas = tags;
    datas.splice(index, 1);
    setTags([...datas]);
  };
  const handlePreviewSkillDelete = (index: number) => {
    const datas = skills;
    datas.splice(index, 1);
    setSkills([...datas]);
  };
  const handleTags = (data: string) => {
    if (data.trim() !== "") {
      setTags((prev) => [...prev, data.trim()]);
      form.setValue("tags", [...tags, data.trim()]);
    }
  };
  const handleSkills = (data: string) => {
    if (data.trim() !== "") {
      setSkills((prev) => [...prev, data.trim()]);
      form.setValue("skills", [...skills, data.trim()]);
    }
  };
  const form = useForm<FormValue>();
  const { register, handleSubmit, formState, setValue } = form;
  const { errors } = formState;

  useEffect(() => {
    (async () => {
      const res: RootObject = await GigDetails({
        id: id!,
        page: 1,
        limit: 1,
      });
      setValue("title", res.data.gig.title);
      setValue("businessName", res.data.gig.businessName);
      setValue("businessHandle", res.data.gig.businessHandle);
      setValue("description", res.data.gig.description);
      setValue("gigType", res.data.gig.gigType!);
      setValue("level", res.data.gig.level || "");

      setValue("numberofcreator", res.data.gig.numberofcreator);
      setValue("numberofimage", res.data.gig.numberofimage);
      setValue("numberofvideo", res.data.gig.numberofvideo);
      setValue("submissionTimeframe", res.data.gig.submissionTimeframe);
      setValue("adDuration", res.data.gig.adDuration);
      setValue("offerAmountFrom", res.data.gig.offerAmountFrom);
      setValue("offerAmountTo", res.data.gig.offerAmountTo);
      setValue("offerAmount", res.data.gig.offerAmount);
      setSpecifications(res.data.gig.specifications!);
      setTags(res.data.gig.tags!);
      setSkills(res.data.gig.skills!);
      setLoadingP(false);
    })();
  }, [id, setValue]);
  const onsubmit = async (data: FormValue) => {
    if (!loading) {
      setLoading(true);
      try {
        const formData: FormData = new FormData();
        _id !== "" && formData.append("_id", _id);
        if (productSelected) {
          formData.append("connectedTo", productSelected._id);
        }
        formData.append("title", data.title);
        formData.append("businessName", data.businessName);
        formData.append("businessHandle", data.businessHandle);
        formData.append("description", data.description);
        formData.append("gigType", data.gigType!);
        formData.append("level", data.level);
        data.numberofcreator &&
          formData.append("numberofcreator", data.numberofcreator.toString());
        data.numberofimage &&
          formData.append("numberofimage", data.numberofimage.toString());
        data.numberofvideo &&
          formData.append("numberofvideo", data.numberofvideo.toString());
        data.adDuration &&
          formData.append("adDuration", data.adDuration.toString());
        data.submissionTimeframe &&
          formData.append(
            "submissionTimeframe",
            data.submissionTimeframe.toString()
          );
        data.offerAmountFrom &&
          formData.append("offerAmountFrom", data.offerAmountFrom.toString());
        data.offerAmount &&
          formData.append("offerAmount", data.offerAmount.toString());
        data.offerAmountTo &&
          formData.append("offerAmountTo", data.offerAmountTo.toString());
        tags.length > 0 && formData.append("tags", JSON.stringify(tags));
        skills.length > 0 && formData.append("skills", JSON.stringify(skills));

        if (
          newSpecifications &&
          newSpecifications.length > 0 &&
          newSpecifications[0].title &&
          newSpecifications[0].title.length > 0
        ) {
          formData.append(
            "specifications",
            JSON.stringify([...newSpecifications, ...specifications])
          );
        } else if (
          specifications &&
          specifications.length > 0 &&
          specifications[0].title.length > 0
        ) {
          formData.append("specifications", JSON.stringify(specifications));
        }

        await addGig(formData);
        handleOpen();
        setLoading(false);
        setUpdateLoading(true);
      } catch (error) {
        console.log("Error", error);
      }
    }
  };
  useEffect(() => {
    (async () => {
      try {
        const response: RootSearchProduct = await SearchProductName({
          productName: searchValue,
          userHandle: JSON.parse(localStorage.getItem("token")!).user
            .userHandle,
        });
        if (response.status === 200) {
          setSearchList(response.data.results);
        }
      } catch (error) {
        console.error("Error during search:", error);
      }
    })();
  }, [searchValue]);
  const selectAction = (e: { target: { value: any } }) => {
    setSelectedAction(e.target.value);
  };
  const addTask = () => {
    if (selectedAction != "None") {
      let newSub = subTasks.filter((ele) => ele.action == selectedAction);
      newSub[0].businessHandle = businessName?.user.userHandle;
      newSub[0].businessName = businessName?.user.fullname;
      setListOfSubTask((prev) => [...prev, newSub[0]]);
    } else {
      alert("Please select one action!");
    }
  };
  const handleDelete = async (id: string) => {
    if (window.confirm("Are you sure you want to delete this product?")) {
      try {
        await GigDelete({ _id: id, page: 1 });
        toast.success("Gig Deleted successfully");
      } catch (error) {
        toast.error("Error deleting product");
        console.error("Error deleting product:", error);
      }
    }
  };

  return (
    <section>
      {loadingP && (
        <div className="flex justify-center items-center h-96">
          <Spinner className="h-16 w-16 text-gray-600/50" color="blue" />
        </div>
      )}
      {!loadingP && step > 0 && (
        <div className="bg-white px-3 font-Roboto rounded-md ">
          <div className="pr-3 flex justify-between items-center">
            <h3 className="font-Roboto text-xl font-bold popupHeader">
              {step === 1 && "Update Gig Tags & Skills"}
              {step === 2 && "Update Gig Preference"}
              {step === 3 && "Update Gig Content Required"}
              {step === 4 && newSpecification && "Add Gig Specification"}
              {step === 4 && !newSpecification && "Update Gig Specification"}
              {step === 5 && "Update Gig Price"}
              {step === 6 && "Update Gig Product"}
              {step === 7 && "Update Gig Title"}
              {step === 8 && "Update Sub-Task"}
            </h3>
            <Button
              onClick={handleOpen}
              className="p-2 rounded-full bg-[#9E9E9E] text-white flex justify-center"
            >
              <RxCross2 size={25} />
            </Button>
          </div>
          <Card className="w-full bg-transparent shadow-none px-5">
            <form onSubmit={handleSubmit(onsubmit)} className="pb-7">
              {step === 7 && (
                <div className="p-6 rounded-lg mt-5 border border-gray-300">
                  <div className="w-full">
                    <label
                      htmlFor="taskTitle"
                      className="text-[16px] text-gray-500 font-Roboto font-medium"
                    >
                      Task Title <span className="text-red-500">*</span>
                    </label>
                    <input
                      id="taskTitle"
                      type="text"
                      className="border-2 w-full py-3 px-3 outline-none rounded-md font-Roboto"
                      {...register("title", {
                        required: "Title is required",
                      })}
                    />
                    {errors.title && (
                      <p className="text-red-500">{errors.title?.message}</p>
                    )}
                  </div>
                </div>
              )}
              {step === 1 && (
                <div className="p-6 rounded-lg mt-5 border border-gray-300 space-y-2 h-[400px] overflow-auto">
                  <div className="flex flex-wrap justify-center gap-4 w-full pb-5 ">
                    <div className="w-full">
                      <label htmlFor="expertiesLevel" className="custom-label">
                        Experties Level<span className="text-red-500">*</span>:
                      </label>
                      <select
                        id="expertiesLevel"
                        className="border-2 w-full py-3 px-3 outline-none rounded-md"
                        {...register("level", {
                          required: "Experties Level is required",
                        })}
                      >
                        <option value="entry">Entry</option>
                        <option value="intermidate">Intermidate</option>
                        <option value="expert">Expert</option>
                      </select>
                      {errors.level && (
                        <p className="text-red-500">{errors.level?.message}</p>
                      )}
                    </div>
                    <div className="w-full">
                      <label htmlFor="tag" className="custom-label">
                        Tags:
                      </label>
                      <input
                        id="tag"
                        type="text"
                        autoComplete="off"
                        className="border-2 w-full py-3 px-3 outline-none rounded-md font-Roboto"
                        {...register("tags")}
                        onKeyDown={(e) => {
                          if (e.key === "Enter" || e.key === " ") {
                            e.preventDefault();
                            handleTags(e.currentTarget.value);
                            e.currentTarget.value = "";
                          }
                        }}
                      />
                      <div className="flex flex-wrap gap-5">
                        {tags.map((item, index) => {
                          return (
                            <GigTagsPreview
                              key={index}
                              tags={item}
                              index={index}
                              action={handlePreviewTagDelete}
                            />
                          );
                        })}
                      </div>
                    </div>
                    <div className="w-full">
                      <label htmlFor="skill" className="custom-label">
                        Skills:
                      </label>
                      <input
                        id="skill"
                        type="text"
                        autoComplete="off"
                        className="border-2 w-full py-3 px-3 outline-none rounded-md"
                        {...register("skills")}
                        onKeyDown={(e) => {
                          if (e.key === "Enter" || e.key === " ") {
                            e.preventDefault();
                            handleSkills(e.currentTarget.value);
                            e.currentTarget.value = "";
                          }
                        }}
                      />
                      <div className="flex flex-wrap gap-5">
                        {skills.map((item, index) => {
                          return (
                            <SkillPreview
                              key={index}
                              skills={item}
                              index={index}
                              action={handlePreviewSkillDelete}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {step === 2 && (
                <div className="p-6 rounded-lg mt-5 border border-gray-300 space-y-2">
                  <div className="flex flex-wrap justify-center gap-4 w-full pb-5">
                    <div className="w-full">
                      <label
                        htmlFor="numberofcreator"
                        className="text-[16px] text-gray-500 font-Roboto font-medium"
                      >
                        Number of Creators
                        <span className="text-red-500">*</span>:
                      </label>
                      <input
                        id="numberOfCreator"
                        type="number"
                        className="border-2 w-full py-3 px-3 outline-none rounded-md font-Roboto"
                        {...register("numberofcreator", {
                          required: "Number of Creators is required",
                        })}
                      />
                      {errors.numberofcreator && (
                        <p className="text-red-500">
                          {errors.numberofcreator?.message}
                        </p>
                      )}
                    </div>
                    <div className="w-full">
                      <label
                        htmlFor="gigType"
                        className="text-[16px] text-gray-500 font-Roboto font-medium"
                      >
                        Content Type<span className="text-red-500">*</span>:
                      </label>
                      <select
                        id="gigType"
                        className="border-2 w-full py-3 px-3 outline-none rounded-md"
                        {...register("gigType", {
                          required: "Content type is required",
                        })}
                      >
                        <option value="freemium">Freemium</option>
                        <option value="premium">Premium</option>
                      </select>
                      {errors.gigType && <p>{errors.gigType?.message}</p>}
                    </div>
                  </div>
                </div>
              )}
              {step === 3 && (
                <div>
                  <div className="flex justify-between flex-col items-center gap-4">
                    <div className="mt-5 w-full">
                      <label
                        htmlFor="photo"
                        className="text-[16px] font-bold text-gray-500"
                      >
                        Number of Content Required Photo
                        <span className="text-red-500">*</span>:
                      </label>
                      <input
                        id="photo"
                        type="number"
                        className="border-2 w-full py-3 px-3 outline-none rounded-md"
                        {...register("numberofimage", {
                          required: "This field is required.",
                        })}
                      />
                      {errors.numberofimage && (
                        <p className="text-red-500">
                          {errors.numberofimage?.message}
                        </p>
                      )}
                    </div>
                    <div className="my-1 w-full">
                      <label
                        htmlFor="video"
                        className="text-[16px] font-bold text-gray-500"
                      >
                        Number of Content Required Video
                        <span className="text-red-500">*</span>:
                      </label>
                      <input
                        id="video"
                        type="number"
                        className="border-2 w-full py-3 px-3 outline-none rounded-md"
                        {...register("numberofvideo", {
                          required: "This field is required",
                        })}
                      />
                      {errors.numberofvideo && (
                        <p className="text-red-500">
                          {errors.numberofvideo?.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {step === 4 && (
                <div className="space-y-8 ">
                  {!newSpecification &&
                    specifications.map((data, index) => {
                      return (
                        index === repeaterIndex && (
                          <div
                            key={index}
                            className="border px-5 rounded-md border-gray-300"
                          >
                            <div className="pb-10  flex flex-row">
                              <div className="rounded-lg mt-5 space-y-2 w-full">
                                <div className="w-full pt-5 pb-8 space-y-3">
                                  <div className="w-full space-y-2">
                                    <label
                                      htmlFor="title"
                                      className="text-[16px] text-gray-500 font-Roboto font-medium text-left w-full"
                                    >
                                      Update Title
                                      <span className="text-red-500">*</span> :
                                    </label>
                                    <input
                                      type="text"
                                      required
                                      className="border-2 w-full p-3 rounded-md"
                                      value={data.title}
                                      onInput={(e) => {
                                        data.title = e.currentTarget.value;
                                        setSpecifications([...specifications]);
                                      }}
                                      {...register("descriptionTitle", {
                                        required: "Title is required",
                                      })}
                                    />
                                    {errors.descriptionTitle && (
                                      <p className="text-red-500">
                                        {errors.descriptionTitle?.message}
                                      </p>
                                    )}
                                  </div>
                                  <div className="w-full space-y-2">
                                    <label
                                      htmlFor="title"
                                      className="text-[16px] text-gray-500 font-Roboto font-medium"
                                    >
                                      Description
                                      <span className="text-red-500">*</span> :
                                    </label>
                                    <ReactQuill
                                      theme="snow"
                                      value={data.description}
                                      className="rounded-lg h-56"
                                      onChange={(val) => {
                                        data.description = val;
                                        setSpecifications([...specifications]);
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      );
                    })}
                  {newSpecification &&
                    newSpecifications.map((data, index) => {
                      return (
                        <div
                          key={index}
                          className="border px-5 rounded-md border-gray-300"
                        >
                          <div className="pb-10  flex flex-row">
                            <div className="rounded-lg mt-5 space-y-2 w-full">
                              <div className="w-full pt-5 pb-8 space-y-3">
                                <div className="w-full space-y-2">
                                  <label
                                    htmlFor="title"
                                    className="text-[16px] text-gray-500 font-Roboto font-medium text-left w-full"
                                  >
                                    Title
                                    <span className="text-red-500">*</span> :
                                  </label>
                                  <input
                                    type="text"
                                    className="border-2 w-full p-3 rounded-md"
                                    required
                                    value={data.title}
                                    onInput={(e) => {
                                      data.title = e.currentTarget.value;
                                      setNewSpecifications([
                                        ...newSpecifications,
                                      ]);
                                    }}
                                    {...register("descriptionTitle", {
                                      required: "Title is required",
                                    })}
                                  />
                                  {errors.descriptionTitle && (
                                    <p className="text-red-500">
                                      {errors.descriptionTitle?.message}
                                    </p>
                                  )}
                                </div>
                                <div className="w-full space-y-2">
                                  <label
                                    htmlFor="title"
                                    className="text-[16px] text-gray-500 font-Roboto font-medium"
                                  >
                                    Description{" "}
                                    <span className="text-red-500">*</span> :
                                  </label>
                                  <ReactQuill
                                    theme="snow"
                                    value={data.description}
                                    className="rounded-lg h-56"
                                    onChange={(val) => {
                                      data.description = val;
                                      setNewSpecifications([
                                        ...newSpecifications,
                                      ]);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              )}
              {step === 5 && (
                <div className="p-6 rounded-lg mt-5 border border-gray-300 space-y-2">
                  <div className="flex gap-3 items-center">
                    <Switch
                      crossOrigin="switch"
                      color="blue"
                      onClick={handleRangePrice}
                    />
                    <span>Range Price</span>
                  </div>
                  <div className="flex gap-4">
                    <div className="w-full">
                      <label htmlFor="duration" className="custom-label">
                        Ad Run Duration Day
                        <span className="text-red-500">*</span>:
                      </label>
                      <input
                        id="duration"
                        type="number"
                        className="border-2 w-full py-3 px-3 outline-none rounded-md"
                        {...register("adDuration", {
                          required: "Field is Required",
                        })}
                      />
                      {errors.adDuration && (
                        <p className="text-red-500">
                          {errors.adDuration?.message}
                        </p>
                      )}
                    </div>
                    <div className="w-full">
                      <label htmlFor="timeframe" className="custom-label">
                        Timeframe<span className="text-red-500">*</span>:
                      </label>
                      <input
                        id="timeframe"
                        type="number"
                        className="border-2 w-full py-3 px-3 outline-none rounded-md"
                        {...register("submissionTimeframe", {
                          required: "Field is Required",
                        })}
                      />
                      {errors.submissionTimeframe && (
                        <p className="text-red-500">
                          {errors.submissionTimeframe?.message}
                        </p>
                      )}
                    </div>
                  </div>
                  {offerOn ? (
                    <div className="flex justify-start gap-4">
                      <div className="w-full">
                        <label htmlFor="offerAmount" className="custom-label">
                          Offer From<span className="text-red-500">*</span>:
                        </label>
                        <input
                          type="number"
                          className="border-2 w-full py-3 px-3 outline-none rounded-md"
                          {...register("offerAmountFrom", {
                            required: "Field is Required",
                          })}
                        />
                        {errors.offerAmountFrom && (
                          <p className="text-red-500">
                            {errors.offerAmountFrom?.message}
                          </p>
                        )}
                      </div>
                      <div className="w-full">
                        <label htmlFor="offerAmount" className="custom-label">
                          Offer To<span className="text-red-500">*</span>:
                        </label>
                        <input
                          type="number"
                          className="border-2 w-full py-3 px-3 outline-none rounded-md"
                          {...register("offerAmountTo", {
                            required: "Field is Required",
                          })}
                        />
                        {errors.offerAmountTo && (
                          <p className="text-red-500">
                            {errors.offerAmountTo?.message}
                          </p>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="w-full">
                        <label
                          htmlFor="offerAmountFrom"
                          className="custom-label"
                        >
                          Offer Amount<span className="text-red-500">*</span>:
                        </label>
                        <input
                          type="number"
                          className="border-2 w-full py-3 px-3 outline-none rounded-md"
                          {...register("offerAmountFrom", {
                            required: "Field is Required",
                          })}
                        />
                        {errors.offerAmountFrom && (
                          <p className="text-red-500">
                            {errors.offerAmountFrom?.message}
                          </p>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
              {step === 6 && (
                <div className="w-full relative">
                  <label htmlFor="searchProduct" className="custom-label">
                    Search Product:
                  </label>
                  {productSelected === undefined && (
                    <input
                      type="text"
                      autoComplete="off"
                      className="border-2 w-full py-3 px-3 outline-none rounded-md"
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                    />
                  )}
                  {productSelected !== undefined && (
                    <div className="w-full  bg-white rounded-md py-1  border-[2px] p-1 px-3 flex items-center gap-2">
                      <img
                        src={productSelected.images}
                        alt="product"
                        className="w-10 h-10 rounded-full object-cover"
                      />
                      {productSelected.productName}
                      <div
                        onClick={() => {
                          setProductSelected(undefined);
                        }}
                        className="bg-red-500 p-[2px] rounded-full"
                      >
                        <RxCross2
                          size={13}
                          className="font-bold"
                          color="white"
                        />
                      </div>
                    </div>
                  )}
                  {searchValue !== "" && searchList.length > 0 && (
                    <div className="w-full mt-2 bg-white shadow-md border-[1px] p-3 absolute">
                      <ul>
                        {searchList.map((item, index) => {
                          return (
                            <li
                              key={index}
                              onClick={() => {
                                setConnectedTo(item._id);
                                setSearchValue("");
                                setProductSelected(item);
                              }}
                              className="flex items-center gap-2 mt-2 cursor-pointer "
                            >
                              <img
                                src={item.images}
                                alt="product"
                                className="w-11 h-11 rounded-full object-contain border-2"
                              />
                              {item.productName}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}
                </div>
              )}
              {step === 8 && (
                <>
                  <div className=" h-auto ">
                    <div className="space-y-3">
                      <div className="flex justify-end">
                        <Button
                          onClick={addTask}
                          className={`bg-bluePostaam-600 rounded-full py-3 text-[15px] font-bold w-48 text-white ${
                            listOfSubTask && listOfSubTask.length > 0
                              ? "hidden"
                              : "block"
                          }`}
                        >
                          Update Task
                        </Button>
                      </div>
                      <div className="border border-gray-300 rounded-lg bg-white p-6">
                        <div>
                          <label htmlFor="">Action</label>
                        </div>

                        <div>
                          <select
                            className="border-2 w-full py-3 px-3 outline-none rounded-md font-Roboto"
                            onChange={selectAction}
                          >
                            {subTasks.map((ele, index) => (
                              <option key={index} value={ele.action}>
                                {ele.action}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      {listOfSubTask &&
                        listOfSubTask.map((ele, index) => (
                          <UpdateSubGig
                            key={index}
                            ele={ele}
                            index={index}
                            businessName={
                              businessName ? businessName?.user.fullname : ""
                            }
                            listOfSubTask={listOfSubTask}
                            setListOfSubTask={setListOfSubTask}
                            handleDelete={handleDelete}
                            _id={_id}
                            selectedAction={selectedAction}
                            errors={undefined}
                          />
                        ))}
                    </div>
                  </div>
                </>
              )}
              <Button
                type="submit"
                className="bg-bluePostaam-600 py-3 text-sm font-Roboto font-medium w-48 rounded-full tracking-wider mt-5"
                disabled={step === 8}
              >
                <div>{loading ? "Loading" : "Update"}</div>
              </Button>
            </form>
          </Card>
        </div>
      )}
    </section>
  );
};
export default GigUpdate;
