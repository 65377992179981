import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Button,
  Dialog,
  DialogHeader,
  Spinner,
} from "@material-tailwind/react";
import { useForm } from "react-hook-form";
import { RxCross2 } from "react-icons/rx";
import {
  RootSingleBooking,
  StatsBookingProps,
  StatusBooking,
  getSingleBooking,
} from "../../utils/sales";
type FormValue = {
  reason: string;
  description: string;
};
const SingleBooking = () => {
  const [orderInfo, setOrderInfo] = useState<RootSingleBooking>();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const handleOpen = () => setOpen(!open);
  const form = useForm<FormValue>();
  const { register, handleSubmit, formState } = form;
  const { errors } = formState;
  const onsubmit = (data: FormValue) => {
    console.log("Submitted", data);
  };
  useEffect(() => {
    (async () => {
      setLoading(true);
      let response = await getSingleBooking(id!);
      setOrderInfo(response);
      setLoading(false);
    })();
  }, []);
  const handleProcessing = async () => {
    let nextStatus;
    switch (orderInfo?.results.bookingStatus) {
      case "Pending":
        nextStatus = "Completed";
        break;
      default:
        console.log("No valid next status found.");
        return;
    }
    const data: StatsBookingProps = {
      bookingNumber: orderInfo?.results.bookingNumber,
      bookingStatus: nextStatus,
      reason: " ",
      description: " ",
    };

    try {
      const respons = await StatusBooking(data);
      if (respons.status === 200) {
        toast.success(respons.data.message);
      } else {
        toast.success("Status update successfully");
      }
      setOpen(false);
    } catch (error) {
      console.log("Error processing order", error);
    }
  };
  const handleCancel = async () => {
    if (!formState.isValid) {
      return;
    }
    const data: StatsBookingProps = {
      bookingNumber: orderInfo?.results.bookingNumber ?? "",
      bookingStatus: "Cancelled",

      reason: formState.errors.reason ? "" : form.getValues("reason"),
      description: formState.errors.description
        ? ""
        : form.getValues("description"),
    };
    try {
      const respons = await StatusBooking(data);
      if (respons.status === 201) {
        toast.success(respons.data.message);
      } else {
        toast.success("Status Cancelled successfully");
      }
      setOpen(false);
    } catch (error) {
      console.log("Error processing order", error);
    }
  };

  return (
    <section className="p-3">
      {loading && (
        <div className=" flex justify-center items-center h-[60vh]">
          <Spinner className="h-16 w-16 text-gray-600/50" color="blue" />
        </div>
      )}
      {!loading && (
        <div>
          <div className="flex justify-between bg-white py-6 rounded-md shadow-md font-mulish px-4">
            <div className="booking-details">
              <div className="flex items-center">
                <p className="text-gray-800 font-bold text-[1.2rem]">
                  Booking Order:
                </p>
                <p className="text-bluePostaam-600 text-[1.1rem] ml-1 font-[600]">
                  {orderInfo?.results.bookingNumber}
                </p>
              </div>
              <div className="text-[0.9rem] text-gray-600 flex gap-1">
                <p>Placed on</p>{" "}
                <p>
                  {orderInfo?.results.createdAt
                    ? orderInfo?.results.createdAt.split("T")[0]
                    : "N/A"}
                </p>
              </div>
            </div>

            <div className="flex flex-col justify-end">
              <p className="text-[1rem] text-gray-600">
                Total : Rs. {orderInfo?.results.product.price}
              </p>
              <p className="text-[1rem] text-white font-bold  bg-gray-500 px-2 py-1 rounded-md mt-1 text-center capitalize">
                {orderInfo?.results.bookingStatus}
              </p>
            </div>
          </div>

          <div className="flex justify-between mt-5 gap-5 flex-wrap">
            <div className="bg-white p-4 rounded-md shadow-md w-[62%]">
              <div className="flex justify-between gap-8">
                <div className="flex items-center gap-3 font-Roboto">
                  <img
                    src={orderInfo?.results.product.images}
                    alt="order"
                    className="w-16 h-16"
                  />
                  <div>
                    <p className="text-gray-600 text-[0.9rem]">
                      {orderInfo?.results.product.productName}
                    </p>
                    <p className=" text-[0.9rem]">
                      Qty: {orderInfo?.results.product.quantity}
                    </p>
                  </div>
                </div>
                <div>
                  <p className="text-right">
                    Rs. {orderInfo?.results.subTotal}
                  </p>
                  <div className="flex gap-3 mt-1">
                    <Button
                      className={`bg-transparent border-2 py-1 rounded-sm px-2 border-gray-300 text-gray-600 ${
                        orderInfo?.results.bookingStatus === "Completed" ||
                        orderInfo?.results.bookingStatus === "Cancelled"
                          ? "hidden"
                          : "block"
                      }`}
                      onClick={handleProcessing}
                    >
                      {orderInfo?.results.bookingStatus === "Pending"
                        ? "Complete"
                        : ""}
                    </Button>
                    <Button
                      className={`bg-transparent border-2 py-1 rounded-sm px-2 border-gray-300 text-gray-600 ${
                        orderInfo?.results.bookingStatus === "Cancelled"
                          ? "hidden"
                          : "block"
                      }`}
                      onClick={handleOpen}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white w-[36%] rounded-md shadow-md font-Roboto py-2">
              <div className="border-b-[1px] p-3">
                <p className="text-gray-800 font-bold">Order Status</p>
              </div>
              <div className="flex gap-8 px-3 mt-2">
                <ul>
                  <li>Logs</li>
                </ul>
                <ul>
                  <li>Sub Logs</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="flex justify-between mt-5 gap-5 flex-wrap">
            <div className=" w-[62%]">
              <div className=" p-4 bg-white rounded-md shadow-md gap-8">
                <p className=" text-[1rem] text-gray-800 font-bold">Address</p>
                <div className="flex gap-8  mt-2">
                  <ul className="text-gray-600">
                    <li>Company Name:</li>
                    <li>Phone:</li>
                    <li>Email:</li>
                  </ul>
                  <ul className="text-gray-600">
                    <li>
                      {orderInfo?.results.product.companyName ||
                        "Not Available"}
                    </li>
                    <li>
                      {orderInfo?.results.product.phoneNumber ||
                        "Not Available"}
                    </li>
                    <li>{orderInfo?.results.email || "Not Available"}</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="bg-white w-[36%] rounded-md shadow-md font-Roboto">
              <div className="border-b-[1px] p-3">
                <p className="text-gray-800 font-bold">Total Summary</p>
              </div>
              <div className="flex justify-between items-center px-3 mt-2 border-b-[1px] pb-2">
                <ul className="leading-9">
                  <li>Subtotal</li>
                  <li>Commission</li>
                  <li>Discount</li>
                </ul>
                <ul className="leading-9">
                  <li className="text-right">
                    Rs {orderInfo?.results.subTotal}
                  </li>
                  <li className="text-right">
                    {orderInfo?.results.product.commission}
                  </li>
                  <li className="text-right">
                    {orderInfo?.results.product.discountPercentage}%
                  </li>
                </ul>
              </div>
              <div className="p-3">
                <div>
                  <div className="flex justify-between items-center">
                    <p className="text-gray-800 text-[1.2rem] font-[700]">
                      Total
                    </p>
                    <p className="text-gray-800 text-[1.2rem] font-[700]">
                      Rs {orderInfo?.results.subTotal}
                    </p>
                  </div>
                  <p className="text-[0.8rem] text-gray-600">
                    (Inclusive of VAT)
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Dialog open={open} handler={handleOpen} dismiss={{ enabled: false }}>
        <DialogHeader className="flex justify-between items-center">
          Give Reason
          <RxCross2 onClick={handleOpen} className="cursor-pointer" />
        </DialogHeader>
        <form className="formStyle mx-5" onSubmit={handleSubmit(onsubmit)}>
          <div>
            <label htmlFor="reason">Reason:</label>
            <input
              type="text"
              {...register("reason", { required: "Reason is required" })}
            />
            <p className="text-red-500">{errors.reason?.message}</p>
          </div>
          <div className="flex flex-col mt-3">
            <label htmlFor="reason">Description:</label>
            <textarea
              cols={12}
              rows={5}
              {...register("description", {
                required: "Description is required",
              })}
              className="resize-none"
            />
            <p className="text-red-500">{errors.description?.message}</p>
          </div>
          <div className="mb-3 mt-3 flex justify-end items-end">
            ? "N/A" : orderInfo?.results.product.discountPercentage
            <Button
              type="submit"
              onClick={handleCancel}
              className="bg-bluePostaam-600 rounded-md"
            >
              Submit
            </Button>
          </div>
        </form>
      </Dialog>
    </section>
  );
};

export default SingleBooking;
