import { useState, useEffect, useRef } from "react";
import { Button, Card, Carousel } from "@material-tailwind/react";
import { Post } from "../../../utils/post";

type PostItemProps = {
  item: Post;
};

const PostCard = ({ item }: PostItemProps) => {
  const [key, setKey] = useState(0);
  const videoRef = useRef<HTMLVideoElement>(null);

  // Reset media when item changes
  useEffect(() => {
    setKey((prev) => prev + 1);
    if (videoRef.current) {
      videoRef.current.load();
    }
  }, [item.medias]);

  const renderMedia = (medias: { fileType: string; mediaLink: string }[]) => {
    if (medias.length === 1) {
      const mediaLink = medias[0].mediaLink;
      const fileExtension = mediaLink.split(".").pop()?.toLowerCase();

      if (
        fileExtension === "mp4" ||
        fileExtension === "avi" ||
        fileExtension === "mov" ||
        fileExtension === "gif"
      ) {
        return (
          <video
            key={`video-${key}`}
            ref={videoRef}
            controls
            className="w-full h-[30rem] object-contain"
          >
            <source src={mediaLink} type={`video/${fileExtension}`} />
            Your browser does not support the video tag.
          </video>
        );
      } else if (
        fileExtension === "jpg" ||
        fileExtension === "jpeg" ||
        fileExtension === "png"
      ) {
        return (
          <img
            key={`img-${key}`}
            src={mediaLink}
            alt="Post Photo"
            className="w-full h-[30rem] min-h-[30rem] object-contain"
          />
        );
      } else {
        return <p className="text-gray-500">Unsupported Media Type</p>;
      }
    } else {
      return (
        <Carousel className="w-full overflow-hidden">
          {medias.map((media, index) => (
            <div
              key={`carousel-${key}-${index}`}
              className="h-full w-full flex justify-center items-center"
            >
              <img
                src={media.mediaLink}
                alt={`Image ${index + 1}`}
                className="w-full object-contain"
              />
            </div>
          ))}
        </Carousel>
      );
    }
  };

  return (
    <Card className="rounded-lg w-full bg-white overflow-hidden">
      <div className="">
        <div className="h-[30rem] flex justify-center items-center bg-black overflow-hidden">
          {renderMedia(item.medias)}
        </div>
        <div className="p-5 space-y-3">
          <div className="flex justify-between items-center">
            <h3 className="text-xl font-medium text-gray-800 capitalize mt-3">
              {item.postName}
            </h3>
          </div>
          <h5 className="text-base font-normal text-white rounded-full capitalize bg-bluePostaam-600 w-20 text-center py-0.5">
            {item.postType}
          </h5>
          <div className="grid xl:grid-cols-4 gap-2 DashboardCard">
            <div className="CardStats flex flex-col justify-center items-center">
              <h4 className="text-center font-medium text-lg text-bluePostaam-700">
                {item.views}
              </h4>
              <p className="text-center font-medium text-[0.8rem] text-gray-700 ">
                Views
              </p>
            </div>
            <div className="CardStats flex flex-col justify-center items-center">
              <h4 className="text-center font-medium text-lg text-bluePostaam-700">
                {item.likes}
              </h4>
              <p className="text-center font-medium text-[0.8rem] text-gray-700">
                Likes
              </p>
            </div>
            <div className="comment_class flex flex-col justify-center items-center">
              <h4 className="text-center font-medium text-lg text-bluePostaam-700">
                {item.comments}
              </h4>
              <p className="text-center  font-medium text-[0.8rem] text-gray-700">
                Comment
              </p>
            </div>
            <div className="CardStats flex flex-col justify-center items-center">
              <h4 className="text-center font-medium text-lg text-bluePostaam-700">
                {item.shares}
              </h4>
              <p className="text-center font-medium text-[0.8rem] text-gray-700">
                Shares
              </p>
            </div>
          </div>

          <div className="w-full">
            <h4 className="text-xl font-medium text-gray-800 capitalize pb-2">
              Tags
            </h4>

            <div className="flex flex-wrap w-full gap-2 ">
              {item.tags.map((tag, index) => {
                return (
                  <p
                    key={index}
                    className="bg-bluePostaam-500 px-3 py-1 text-white rounded-full text-sm"
                  >
                    #{tag.tagName}
                  </p>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default PostCard;
