import axios from "axios";
import { baseUrl } from ".";

export interface Root {
  status: Number;
  data: {
    message: string;
    results: Results;
  };
}

export interface Results {
  token: string;
  user: User;
}

export interface User {
  id: string;
  fullname: string;
  email: string;
  role: string;
  userHandle: string;
  profileComplete: boolean;
}

export interface LoginProp {
  email: string;
  password: string;
}
export interface SignupProp {
  email: string;
  password: string;
  businessName: string;
  role: string;
}

export interface VerifyProp {
  email: string;
  otp: Number;
}
export interface PwsVerifyProp {
  email: string;
  otp: Number;
  password: string;
}
export interface ForgetProps {
  email: string;
}

// login function
export const userLogin = async (data: LoginProp): Promise<Root> => {
  try {
    const res = await axios.post(baseUrl + "business/login", data);

    return res as Root;
  } catch (error: any) {
    console.log("Error in Login", error);
    return error.response as Root;
  }
};

// SignUp function
export const userSignUp = async (data: SignupProp): Promise<Root> => {
  try {
    const res = await axios.post(baseUrl + "business/register", data);
    return res as Root;
  } catch (error: any) {
    console.log("Email Not Found", error);
    return error.response as Root;
  }
};

// verify OTP function
export const VerifyOTP = async (data: VerifyProp): Promise<Root> => {
  try {
    const res = await axios.put(baseUrl + "business/verify", data);
    return res as Root;
  } catch (error: any) {
    console.log("Invalid OTP", error);
    return error.response as Root;
  }
};

// Forget password function

export const ForgetPws = async (data: ForgetProps): Promise<Root> => {
  try {
    const res = await axios.post(baseUrl + "business/forgetPassword", data);
    return res as Root;
  } catch (error: any) {
    console.log("Error Email Not Matched", error);
    return error.response as Root;
  }
};

// Password Verify OTP
export const PasswordVerify = async (data: PwsVerifyProp): Promise<Root> => {
  try {
    const res = await axios.put(
      baseUrl + "business/forgetPasswordVerify",
      data
    );
    return res as Root;
  } catch (error: any) {
    console.log("Error", error);
    return error.response as Root;
  }
};
