import React, { useEffect, useState } from "react";
import { Dialog } from "@material-tailwind/react";
import { RxCross2 } from "react-icons/rx";
import {
  RootBusinessProfile,
  SubBusiness,
  deleteSubBusiness,
  getBusinessProfile,
} from "../../utils/business";
import Edit from "../../assets/image/edit.png";
import SubBusinessEdit from "../forms/accountandsetting/SubBusinessEdit";
import { toast } from "react-toastify";
import { AiFillDelete } from "react-icons/ai";
import { SUB_BUSINESS_LABEL } from "../../data";
const SubBusinessList = () => {
  const [open, setOpen] = useState(false);
  const [businessProfile, setBusinessProfile] = useState<RootBusinessProfile>();
  const [subBusinessProfile, setSubBusinessProfile] = useState<SubBusiness>();
  const [size, setSize] = React.useState(null);
  const [refetching, setRefetching] = useState(false);
  const handleOpen = (value: any) => {
    setSize(value);
    console.log(size);
    setRefetching(!refetching);
  };

  useEffect(() => {
    (async () => {
      let data = await getBusinessProfile();
      setBusinessProfile(data);
    })();
  }, [refetching]);

  const handleDelete = async (_id: string) => {
    if (window.confirm("Are you sure you want to delete this sub business?")) {
      try {
        const del = await deleteSubBusiness(_id);
        toast.success(del.message);
        setBusinessProfile((prevProfile) => {
          if (prevProfile && del.status === 200) {
            const updatedSubBusinesses = prevProfile.subBusinesses.filter(
              (subBusiness) => subBusiness._id !== _id
            );
            return { ...prevProfile, subBusinesses: updatedSubBusinesses };
          }
          return prevProfile;
        });
      } catch (error) {
        toast.error("Error deleting sub business");
        console.log(error);
      }
    }
  };

  return (
    <section>
      {businessProfile?.subBusinesses.map((subBusiness, index) => {
        return (
          <div className="mt-10" key={index}>
            <div className="bg-white w-full box-shadow p-5 rounded-lg flex justify-between space-x-5">
              <div className="w-full">
                <div className="flex justify-between items-center rounded-md ">
                  <h4 className="SectionHeader">Business Information</h4>
                  <button
                    className="bg-transparent rounded-full border-[1px] mb-2  shadow-none w-10 h-10 border-gray-400 flex justify-center items-center"
                    onClick={() => handleDelete(subBusiness._id)}
                  >
                    <AiFillDelete size={25} color="red" />
                  </button>
                </div>

                <div className="flex">
                  <ul className="font-bold font-Roboto text-gray-600 w-7/12 space-y-4">
                    {SUB_BUSINESS_LABEL.map((item, index) => {
                      return <li key={index}>{item.label}</li>;
                    })}
                  </ul>
                  <ul className="font-Roboto text-base text-gray-600 w-10/12 space-y-4">
                    <li>{subBusiness.businessName || "Not Avaliable"}</li>
                    <li>{subBusiness.ownerName || "Not Avaliable"}</li>
                    <li className="font-Roboto text-base text-gray-600 w-10/12 flex gap-2 flex-wrap">
                      {subBusiness.categories.map((category, index) => {
                        return (
                          <span
                            key={index}
                            className="bg-gray-200 px-3 py-1 rounded-lg text-sm"
                          >
                            {category.name || "Not Available"}
                          </span>
                        );
                      })}
                    </li>
                    <li>{subBusiness.address || "Not Avaliable"}</li>
                    <li>{subBusiness.region || "Not Avaliable"}</li>
                    <li> {subBusiness.zone || "Not Avaliable"}</li>
                    <li>{subBusiness.country || "Not Avaliable"}</li>
                    <li> {subBusiness.area || "Not Avaliable"}</li>
                    <li>{subBusiness.personInChargeName || "Not Avaliable"}</li>
                    <li>
                      {subBusiness.personInChargeEmail || "Not Avaliable"}
                    </li>
                    <li>
                      {subBusiness.personInChargePhone || "Not Avaliable"}
                    </li>
                    <li>{subBusiness.registrationNumber || "Not Avaliable"}</li>
                  </ul>
                </div>
              </div>
              <div
                onClick={() => {
                  handleOpen("xl");
                  setSubBusinessProfile(subBusiness);
                }}
              >
                <img
                  src={Edit}
                  alt="edit"
                  className="w-10 h-10 object-contain"
                />
              </div>
            </div>
          </div>
        );
      })}
      <Dialog
        open={size === "xl"}
        size={size || "xl"}
        handler={() => setOpen(false)}
        dismiss={{ enabled: false }}
      >
        <div
          onClick={handleOpen}
          className="flex justify-end items-center align-middle px-3 py-3.5 cursor-pointer absolute right-0 bg-gray-500 rounded-full text-white h-11 w-11 m-4"
        >
          <RxCross2 size={24} />
        </div>
        <SubBusinessEdit
          businessProfile={subBusinessProfile!}
          handleOpen={handleOpen}
        />
      </Dialog>
    </section>
  );
};

export default SubBusinessList;
