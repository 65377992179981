import {
  Button,
  Popover,
  PopoverContent,
  PopoverHandler,
} from "@material-tailwind/react";
import { format } from "date-fns";
import { DayPicker } from "react-day-picker";
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/24/outline";
import { FiSearch } from "react-icons/fi";
import { useEffect, useRef, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
interface FilterProps {
  setGigTitle: (title: string | undefined) => void;
  setGigType: (gigType: string | undefined) => void;
  handleFilter: () => void;
}
const SalesFilter = ({
  setGigTitle,
  handleFilter,
  setGigType,
}: FilterProps) => {
  const [date, setDate] = useState<Date | undefined>();
  const [date1, setDate1] = useState<Date | undefined>();
  const [businessSelected, setBusinessSelected] = useState("");
  const [openBusinessMenu, setOpenBusinessMenu] = useState(false);
  const businessDropdownRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const handleOutSideBusiness = (event: MouseEvent) => {
      if (
        businessDropdownRef.current &&
        event.target instanceof Node &&
        !businessDropdownRef.current.contains(event.target)
      ) {
        setOpenBusinessMenu(false);
      }
    };
    document.addEventListener("click", handleOutSideBusiness);
    return () => {
      document.removeEventListener("click", handleOutSideBusiness);
    };
  }, [businessDropdownRef]);
  const handelBusinessMenu = () => {
    setOpenBusinessMenu(!openBusinessMenu);
  };
  return (
    <section>
      <div className="flex items-center  bg-white gap-3 rounded-md shadow-sm">
        <div className="w-full">
          <input
            type="text"
            placeholder="Gig Title"
            className="py-2 px-2 border-2 outline-none rounded-md font-Roboto w-full"
            onInput={(e) => setGigTitle(e.currentTarget.value)}
          />
        </div>
        <div className="w-72">
          <div className="dropdown relative " ref={businessDropdownRef}>
            <div
              className={`dropdown-btn py-2 px-2 cursor-pointer border-2 outline-none rounded-md flex items-center justify-between `}
              onClick={handelBusinessMenu}
            >
              <span
                className={`${
                  businessSelected === "Business Name" ||
                  businessSelected === ""
                    ? "text-[#b3b4be]"
                    : "text-black"
                }`}
              >
                {businessSelected ? businessSelected : "Gig Type"}
              </span>
              <IoIosArrowDown className="text-[#a7a7aa]" />
            </div>
            {openBusinessMenu && (
              <div className="dropdown-container bg-white px-1 py-1 shadow-md absolute z-10 w-full rounded-md border-[1px] border-gray-200 mt-1">
                <div
                  className={`dropdown-items py-3 mb-1 px-2 cursor-pointer hover:bg-gray-200 rounded-md ${
                    businessSelected === "freemium" ? "bg-gray-200" : ""
                  }`}
                  onClick={() => {
                    if (businessSelected !== "freemium") {
                      setBusinessSelected("Freemium");
                      setGigType("freemium");
                    }
                    setOpenBusinessMenu(false);
                  }}
                >
                  Freemium
                </div>
                <div
                  className={`dropdown-items py-3 mb-1 px-2 cursor-pointer hover:bg-gray-200 rounded-md ${
                    businessSelected === "premium" ? "bg-gray-200" : ""
                  }`}
                  onClick={() => {
                    if (businessSelected !== "premium") {
                      setBusinessSelected("Premium");
                      setGigType("premium");
                    }
                    setOpenBusinessMenu(false);
                  }}
                >
                  Premium
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="">
          <Button
            className="bg-bluePostaam-600 w-full h-[41.8px] rounded-[5px] lg:w-full hover:shadow-none font-Roboto flex justify-center items-center"
            onClick={handleFilter}
          >
            <FiSearch size={23} />
          </Button>
        </div>
      </div>
    </section>
  );
};

export default SalesFilter;
