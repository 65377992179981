import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Button,
  Dialog,
  DialogHeader,
  Spinner,
} from "@material-tailwind/react";
import { useForm } from "react-hook-form";
import { RxCross2 } from "react-icons/rx";
import {
  RootOrderDetails,
  StatsOrderProps,
  StatusOrder,
  getOrderDetails,
} from "../../utils/sales";
type FormValue = {
  reason: string;
  description: string;
};
const OrderDetails = () => {
  const [orderInfo, setOrderInfo] = useState<RootOrderDetails>();
  const [open, setOpen] = useState(false);
  const [logOpen, setLogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const handleOpen = () => setOpen(!open);
  const handleLogOpen = () => setLogOpen(!logOpen);
  const form = useForm<FormValue>();
  const { register, handleSubmit, formState } = form;
  const { errors } = formState;

  const onsubmit = (data: FormValue) => {
    console.log("Submitted", data);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      let response = await getOrderDetails(id!);
      setOrderInfo(response);
      setLoading(false);
    };

    fetchData();
  }, [id]);
  const handleProcessing = async () => {
    let nextStatus;
    switch (orderInfo?.results.orderStatus) {
      case "Pending":
        nextStatus = "Processing";
        break;
      case "Processing":
        nextStatus = "Completed";
        break;
      case "Completed":
        nextStatus = "Ready To Ship";
        break;

      default:
        console.log("No valid next status found.");
        return;
    }

    const data: StatsOrderProps = {
      id: id!,
      orderStatus: nextStatus,
      reason: " ",
      description: " ",
    };

    try {
      const respons = await StatusOrder(data);
      if (respons.status === 200) {
        toast.success(respons.data.message);
      } else {
        toast.success("Status update successfully");
      }
    } catch (error) {
      console.log("Error processing order", error);
    }
  };
  const handleCancel = async () => {
    if (!formState.isValid) {
      return;
    }
    const data: StatsOrderProps = {
      id: id!,
      orderStatus: "Cancelled",
      reason: formState.errors.reason ? "" : form.getValues("reason"),
      description: formState.errors.description
        ? ""
        : form.getValues("description"),
    };
    try {
      const respons = await StatusOrder(data);
      if (respons.status === 200) {
        toast.success(respons.data.message);
      } else {
        toast.success("Status Cancelled successfully");
      }
    } catch (error) {
      console.log("Error processing order", error);
    }
  };
  return (
    <section className="p-3">
      {loading && (
        <div className=" flex justify-center items-center h-[60vh]">
          <Spinner className="h-16 w-16 text-gray-600/50" color="blue" />
        </div>
      )}
      {!loading && (
        <div>
          <div className="flex justify-between bg-white py-6 rounded-md shadow-md font-mulish px-4">
            <div className="order-details">
              <div className="flex items-center">
                <p className="text-gray-800 font-bold text-[1.2rem]">Order:</p>
                <p className="text-bluePostaam-600 text-[1.1rem] ml-1 font-[600]">
                  {orderInfo?.results.subOrderCode}
                </p>
              </div>
              <div className="text-[0.9rem] text-gray-600 flex gap-1">
                <p>Placed on</p>{" "}
                <p>
                  {orderInfo?.results.createdAt
                    ? orderInfo?.results.createdAt.split("T")[0]
                    : "N/A"}
                </p>
              </div>
            </div>
            <div className="text-[1rem] text-gray-600 flex gap-1">
              <p>Delivery by</p>{" "}
              <p>
                {orderInfo?.results.deliveryDate
                  ? orderInfo.results.deliveryDate.split("T")[0]
                  : "N/A"}
              </p>
            </div>
            <div className="flex flex-col justify-end">
              <p className="text-[1rem] text-gray-600">
                Total : Rs. {orderInfo?.results.details.total}
              </p>
              <p className="text-[1rem] text-white font-bold  bg-gray-500 px-2 py-1 rounded-md mt-1 text-center capitalize">
                {orderInfo?.results.orderStatus}
              </p>
            </div>
          </div>
          <div className="flex justify-between mt-5 gap-5 flex-wrap">
            <div className="bg-white p-4 rounded-md shadow-md w-[55%]">
              <div className="flex justify-between gap-8">
                <div className="flex items-center gap-3 font-Roboto">
                  <img
                    src={orderInfo?.results.product.image}
                    alt="order"
                    className="w-16 h-16"
                  />
                  <div>
                    <p className="text-gray-600 text-[0.9rem]">
                      {orderInfo?.results.product.productName}
                    </p>
                    <div className="flex items-center mt-1">
                      <p className="text-[0.9rem]">
                        Qty: {orderInfo?.results.product.quantity}
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <p className="text-right">
                    Rs. {orderInfo?.results.details.subTotal}
                  </p>
                  <div className="flex gap-3 mt-1">
                    <Button
                      className={`bg-transparent border-2 py-1 rounded-sm px-2 border-gray-300 text-gray-600 ${
                        orderInfo?.results.orderStatus === "Ready To Ship"
                          ? "hidden"
                          : "block"
                      }`}
                      onClick={handleProcessing}
                    >
                      {orderInfo?.results.orderStatus === "Pending"
                        ? "Processing"
                        : orderInfo?.results.orderStatus === "Processing"
                        ? "Completed"
                        : orderInfo?.results.orderStatus === "Completed"
                        ? "Ready To Ship"
                        : "Update Status"}
                    </Button>
                    <Button
                      className="bg-transparent border-2 py-1 rounded-sm px-2 border-gray-300 text-gray-600"
                      onClick={handleOpen}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white w-[43%] rounded-md shadow-md font-Roboto py-2">
              <div className="border-b-[1px] p-3">
                <p className="text-gray-800 font-bold">Order Status</p>
              </div>
              <div className="flex gap-8 px-3 mt-2 items-center">
                <div>
                  <div>
                    {orderInfo?.results.logs.map((log, index) => {
                      return (
                        <div key={index} className="flex font-Roboto leading-7">
                          <p className="text-gray-700">
                            {log.createdAt.split("T")[0]}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div>
                  <div>
                    {orderInfo?.results.logs.map((log, index) => {
                      return (
                        <div
                          key={index}
                          className="flex gap-1 font-Roboto leading-7"
                        >
                          <div className="flex gap-1 items-center">
                            <p className="text-gray-700">Your order has been</p>
                            <p className="text-gray-800 font-500">
                              {log.action ? log.action : "N/A"}
                            </p>
                          </div>
                          <div>
                            {log.action === "Returned" ||
                            log.action === "Exchange" ? (
                              <div className="flex gap-2">
                                <button className="bg-green-400 px-2 rounded-md text-white">
                                  Accept
                                </button>
                                <button
                                  className="bg-red-400 px-2 rounded-md text-white"
                                  onClick={handleLogOpen}
                                >
                                  Cancel
                                </button>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-between mt-5 gap-5 flex-wrap">
            <div className=" w-[62%]">
              <div className="flex p-4 bg-white rounded-md shadow-md justify-between gap-8">
                <div>
                  <div className="flex items-center gap-3 font-Roboto">
                    <div>
                      <p className=" text-[1rem] text-gray-800 font-bold">
                        Shipping Address
                      </p>
                      <div className="flex gap-8  mt-2">
                        <ul className="text-gray-600">
                          <li>Name:</li>
                          <li>Address:</li>
                          <li>Phone:</li>
                          <li>Email:</li>
                        </ul>
                        <ul className="text-gray-600">
                          <li>
                            {orderInfo?.results.shippingAddress.firstname}
                          </li>
                          <li>{orderInfo?.results.shippingAddress.address1}</li>
                          <li>
                            {orderInfo?.results.shippingAddress.phone1},
                            {orderInfo?.results.shippingAddress.phone2}
                          </li>
                          <li>{orderInfo?.results.shippingAddress.email}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex p-4 bg-white rounded-md shadow-md justify-between gap-8 mt-3">
                <div>
                  <div className="flex items-center gap-3 font-Roboto">
                    <div>
                      <p className=" text-[1rem] text-gray-800 font-bold">
                        Billing Address
                      </p>
                      <div className="flex gap-8  mt-2">
                        <ul className="text-gray-600">
                          <li>Name:</li>
                          <li>Address:</li>
                          <li>Phone:</li>
                          <li>Email:</li>
                        </ul>
                        <ul className="text-gray-600">
                          <li>{orderInfo?.results.billingAddress.firstname}</li>
                          <li>{orderInfo?.results.billingAddress.address1}</li>
                          <li>
                            {orderInfo?.results.billingAddress.phone1},{" "}
                            {orderInfo?.results.billingAddress.phone2}
                          </li>
                          <li>{orderInfo?.results.billingAddress.phone2}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white w-[36%] rounded-md shadow-md font-Roboto">
              <div className="border-b-[1px] p-3">
                <p className="text-gray-800 font-bold">Total Summary</p>
              </div>
              <div className="flex justify-between items-center px-3 mt-2 border-b-[1px] pb-2">
                <ul className="leading-9">
                  <li>Subtotal</li>
                  <li>Shipping</li>
                  <li>Discount</li>
                </ul>
                <ul className="leading-9">
                  <li className="text-right">
                    Rs {orderInfo?.results.details.subTotal}
                  </li>
                  <li className="text-right">
                    Rs {orderInfo?.results.details.shipping}
                  </li>
                  <li className="text-right">
                    {orderInfo?.results.details.totalDiscount === 0
                      ? "N/A"
                      : orderInfo?.results.details.totalDiscount}
                  </li>
                </ul>
              </div>
              <div className="px-3 mt-3">
                <div>
                  <div className="flex justify-between items-center">
                    <p className="text-gray-800 text-[1.2rem] font-[700]">
                      Total
                    </p>
                    <p className="text-gray-800 text-[1.2rem] font-[700]">
                      Rs {orderInfo?.results.details.total}
                    </p>
                  </div>
                  <p className="text-[0.8rem] text-gray-600">
                    (Inclusive of VAT)
                  </p>
                </div>
              </div>
              <div className=" p-3 mt-4">
                <div className="bg-green-400 p-3 rounded-md shadow-sm">
                  <p className="text-white font-Roboto">
                    Paid By : {orderInfo?.results.paymentType}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Dialog open={open} handler={handleOpen} dismiss={{ enabled: false }}>
        <DialogHeader className="flex justify-between items-center">
          Give Reason
          <RxCross2 onClick={handleOpen} className="cursor-pointer" />
        </DialogHeader>
        <form className="formStyle mx-5" onSubmit={handleSubmit(onsubmit)}>
          <div>
            <label htmlFor="reason">Reason:</label>
            <input
              type="text"
              {...register("reason", { required: "Reason is required" })}
            />
            <p className="text-red-500">{errors.reason?.message}</p>
          </div>
          <div className="flex flex-col mt-3">
            <label htmlFor="reason">Description:</label>
            <textarea
              cols={12}
              rows={5}
              {...register("description", {
                required: "Description is required",
              })}
              className="resize-none"
            />
            <p className="text-red-500">{errors.description?.message}</p>
          </div>
          <div className="mb-3 mt-3 flex justify-end items-end">
            <Button
              type="submit"
              onClick={handleCancel}
              className="bg-bluePostaam-600 rounded-md"
            >
              Submit
            </Button>
          </div>
        </form>
      </Dialog>
      <Dialog
        open={logOpen}
        handler={handleLogOpen}
        dismiss={{ enabled: false }}
      >
        <DialogHeader className="flex justify-between items-center">
          Give Reason
          <RxCross2 onClick={handleLogOpen} className="cursor-pointer" />
        </DialogHeader>
        <form className="formStyle mx-5">
          <div>
            <label htmlFor="reason">Reason:</label>
            <input type="text" />
            <p className="text-red-500">{errors.reason?.message}</p>
          </div>
          <div className="flex flex-col mt-3">
            <label htmlFor="reason">Description:</label>
            <textarea cols={12} rows={5} className="resize-none" />
            <p className="text-red-500">{errors.description?.message}</p>
          </div>
          <div className="mb-3 mt-3 flex justify-end items-end">
            <Button type="submit" className="bg-bluePostaam-600 rounded-md">
              Submit
            </Button>
          </div>
        </form>
      </Dialog>
    </section>
  );
};
export default OrderDetails;
