import axios from "axios";
import { baseUrl } from ".";

/* api for dashBoard count start */
export interface RootDashboard {
  message: string;
  results: Results;
}

export interface Results {
  dashboardCount: DashboardCount;
}
export interface DashboardCount {
  productCount: number;
  totalEarning: number;
  affiliatedProduct: number;
  totalSales: number;
  activeUsersCount: number;
  sales: number;
}

export const getDashboardData = async (): Promise<RootDashboard> => {
  try {
    const res = await axios.get(baseUrl + "product/dashboardCount", {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("token")!).token,
      },
    });
    return res.data as RootDashboard;
  } catch (error: any) {
    console.log("Error", error);
    return error.response.data as RootDashboard;
  }
};

/* api for dashBoard count End */
