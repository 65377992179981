import { useState } from "react";
import { Button, Spinner } from "@material-tailwind/react";
import { useForm } from "react-hook-form";
import "react-toastify/dist/ReactToastify.css";
import { PasswordVerify } from "../../utils/user";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { IoEye, IoEyeOff } from "react-icons/io5";
type FormValue = {
  email: string;
  password: string;
  otp: Number;
};
const ForgetOtpPws = () => {
  const [isEyeOpen, setIsEyeOpen] = useState(false);
  const handelIsEyeOpen = () => {
    setIsEyeOpen(!isEyeOpen);
  };
  const form = useForm<FormValue>();
  const { register, handleSubmit, formState } = form;
  const { errors } = formState;
  const [loading, setLoading] = useState(false);
  const router = useNavigate();
  const params = new URLSearchParams(window.location.search);
  let email = params.get("email");
  const onsubmit = async (data: FormValue) => {
    console.log("OTP submit", data);
    setLoading(true);
    const response = await PasswordVerify({
      otp: data.otp,
      email: email!,
      password: data.password,
    });
    if (response.status === 200) {
      toast.success("OTP Matched, Reset Your Password");
      router("/login");
    } else {
      toast.error(`"Error" ${response.data.message}`);
    }
    setLoading(false);
  };

  const [isFocus, setIsFocus] = useState(false);
  return (
    <section className="flex flex-col justify-center items-center w-full py-6 h-screen login-background">
      <div className="bg-white p-7 overflow-y-auto login-shadow w-400 mt-8 rounded-md">
        <h3 className="text-4xl font-bold text-bluePostaam-550 text-left ">
          Verification Code
        </h3>
        <form onSubmit={handleSubmit(onsubmit)} className="mt-3">
          <div className="w-full">
            <label
              htmlFor="otpcode"
              className="rounded-md text-Text font-[500] outline-none w-full font-mulish"
            >
              OTP Code<span className="text-red-500">*</span>:
            </label>
            <div
              className={`w-full rounded-md custom-input ${
                errors.otp
                  ? "focus:border-red-500 focus:ring-red-500 border-red-500 border-2"
                  : isFocus
                  ? "focus:border-indigo-500 focus:ring-border-500 border-indigo-500 border-2"
                  : ""
              }`}
              onFocus={() => setIsFocus(true)}
              onBlur={() => setIsFocus(false)}
            >
              <input
                type="text"
                id="otp"
                className="py-5 w-full px-4 rounded-md outline-none"
                {...register("otp", {
                  required: "OTP code is required*",
                })}
              />
            </div>
            <p className="text-red-900 pb-0 mb-0 font-mulish">
              {errors.otp?.message}
            </p>
          </div>
          <div className="mt-3 w-full">
            <label
              htmlFor="newPassword"
              className="rounded-md text-Text font-[500] outline-none w-full font-mulish"
            >
              Set New Password<span className="text-red-500">*</span>:
            </label>
            <div
              className={`w-full rounded-md custom-input relative ${
                errors.password
                  ? "focus:border-red-500 focus:ring-red-500 border-red-500 border-2"
                  : isFocus
                  ? "focus:border-indigo-500 focus:ring-border-500 border-indigo-500 border-2"
                  : ""
              }`}
              onFocus={() => setIsFocus(true)}
              onBlur={() => setIsFocus(false)}
            >
              <input
                type={isEyeOpen === false ? "password" : "text"}
                id="password"
                className="py-5 w-full px-4 rounded-md outline-none"
                {...register("password", {
                  pattern: {
                    value:
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{10,}$/,
                    message:
                      "Password must contain at least :  one lowercase letter, one uppercase letter, one digit, and one special character.",
                  },
                  minLength: {
                    value: 6,
                    message: "Password length must be 6",
                  },
                  required: "Password is required*",
                })}
              />
              <div className="absolute top-5 right-5">
                {isEyeOpen === false ? (
                  <IoEye
                    size={25}
                    className="text-[#757575]"
                    onClick={handelIsEyeOpen}
                  />
                ) : (
                  <IoEyeOff
                    size={25}
                    onClick={handelIsEyeOpen}
                    className="text-[#757575]"
                  />
                )}
              </div>
            </div>
            <p className="text-red-900 pb-0 mb-0 font-mulish">
              {errors.password?.message}
            </p>
          </div>
          <div className="w-full items-center flex justify-center mt-6">
            {!loading && (
              <Button
                type="submit"
                className="login-bg  w-full py-5 font-Roboto font-bold rounded-md  -tracking-tighter text-base text-white capitalize"
              >
                Verify
              </Button>
            )}
            {loading && (
              <div className="flex items-center gap-2 login-bg text-white w-full justify-center py-5 tracking-wider text-base outline-none">
                <Spinner className="h-4 w-4" />
                <p>Loading</p>
              </div>
            )}
          </div>
        </form>
      </div>
    </section>
  );
};

export default ForgetOtpPws;
