import { RootDashboard } from "../../utils/home";
interface Information1 {
  data: RootDashboard;
}
const Information = ({ data }: Information1) => {
  const cardData = [
    {
      title: "Daily Sale",
      value: data.results.dashboardCount.sales || 0,
    },
    {
      title: "Total Earning",
      value: `Rs. ${data.results.dashboardCount.totalEarning || 0}`,
    },
    {
      title: "Total Sales",
      value: data.results.dashboardCount.totalSales || 0,
    },
    {
      title: "Affiliated Products",
      value: data.results.dashboardCount.affiliatedProduct || 0,
    },
    {
      title: "Total Products",
      value: data.results.dashboardCount.productCount || 0,
    },
  ];
  return (
    <>
      <section className="px-4 space-y-6">
        <div className="w-full grid grid-cols-5 space-x-3 cardstyle">
          {cardData.map((data, index) => {
            return (
              <div
                key={index}
                className="px-5 py-5 box-shadow items-center bg-white rounded-md"
              >
                <h4 className="cardValue">{data.value}</h4>
                <p className="cardTitle">{data.title}</p>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};
export default Information;
