import { toast } from "react-toastify";
import { useEffect, useState } from "react";

import { GigApprove, GigChats, GigChatUserList, GigDetailsUserList, GigUser, GigUserListReultProps, ResulUsertChatsProps, RootObject } from "../../../utils/gig";
import UserBox from "./UserBox";
import { FaCross } from "react-icons/fa";
import { IoCloseCircle } from "react-icons/io5";
import { Spinner } from "@material-tailwind/react";
import GigChatMessage from "../gig/GigChatMessage";

interface UserListProps {
  buttonAction: String;
  setReFetch: (value: boolean) => void;
  reFetch: boolean;
  requirePosting?: boolean;
  id: string,
  tab: string
}
const UserList = ({
  buttonAction,
  setReFetch,
  reFetch,
  requirePosting,
  id,
  tab
}: UserListProps) => {
  const [gigUserChatList, setGigUserChatList] = useState<ResulUsertChatsProps[]>([]);
  const [activeUser, setActiveUser] = useState<GigUser>();
  const [loading, setLoading] = useState(true)
  const [loadingMore, setLoadingMore] = useState(false)
  const [pagination, setPagination] = useState<{ total: number, next?: number, previous?: number }>();
  const [chatId, setChatId] = useState<string>()
  const getUserChats = async (id: string, user: GigUser) => {
    try {
      setChatId(id)
      setActiveUser(user)
      const responsChatUserList = await GigChats(
        id
      );
      setGigUserChatList(responsChatUserList.results);
    } catch (error) {
      console.error("Error fetching gig user chat list:", error);
    }
  };
  const [users, setUsers] = useState<GigUser[]>()
  useEffect(() => {
    (async () => {
      const response: RootObject = await GigDetailsUserList({ id: id!, page: 1, limit: 5, gigStatus: tab })
      if (response.status == 200) {
        setUsers(response.data.results)
        setPagination(() => response.data.pagination)
        setLoading(false)
      }
    })()
  }, [tab])

  const loadMore = async () => {
    setLoadingMore(true)
    if (pagination?.next) {
      const response: RootObject = await GigDetailsUserList({ id: id!, page: pagination.next, limit: 5, gigStatus: tab })
      if (response.status == 200) {
        setUsers((prev) => [...prev!, ...response.data.results])
        setPagination(() => response.data.pagination)
        setLoadingMore(false)
      }
    }

  }

  return (
    <div className="flex gap-3 flex-col">
      {loading && <div className=" flex justify-center items-center h-[60vh]">
        <Spinner className="h-16 w-16 text-gray-600/50" color="blue" />
      </div>}
      {!loading && users && users?.length > 0 && <div >
        <strong>Total : {pagination?.total} Users</strong>
      </div>}
      {!loading && users?.map((data, index) => <div key={index} >
        <UserBox data={data} buttonAction={buttonAction} requirePosting={requirePosting} setReFetch={setReFetch} reFetch={reFetch} getUserChats={getUserChats} />
      </div>)}
      {!loading && users?.length === 0 &&
        <div>
          No User Found
        </div>}

      {
        gigUserChatList.length > 0 && (<div className="fixed top-0 bottom-0 right-0 left-0 bg-black/20 " style={{ zIndex: 9999999999999 }}>
          <div className="bg-gray-50 w-1/3 h-[750px] top-1/4 left-1/2 -translate-x-1/2 absolute p-5 border rounded-lg" >
            <div className="flex justify-between mb-5">
              <p className="text-sm text-gray-500"> {activeUser!.user.fullname}</p>
              <IoCloseCircle className="h-8 w-8 text-gray-500" onClick={() => setGigUserChatList([])} />
            </div>

            <GigChatMessage chatId={chatId!} roomId={activeUser!.user.userHandle} gigId={activeUser!._id} user={{
              fullname: activeUser!.user.fullname,
              profileFile: activeUser!.user.profileFile
            }} />
          </div>
        </div>)
      }
      {!loadingMore && pagination?.next && <div onClick={loadMore} className="bg-blue-300 w-28 text-center p-2 rounded-lg text-white cursor-pointer">
        Load More
      </div>}
      {loadingMore && <div className="bg-blue-100 w-28 text-center p-2 rounded-lg flex items-center justify-center">
        <Spinner className="h-5 w-5 text-gray-600/50" color="blue" />
      </div>}
    </div>


  );
};

export default UserList;
