import { Button } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import {
  CountryListProps,
  ProvinceListProps,
  RootBusinessProfile,
  RootSubBusiness,
  addSubBusiness,
  getBusinessProfile,
  getCountryList,
  getProvinceList,
} from "../../../../utils/business";
import {
  ResultProductCategory,
  getProductCategory,
} from "../../../../utils/product";

type FormValue = {
  businessName: string;
  email: string;
  role: string;
  connectedHandle: string;
  ownerName: string;
  country: string;
  region: string;
  zone: string;
  city: string;
  area: string;
  address: string;
  personInChargeName: string;
  personInChargePhone: string;
  personInChargeEmail: string;
  registrationNumber: string;
  registerationFileUrl: string;
  profileFileUrl: string;
  categories: string;
  registerationFile: File[];
  _id: string;
};

interface subBusiness {
  businessProfile: RootBusinessProfile;
  handleOpen1: any;
}

const SubBusinessForm = ({ handleOpen1 }: subBusiness) => {
  const [loading, setLoading] = useState(false);

  const form = useForm<FormValue>();
  const { register, handleSubmit, formState, setValue } = form;
  const { errors } = formState;
  const [countryList, setCountryList] = useState<CountryListProps[]>([]);
  const [provinceList, setProvinceList] = useState<ProvinceListProps[]>([]);

  const [selectedBusinessFiles, setSelectedBusinessFiles] = useState<File[]>(
    []
  );
  const [businessinfo, setBusinessInfo] = useState<RootBusinessProfile>();
  const [showCopiedData, setShowCopiedData] = useState(false);
  const [cunt, setCunt] = useState("");
  const [categories, setCategories] = useState<ResultProductCategory[]>();
  useEffect(() => {
    (async () => {
      try {
        let resCountry = await getCountryList();
        setCountryList(resCountry);

        let resProvince = await getProvinceList();
        setProvinceList(resProvince);
      } catch (error) {
        console.log("Error fetching seller account:", error);
      }
    })();
  }, [setValue]);
  const onsubmit = async (data: FormValue) => {
    if (!loading) {
      setLoading(true);
      try {
        const formData = new FormData();
        formData.append("businessName", data.businessName);
        formData.append("personInChargeName", data.personInChargeName);
        formData.append("personInChargePhone", data.personInChargePhone);
        formData.append("personInChargeEmail", data.personInChargeEmail);
        formData.append("country", data.country);
        formData.append("region", data.region);
        formData.append("zone", data.zone);
        formData.append("city", data.city);
        formData.append("area", data.area);
        formData.append("email", data.email);
        data.categories &&
          formData.append("categories", JSON.stringify([data.categories]));
        formData.append("address", data.address);
        formData.append("registrationNumber", data.registrationNumber);

        if (selectedBusinessFiles && selectedBusinessFiles.length > 0) {
          const businessimagesArray = Array.from(selectedBusinessFiles);
          businessimagesArray.forEach((registerationFile) => {
            formData.append("registerationFile", registerationFile);
          });
        }

        const apiResponse: RootSubBusiness = await addSubBusiness(formData);

        setLoading(false);
        handleOpen1();
        toast.success(apiResponse.data.massage);
      } catch (error) {
        console.log("Error", error);
      }
    }
  };
  useEffect(() => {
    if (showCopiedData) {
      (async () => {
        try {
          let resCountry = await getCountryList();
          setCountryList(resCountry);

          let resProvince = await getProvinceList();
          setProvinceList(resProvince);

          const businessProfile = await getBusinessProfile();
          setValue("businessName", businessProfile.results.businessName);
          setValue(
            "personInChargeName",
            businessProfile.results.personInChargeName
          );
          setValue(
            "personInChargePhone",
            businessProfile.results.personInChargePhone
          );
          setValue(
            "personInChargeEmail",
            businessProfile.results.personInChargeEmail
          );
          setValue("country", businessProfile.results.country);
          setValue("region", businessProfile.results.region);
          setValue("zone", businessProfile.results.zone);
          setValue("city", businessProfile.results.city);
          setValue("area", businessProfile.results.area);
          setValue("address", businessProfile.results.address);
          setValue(
            "registrationNumber",
            businessProfile.results.registrationNumber
          );
        } catch (error) {
          console.log("Error fetching seller account:", error);
        }
      })();
    }
  }, [showCopiedData, setValue]);
  useEffect(() => {
    (async () => {
      let data = await getBusinessProfile();
      setBusinessInfo(data);
    })();
  }, []);
  useEffect(() => {
    (async () => {
      let data = await getProductCategory();
      setCategories(data.results);
    })();
  }, []);
  return (
    <section>
      <div className="">
        <div className="flex justify-between items-center">
          <h4 className="popupHeader">Business Information</h4>
          <Button
            className="bg-bluePostaam-600 mr-20 h-8 flex justify-center items-center rounded-md"
            onClick={() => setShowCopiedData(true)}
          >
            Copy from Previous
          </Button>
        </div>
        <form className="px-8 pb-7 formStyle" onSubmit={handleSubmit(onsubmit)}>
          <div className="overflow-auto">
            <div className="grid grid-cols-3 gap-4 ">
              <div className="">
                <label htmlFor="Fname" className="">
                  Legal Name :
                </label>
                <input
                  type="text"
                  className="border-2 w-full py-3 px-3 outline-none rounded-md"
                  {...register("businessName", {
                    required: "Business Name is required",
                  })}
                />
                <p className="text-red-500">{errors.businessName?.message}</p>
              </div>
              <div className="">
                <label htmlFor="charge" className="">
                  Person in Charge:
                </label>
                <input
                  type="text"
                  className="border-2 w-full py-3 px-3 outline-none rounded-md"
                  {...register("personInChargeName")}
                />
              </div>
              <div className="">
                <label htmlFor="email" className="">
                  Person in Charge Mobile Number:
                </label>
                <input
                  type="text"
                  className="border-2 w-full py-3 px-3 outline-none rounded-md"
                  {...register("personInChargePhone")}
                />
              </div>
              <div className="">
                <label htmlFor="email" className="">
                  Person in Charge Email:
                </label>
                <input
                  type="text"
                  className="border-2 w-full py-3 px-3 outline-none rounded-md"
                  {...register("personInChargeEmail")}
                />
              </div>
              <div className="">
                <label htmlFor="email" className="">
                  Country <span className="text-red-500">*</span>:
                </label>
                <select
                  className="border-2 w-full py-3 px-3 outline-none rounded-md"
                  {...register("country", { required: "Country is required" })}
                  onChange={(e) => {
                    setCunt(e.target.value);
                  }}
                >
                  {countryList.map((country, index) => (
                    <option value={country.name} key={index}>
                      {country.name}
                    </option>
                  ))}
                </select>
                <p className="text-red-500">{errors.country?.message}</p>
              </div>
              <div className="">
                <label htmlFor="email" className="">
                  Region <span className="text-red-500">*</span>:
                </label>

                {cunt == "Nepal" && (
                  <select
                    className="border-2 w-full py-3 px-3 outline-none rounded-md"
                    {...register("region", { required: "Region is required" })}
                  >
                    {provinceList.map((province, index) => (
                      <option value={province.name} key={index}>
                        {province.name}
                      </option>
                    ))}
                  </select>
                )}
                {cunt != "Nepal" && (
                  <input
                    type="text"
                    className="border-2 w-full py-3 px-3 outline-none rounded-md"
                    {...register("region", { required: "Region is required" })}
                  />
                )}
                <p className="text-red-500">{errors.region?.message}</p>
              </div>
              <div className="">
                <label htmlFor="email" className="">
                  Zone <span className="text-red-500">*</span>:
                </label>
                <input
                  type="text"
                  className="border-2 w-full py-3 px-3 outline-none rounded-md"
                  {...register("zone", { required: "Zone is required" })}
                />
                <p className="text-red-500">{errors.zone?.message}</p>
              </div>
              <div className="">
                <label htmlFor="email" className="">
                  Area <span className="text-red-500">*</span>:
                </label>
                <input
                  type="text"
                  className="border-2 w-full py-3 px-3 outline-none rounded-md"
                  {...register("area", { required: "Area is required" })}
                />
                <p className="text-red-500">{errors.area?.message}</p>
              </div>
              <div className="">
                <label htmlFor="email" className="">
                  Address<span className="text-red-500">*</span>:
                </label>
                <input
                  type="text"
                  className="border-2 w-full py-3 px-3 outline-none rounded-md"
                  {...register("address", { required: "Address is required" })}
                />
                <p className="text-red-500">{errors.address?.message}</p>
              </div>

              <div className="">
                <label htmlFor="email" className="">
                  Business PAN/VAT
                  <span className="text-red-500">*</span>:
                </label>
                <input
                  type="text"
                  className="border-2 w-full py-3 px-3 outline-none rounded-md"
                  {...register("registrationNumber", {
                    required: "Registration Number is required",
                  })}
                />
                <p className="text-red-500">
                  {errors.registrationNumber?.message}
                </p>
              </div>
              <div className="">
                <label htmlFor="email" className="">
                  Email
                  <span className="text-red-500">*</span>:
                </label>
                <input
                  type="text"
                  className="border-2 w-full py-3 px-3 outline-none rounded-md"
                  {...register("email", {
                    required: "Email is required",
                  })}
                />
                <p className="text-red-500">{errors.email?.message}</p>
              </div>
              <div className="">
                <label htmlFor="email" className="">
                  Category<span className="text-red-500">*</span> :
                </label>
                <select
                  className="border-2 w-full py-3 px-3 outline-none rounded-md"
                  {...register("categories", {
                    required: "Category is required",
                  })}
                >
                  <option value="" selected disabled>
                    Select Category
                  </option>
                  {categories?.map((category, index) => {
                    return (
                      <option value={category.name} key={index}>
                        {category.name}
                      </option>
                    );
                  })}
                </select>
                <p className="text-red-500">{errors.categories?.message}</p>
              </div>
            </div>
          </div>
          <div className="w-full flex justify-end">
            <Button type="submit" className="submitButton">
              <div>{loading ? "Loading" : "Submit"}</div>
            </Button>
          </div>
        </form>
      </div>
    </section>
  );
};
export default SubBusinessForm;
