import { Button } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import SalesData from "../components/tables/Sales";
import { Result, RootSales, salesList } from "../utils/sales";

const Revenu = () => {
  const [open, setOpen] = useState(false);
  const [salesData, setSalesData] = useState<Result[]>([]);
  const [pendingCount, setPendingCount] = useState(0);
  const [completedCount, setCompletedCount] = useState(0);
  const [cancelledCount, setCancelledCount] = useState(0);
  const [processingCount, setProcessingCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const res: RootSales = await salesList({
        page: 1,
        limit: 10,
      });
      if (res.status === 200) {
        setSalesData(res.data.results);
        // Calculate counts
        const pending = res.data.results.filter(
          (item) => item.saleStatus === "Pending"
        ).length;
        const completed = res.data.results.filter(
          (item) => item.saleStatus === "Completed"
        ).length;
        const cancelled = res.data.results.filter(
          (item) => item.saleStatus === "Cancelled"
        ).length;
        const Processing = res.data.results.filter(
          (item) => item.saleStatus === "Processing"
        ).length;
        setPendingCount(pending);
        setCompletedCount(completed);
        setCancelledCount(cancelled);
      }
    };
    fetchData();
  }, []);

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <section className="max-w-7xl m-auto space-y-6">
      <div className="w-full grid grid-cols-12 space-x-3 cardstyle">
        <div className="px-5 col-span-3 py-5 box-shadow items-center bg-white rounded-md ">
          <h4 className="cardValue">{pendingCount}</h4>
          <p className="cardTitle">Pending</p>
        </div>
        <div className="px-5 col-span-3 py-5 box-shadow items-center bg-white rounded-md">
          <h4 className="cardValue">{processingCount}</h4>
          <p className="cardTitle">Processing</p>
        </div>
        <div className="px-5 col-span-3 py-5 box-shadow  bg-white rounded-md items-center">
          <h4 className="cardValue">{cancelledCount}</h4>
          <p className="cardTitle"> Cancelled</p>
        </div>
        <div className="px-5 col-span-3 py-5 box-shadow items-center bg-white rounded-md ">
          <h4 className="cardValue">{completedCount}</h4>
          <p className="cardTitle"> Completed</p>
        </div>
      </div>
      <div className="flex justify-start flex-col items-start bg-white rounded-md box-shadow px-3 py-2">
        <Button className="filterbutton" onClick={handleOpen}>
          Filter
        </Button>
        {open && (
          <div className="bg-white shadow-md w-40 z-20 rounded-md border-[1px] border-gray-200 absolute mt-12">
            <ul>
              <li
                className="p-3 cursor-pointer hover:bg-indigo-50"
                onClick={handleOpen}
              >
                All
              </li>
              <li
                className="p-3 cursor-pointer hover:bg-indigo-50"
                onClick={handleOpen}
              >
                Weekly
              </li>
              <li
                className="p-3 cursor-pointer hover:bg-indigo-50"
                onClick={handleOpen}
              >
                Monthly
              </li>
              <li
                className="p-3 cursor-pointer hover:bg-indigo-50"
                onClick={handleOpen}
              >
                Yearly
              </li>
            </ul>
          </div>
        )}
      </div>

      <div>
        <SalesData />
      </div>
    </section>
  );
};

export default Revenu;
