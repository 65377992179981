import { Card, CardBody, Spinner } from "@material-tailwind/react";
import { TABLE_HEAD_GIG_LIST } from "../../data";
import { useEffect, useState } from "react";
import { IoMdEye } from "react-icons/io";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

import SalesFilter from "../../pages/products/filters/salesFilter";
import Pagination from "../Pagination";
import {
  GigListPost,
  PaginationProps,
  ResultGigList,
  RootGigList,
} from "../../utils/gig";
const GigList = () => {
  const [gigList, SetGigList] = useState<ResultGigList[]>([]);
  const limit = 10;
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [gigTitle, setGigTitle] = useState<string>();
  const [gigType, setGigType] = useState<string>();
  const [pagination, setPagination] = useState<PaginationProps>();
  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        const response: RootGigList = await GigListPost({
          page: page,
          limit: limit,
        });
        if (response.status === 200) {
          SetGigList(response.data.results);
          setPagination(response.data.pagination);
        } else {
          console.error("Error fetching gig list:", response);
        }
      } catch (error) {
        console.error("Error fetching gig list:", error);
      }
      setLoading(false);
    })();
  }, []);

  const filterAction = async () => {
    try {
      const response: RootGigList = await GigListPost({
        page: page,
        limit: limit,
        title: gigTitle?.length ? gigTitle : undefined,
        gigType: gigType?.length ? gigType : undefined,
      });
      if (response.status === 200) {
        SetGigList(response.data.results);
        setPagination(response.data.pagination);
      } else {
        console.error("Error fetching gig list:", response);
      }
    } catch (error: any) {
      toast.error(JSON.stringify(error));
      console.error("Error fetching gig list:", error);
    }
  };
  const nextAction = async () => {
    try {
      if (pagination?.next !== undefined) {
        setPage(pagination.next);
        console.log("Next arrow clicked. New skip value:", pagination.next);
        const response: RootGigList = await GigListPost({
          page: pagination!.next!,
          limit: limit,
        });
        if (response.status === 200) {
          SetGigList(response.data.results);
          setPagination(response.data.pagination);
        } else {
          console.error("Error fetching gig list:", response);
        }
      }
    } catch (error) {
      console.error("Error fetching gig list:", error);
    }
  };

  const previousAction = async () => {
    try {
      if (pagination?.previous !== undefined) {
        setPage(pagination.previous);
        const response: RootGigList = await GigListPost({
          page: pagination!.previous!,
          limit: limit,
        });
        if (response.status === 200) {
          SetGigList(response.data.results);
          setPagination(response.data.pagination);
        } else {
          console.error("Error fetching gig list:", response);
        }
      }
    } catch (error) {
      console.error("Error fetching gig list:", error);
    }
  };

  return (
    <section className="max-w-7xl m-auto ">
      <div className=" space-y-3">
        <div className="col-span-12 border border-gray-300 rounded-lg bg-white p-4">
          <h6 className="text-gray-800 font-bold text-[1.2rem]">Gig List</h6>
        </div>
        <div className="border border-gray-300 rounded-lg bg-white p-3 col-span-12">
          <SalesFilter
            setGigTitle={setGigTitle}
            setGigType={setGigType}
            handleFilter={filterAction}
          />
        </div>

        <Card className="h-full w-full mt-3">
          <CardBody className="overflow-hidden px-0 py-0">
            {loading && (
              <div className=" flex justify-center items-center h-[60vh]">
                <Spinner className="h-16 w-16 text-gray-600/50" color="blue" />
              </div>
            )}
            {!loading && (
              <div className="h-full w-full">
                <table className="w-full min-w-max table-auto text-left ">
                  <thead>
                    <tr>
                      {TABLE_HEAD_GIG_LIST.map((head) => (
                        <th
                          key={head}
                          className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 text-sm text-blue-gray-900 font-normal leading-none opacity-70"
                        >
                          {head}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {gigList.length === 0 && (
                      <tr>
                        <td className="px-6 py-3">No Data Available</td>
                      </tr>
                    )}
                    {gigList.length > 0 &&
                      gigList.map((item, index) => {
                        const isLast = index === gigList.length - 0;
                        const classes = isLast
                          ? "p-4"
                          : "p-4 border-b border-blue-gray-50";

                        return (
                          <tr key={index}>
                            <td className={`${classes} font-normal text-sm`}>
                              {index + 1}
                            </td>
                            <td
                              className={`${classes} text-gray-600 text-base hover:underline hover:text-bluePostaam-600`}
                            >
                              {item.title}
                            </td>
                            <td
                              className={`${classes} text-gray-600 text-base`}
                            >
                              {item.numberofcreator}
                            </td>
                            <td className={`${classes} font-normal text-sm`}>
                              {item.gigType}
                            </td>
                            <td className={`${classes} font-normal text-sm`}>
                              {item.numberofimage}/
                              {item.numberofvideo ? item.numberofvideo : "N/A"}
                            </td>

                            <td className={`${classes} font-normal text-sm`}>
                              {item.createdAt.split("T")[0]}
                            </td>
                            <td className={`${classes} font-normal text-sm`}>
                              <Link
                                to={`/gigDetails/${item._id}`}
                                className="flex justify-center items-center rounded-full bg-bluePostaam-500 shadow-none hover:shadow-none h-10 w-10"
                              >
                                <IoMdEye size={18} className="text-white" />
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            )}
          </CardBody>
          <div className="mt-2">
            {pagination && (
              <Pagination
                nextAction={nextAction}
                previousAction={previousAction}
                total={pagination.total}
                currentPage={page}
              />
            )}
          </div>
        </Card>
      </div>
    </section>
  );
};
export default GigList;
