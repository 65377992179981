import axios from "axios";
import { baseUrl } from ".";
import { async } from "q";
export interface RootBusinessProfile {
  message: string;
  results: Results;
  subBusinesses: SubBusiness[];
}

export interface Results {
  status: boolean;
  categories: Category[];
  subCategories: SubCategory[];
  serviceCategories: any[];
  verified: boolean;
  companyVerification: boolean;
  profileComplete: boolean;
  socialAccounts: any[];
  deliveryStatus: boolean;
  deliveryBy: string;
  _id: string;
  businessName: string;
  userHandle: string;
  email: string;
  role: string;
  otp: string;
  registrationDate: any;
  registrationNumber: string;
  password: string;
  registeredBy: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
  profileFile: string;
  registerationFile: string;
  vatFile: string;
  bio: string;
  location: string;
  ownerName: string;
  phoneNumber: string;
  vatNumber: string;
  checkInTime: string;
  checkOutTime: string;
  closingTime: string;
  deliveryTime: string;
  openingTime: string;
  city: string;
  address: string;
  fullName: string;
  country: string;
  region: string;
  zone: string;
  area: string;
  personInChargeName: string;
  personInChargePhone: string;
  personInChargeEmail: string;
  profileImage: string;
  accountName: string;
  accountNumber: string;
  bankName: string;
  branchName: string;
  bankCode: string;
  iban: string;
}

export interface Category {
  _id: string;
  name: string;
  percentage: number;
  slug: string;
}

export interface SubCategory {
  index: number;
  subCategories: SubCategory2[];
  _id: string;
  name: string;
  category: string;
  userHandle: string;
  slug: string;
  __v: number;
  percentage: number;
  deleteStatus?: boolean;
}

export interface SubCategory2 {
  index: number;
  subCategories: any[];
  _id: string;
  name: string;
  category: string;
  mainCategory: string;
  userHandle: string;
  slug: string;
  __v: number;
  percentage?: number;
}

export interface SubBusiness {
  status: boolean;
  categories: Category2[];
  subCategories: any[];
  serviceCategories: any[];
  verified: boolean;
  companyVerification: boolean;
  profileComplete: boolean;
  socialAccounts: any[];
  percentage: number;
  _id: string;
  businessName: string;
  userHandle: string;
  email: string;
  role: string;
  otp: string;
  connectedHandle: string;
  ownerName: string;
  country: string;
  region: string;
  zone: string;
  area: string;
  address: string;
  personInChargeEmail: string;
  registrationNumber: string;
  registerationFileUrl?: string;
  profileFileUrl?: string;
  createdAt: string;
  personInChargeName: string;
  personInChargePhone: string;
  updatedAt: string;
  __v: number;
  profileFile?: string;
  registerationFile?: string;
}

export interface Category2 {
  _id: string;
  name: string;
  percentage: number;
  slug: string;
}

export const getBusinessProfile = async (): Promise<RootBusinessProfile> => {
  try {
    const res = await axios.get(baseUrl + "business/profile", {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("token")!).token,
      },
    });
    return res.data as RootBusinessProfile;
  } catch (error: any) {
    console.log("Error", error);
    return error.response.data as RootBusinessProfile;
  }
};

export const deleteSubBusiness = async (
  _id: string
): Promise<RootBusinessProfile & { status: number }> => {
  try {
    const res = await axios.delete(baseUrl + `business/subBusiness/${_id}`, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("token")!).token,
      },
    });
    return {
      ...res.data,
      status: res.status,
    };
  } catch (error: any) {
    console.log("Error", error);
    return {
      ...error.response.data,
      status: error.response.status,
    };
  }
};

export interface CountryListProps {
  name: string;
  dial_code: string;
  code: string;
}

export interface ProvinceListProps {
  name: string;
  value: string;
}

export const getCountryList = async (): Promise<CountryListProps[]> => {
  try {
    const res = await axios.get(baseUrl + "business/country");
    return res.data.results;
  } catch (error: any) {
    console.log("Error", error);
    return error.response.data as CountryListProps[];
  }
};

export const getProvinceList = async (): Promise<ProvinceListProps[]> => {
  try {
    const res = await axios.get(baseUrl + "business/province");
    return res.data.results;
  } catch (error: any) {
    console.log("Error", error);
    return error.response.data as ProvinceListProps[];
  }
};

// Business Profile Update started

export interface RootBusinessProfileUpdate {
  status: number;
  data: RootSingleBusiness;
}
export interface formData {
  name: string;
  description: string;
}
export interface RootSingleBusiness {
  message: string;
  results: ResultsBusinessProfileUpdate;
}

export interface ResultsBusinessProfileUpdate {
  subCategories: SubCategory[];
  companyVerification: boolean;
  socialAccounts: any[];
  _id: string;
  address: string;
  city: string;
  businessName: string;
  email: string;
  registrationDate: any;
  registrationNumber: string;
  profileFile: string;
  registerationFile: string;
  location: string;
  ownerName: string;
  phoneNumber: string;
  accountName: string;
  accountNumber: string;
  bankName: string;
  branchName: string;
  bankCode: string;
  vatNumber: string;
  personInChargePhone: string;
  personInChargeName: string;
  personInChargeEmail: string;
  zone: string;
  area: string;
  connectedHandle: string;
  registerationFileUrl: string;
  profileFileUrl: string;
  iban: any[];
  otp: number;
}

export interface SubCategory {
  index: number;
  subCategories: SubCategory2[];
  _id: string;
  name: string;
  category: string;
  userHandle: string;
  slug: string;
  __v: number;
  percentage: number;
  deleteStatus?: boolean;
}

export interface SubCategory2 {
  index: number;
  subCategories: any[];
  _id: string;
  name: string;
  category: string;
  mainCategory: string;
  userHandle: string;
  slug: string;
  __v: number;
  percentage?: number;
}

// export interface sellerAccountProps {
//   email: string;
//   phoneNumber: string;
// }
// export interface bankAccountProps {
//   accountName: string;
//   accountNumber: string;
//   bankName: string;
//   branchName: string;
//   bankCode: string;
//   iban: any[];
// }
export const putSellerAccount = async (
  data: FormData
): Promise<RootBusinessProfileUpdate> => {
  try {
    const res = await axios.put(baseUrl + "business/updateProfile", data, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("token")!).token,
      },
    });
    return res as RootBusinessProfileUpdate;
  } catch (error: any) {
    console.log("Error", error);
    return error.response as RootBusinessProfileUpdate;
  }
};

export const putBankAccount = async (
  data: FormData
): Promise<RootBusinessProfileUpdate> => {
  try {
    const res = await axios.put(baseUrl + "business/updateProfile", data, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("token")!).token,
      },
    });
    return res as RootBusinessProfileUpdate;
  } catch (error: any) {
    console.log("Error", error);
    return error.response as RootBusinessProfileUpdate;
  }
};

// Business Profile Update ended

// SubBusiness Create started

export interface RootSubBusiness {
  status: number;
  data: RootSingleSubBusiness;
}
export interface formData {
  name: string;
  description: string;
}

export interface RootSingleSubBusiness {
  massage: string;
  results: ResultSingleSubBusiness;
}

export interface ResultSingleSubBusiness {
  businessName: string;
  _id: string;
}

export const addSubBusiness = async (
  data: FormData
): Promise<RootSubBusiness> => {
  try {
    const res = await axios.post(`${baseUrl}business/subBusinessCreate`, data, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("token")!).token,
      },
    });
    return res as RootSubBusiness;
  } catch (error: any) {
    console.log("Error", error);
    return error.response as RootSubBusiness;
  }
};

// subBusiness create end

// Api for subBusinessUpdate started

export interface RootSubBusinessProfileUpdate {
  status: number;
  data: RootSingleSubBusiness;
}
export interface formData {
  name: string;
  description: string;
}
export interface RootSingleSubBusiness {
  message: string;
  results: ResultSingleSubBusiness;
}

export interface ResultSingleSubBusiness {
  subCategories: SubCategory[];
  companyVerification: boolean;
  socialAccounts: any[];
  _id: string;
  address: string;
  city: string;
  businessName: string;
  email: string;
  registrationDate: any;
  registrationNumber: string;
  profileFile: string;
  registerationFile: string;
  location: string;
  ownerName: string;
  phoneNumber: string;
  accountName: string;
  accountNumber: string;
  bankName: string;
  branchName: string;
  bankCode: string;
  personInChargePhone: string;
  personInChargeName: string;
  personInChargeEmail: string;
  zone: string;
  area: string;
  connectedHandle: string;
  registerationFileUrl: string;
  profileFileUrl: string;
  iban: any[];
  otp: number;
}

export interface SubCategory {
  index: number;
  subCategories: SubCategory2[];
  _id: string;
  name: string;
  category: string;
  userHandle: string;
  slug: string;
  __v: number;
  percentage: number;
  deleteStatus?: boolean;
}

export interface SubCategory2 {
  index: number;
  subCategories: any[];
  _id: string;
  name: string;
  category: string;
  mainCategory: string;
  userHandle: string;
  slug: string;
  __v: number;
  percentage?: number;
}

export const putSubBusiness = async (
  data: FormData
): Promise<RootSubBusinessProfileUpdate> => {
  try {
    const res = await axios.put(
      baseUrl + "business/updateProfileSubusiness",
      data,
      {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")!).token,
        },
      }
    );
    return res as RootSubBusinessProfileUpdate;
  } catch (error: any) {
    console.log("Error", error);
    return error.response as RootSubBusinessProfileUpdate;
  }
};

// API for subBusinessUpdate Ended

// API for profileComplete Alert started

export interface RootAlert {
  message: string;
  results: boolean;
}

export const ProfileAlert = async (): Promise<RootAlert> => {
  try {
    const res = await axios.get(baseUrl + "business/profileComplete", {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("token")!).token,
      },
    });
    return res.data as RootAlert;
  } catch (error: any) {
    console.log("Error", error);
    return error.response.data as RootAlert;
  }
};

// API for profileComplete Alert Ended
