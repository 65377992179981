import axios from "axios";
import { baseUrl } from ".";
import { Post } from "./post";

/* API for gig add Start */
export interface RootGig {
  status: number;
  data: RootSingleGig;
}
export interface RootSingleGig {
  message: string;
  results: ResultsSingleGig;
}
export interface ResultsSingleGig {
  tags: any[];
  skills: any[];
  multiplePost: boolean;
  _id: string;
  title: string;
  description: string;
  gigType: string;
  userHandle: string;
  numberofcreator: number;
  numberofimage: number;
  numberofvideo: number;
  level: string;
  submissionTimeframe: number;
  adDuration: number;
  offerAmountFrom: number;
  offerAmount: number;
  businessHandle: string;
  businessName: string;
  numberengagements: number;
  numberviews: number;
  requirePosting: boolean;
  searchCreator: number;
  status: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
  connectedTo: string;
}

export const addGig = async (data: FormData): Promise<RootGig> => {
  try {
    const res = await axios.post(baseUrl + "gig/create", data, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("token")!).token,
      },
    });

    return res as RootGig;
  } catch (error: any) {
    console.log("Error", error);
    return error.response as RootGig;
  }
};

/* API for gig add Ended */

// API for gig Details Started
export interface RootObject {
  status: number;
  data: {
    message: string;
    results: GigUser[];
    pagination: Pagination;
    gig: Gig;
    subTask: any[];
    posts: Post[];
  };
}
export interface Gig {
  tags: any[];
  skills: any[];
  multiplePost: boolean;
  _id: string;
  title: string;
  description: string;
  gigType: string;
  action: string;
  canApply: boolean;
  userHandle: string;
  numberofcreator?: any;
  level: string;
  businessHandle: string;
  businessName: string;
  status: boolean;
  createdAt: string;
  updatedAt: string;
  numberofimage: number;
  numberofvideo: number;
  __v: number;
  searchCreator: number;
  attachment: any[];
  isFavorite: boolean;
  adDuration: number;
  submissionTimeframe: number;
  offerAmountFrom: number;
  offerAmount: number;
  offerAmountTo: number;
  specifications: any[];
  requirePosting: boolean;
  product: {
    productName: string;
    image: string;
    price: number;
    _id: string;
  };
}

export interface GigUser {
  buseinss: any;
  gigStatus: string;
  user: {
    profileFile: string;
    followers: number;
    fullname: string;
    _id: string;
    skills?: Array<string>;
    userHandle:string;
  };
  _id: string;
  action: string;
  duration: number;
  quantity: number;
  connectedTo: string;
  subTask: boolean;
}

export interface Pagination {
  total: number;
}

export interface GigProps {
  id: string;
  limit: number;
  gigStatus?: string;
  page: number;
  specifications?: string;
}

export const GigDetails = async (data: GigProps): Promise<RootObject> => {
  try {
    const res = await axios.post(baseUrl + "gig/gigAppliedList", data, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("token")!).token,
      },
    });
    return res as RootObject;
  } catch (error: any) {
    console.log("Error", error);
    return error.response as RootObject;
  }
};


export const GigDetailsUserList = async (data: GigProps): Promise<RootObject> => {
  try {
    const res = await axios.post(baseUrl + "gig/gigAppliedUserList", data, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("token")!).token,
      },
    });
    return res as RootObject;
  } catch (error: any) {
    console.log("Error", error);
    return error.response as RootObject;
  }
};

export const GigDetailsPosts = async (data: GigProps): Promise<RootObject> => {
  try {
    const res = await axios.post(baseUrl + "gig/gigContentSubmmited", data, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("token")!).token,
      },
    });
    return res as RootObject;
  } catch (error: any) {
    console.log("Error", error);
    return error.response as RootObject;
  }
};


export const GigApprove = async (data: {
  id: string;
  gigStatus: string;
}): Promise<RootObject> => {
  try {
    const res = await axios.post(baseUrl + `gig/approveProposal`, data, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("token")!).token,
      },
    });
    return res as RootObject;
  } catch (error: any) {
    console.log("Error", error);
    return error.response as RootObject;
  }
};

export const GigAccept = async (data: {
  id: string;
  payment: number;
}): Promise<RootObject> => {
  try {
    const res = await axios.post(baseUrl + `gig/acceptForApprval`, data, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("token")!).token,
      },
    });
    return res as RootObject;
  } catch (error: any) {
    console.log("Error", error);
    return error.response as RootObject;
  }
};

// API for gig Details Ended

// API for gig List started
export interface RootGigList {
  status: Number;
  data: {
    message: string;
    results: ResultGigList[];
    pagination: PaginationProps;
  };
}

export interface ResultGigList {
  tags: string[];
  skills: string[];
  multiplePost: boolean;
  _id: string;
  title: string;
  description: string;
  gigType: string;
  userHandle: string;
  numberofcreator: number;
  numberofimage?: number;
  numberofvideo?: number;
  submissionTimeframe: number;
  adDuration: number;
  offerAmountFrom: number;
  level: string;
  businessHandle: string;
  businessName: string;
  numberengagements: number;
  numberviews: number;
  requirePosting: boolean;
  status: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
  attachment: any[];
  gigApplied: number;
  isFavorite: boolean;
  connectedTo?: string;
}

export interface PaginationProps {
  total: number;
  next?: number;
  previous?: number;
}
export interface gigList {
  page: number;
  limit: number;
  title?: string;
  numberofcreator?: string;
  gigType?: string;
  numberofimage?: number;
  numberofvideo?: number;
  submissionTimeframe?: number;
  adDuration?: number;
  numberviews?: number;
  numberengagements?: number;
}

export interface gigDelete {
  _id: string;
  page: number;
}

export const GigListPost = async (data: gigList): Promise<RootGigList> => {
  try {
    const res = await axios.post(baseUrl + "gig/gigList", data, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("token")!).token,
      },
    });
    return res as RootGigList;
  } catch (error: any) {
    console.log("Error", error);
    return error.response as RootGigList;
  }
};

export const GigDelete = async (data: gigDelete): Promise<RootGigList> => {
  try {
    const res = await axios.delete(baseUrl + `gig/delete/${data._id}`, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("token")!).token,
      },
    });
    return res as RootGigList;
  } catch (error: any) {
    console.log("Error", error);
    return error.response as RootGigList;
  }
};
// API for gig list Ended

export interface GigUserListProps {
  message: string;
  results: GigUserListReultProps[];
}

export interface GigUserListReultProps {
  chatRoom: string;
  _id: string;
  userOne: ChatUser;
  userTwo: ChatUser;
  createdAt: Date;
  updatedAt: Date;
  __v: number;
  count: number;
  lastMessage: string;
}

export interface ChatUser {
  fullname: string;
  email: string;
  phoneNumber: string;
  blockStatus: boolean;
  profileFile: string;
  userHandle: string;
  blockedBy: string;
  _id?: string;
}

export const GigChatUserList = async (
  userHandle: string
): Promise<GigUserListProps> => {
  try {
    const res = await axios.post(
      baseUrl + `chat/chatUserListGig`,
      {
        userHandle,
      },
      {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")!).token,
        },
      }
    );
    return res.data as GigUserListProps;
  } catch (error: any) {
    console.log("Error", error);
    return error.response.data as GigUserListProps;
  }
};


export interface ResulUsertChatsListProps {
  message: string;
  results: ResulUsertChatsProps[];
}

export interface ResulUsertChatsProps {
  _id: string;
  chatId: string;
  sendBy: ChatUser;
  receivedBy: ChatUser;
  message: string;
  createdAt: Date;
  updatedAt: Date;
  __v: number;
}



export const GigChats = async (
  chatId: string
): Promise<ResulUsertChatsListProps> => {
  try {
    const res = await axios.post(
      baseUrl + `chat/chatsUserGig`,
      {
        chatId,
      },
      {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")!).token,
        },
      }
    );
    return res.data as ResulUsertChatsListProps;
  } catch (error: any) {
    console.log("Error", error);
    return error.response.data as ResulUsertChatsListProps;
  }
};