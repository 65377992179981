import { RxCross2 } from "react-icons/rx";

interface Props {
  tags?: string;
  tagUrls?: { _id: string; tagName: string };
  index: number;
  action: (index: number) => void;
}
const TagsPreview = ({ tags, action, index, tagUrls }: Props) => {
  return (
    <div className=" flex justify-between items-center bg-gray-200 p-1 mt-2 rounded-md shadow-md">
      {tags && <p>{tags}</p>}
      {tagUrls && <p>{tagUrls.tagName}</p>}

      <span
        className=" p-1 rounded-full cursor-pointer  right-[-3px]"
        onClick={() => action(index)}
      >
        <RxCross2 size={15} className="text-gray-600" />
      </span>
    </div>
  );
};

export default TagsPreview;
