import { Button } from "@material-tailwind/react";
import { FiSearch } from "react-icons/fi";
interface FilterProps {
  setBookingStatus: (bookingStatus: string | undefined) => void;
  setBookingSearch: (search: string | undefined) => void;
  handleFilter: () => void;
}

const BookingFilter = ({
  setBookingSearch,
  setBookingStatus,
  handleFilter,
}: FilterProps) => {
  return (
    <section>
      <div>
        <div className="flex items-center gap-3 bg-white p-2 rounded-md shadow-sm">
          <div className="w-full">
            <input
              type="text"
              placeholder="Search"
              className="py-[0.7rem] px-2 input outline-none rounded-md font-Roboto w-full border-2"
              onInput={(e) => setBookingSearch(e.currentTarget.value)}
            />
          </div>
          <div className="w-80 ">
            <select
              onChange={(e) => setBookingStatus(e.target.value)}
              className="border-2 py-3 outline-none w-full px-2 rounded-md"
            >
              <option value="">All</option>
              <option value="Pending">Pending</option>
              <option value="Completed">Completed</option>
              <option value="Cancelled">Cancelled</option>
            </select>
          </div>

          <div className=" ">
            <Button
              className="bg-bluePostaam-600 w-full h-[45.8px] rounded-[5px] lg:w-full hover:shadow-none font-Roboto flex justify-center items-center"
              onClick={handleFilter}
            >
              <FiSearch size={23} />
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};
export default BookingFilter;
