import { RxCross2 } from "react-icons/rx";

interface Props {
  videoUrl?: string;
  image?: string;

  index: number;
  action: (index: number) => void;
}
const ImageVideoPreview = ({ image, videoUrl, action, index }: Props) => {
  return (
    <div className="relative">
      {videoUrl && (
        <video
          src={videoUrl}
          className="w-32 h-32 rounded-md cursor-pointer"
          controls
        />
      )}
      {image && (
        <img
          src={image}
          alt="image"
          className="w-32 h-32 rounded-md cursor-pointer"
        />
      )}
      <span
        className="absolute top-[-5px] p-1 rounded-full cursor-pointer bg-red-500 right-[-3px]"
        onClick={() => action(index)}
      >
        <RxCross2 size={15} color="white" />
      </span>
    </div>
  );
};

export default ImageVideoPreview;
