import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  IconButton,
} from "@material-tailwind/react";
import { FaPlus } from "react-icons/fa";

interface PopupFormData {
  title: string;
  amount: number;
  withdrawAmount: number;
}

interface PopupProps {
  page: string;
}

export function Popup({ page }: PopupProps) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(!open);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<PopupFormData>({
    defaultValues: {
      title: "",
    },
  });

  const onSubmit: SubmitHandler<PopupFormData> = (data) => {
    console.log(data);
    // Handle form submission here
    handleOpen();
    reset();
  };

  return (
    <>
      {page === "topup" && (
        <IconButton
          onClick={handleOpen}
          className="w-[35px] h-[35px] bg-addButton hover:bg-indigo-400 outline-none"
        >
          <FaPlus size={20} />
        </IconButton>
      )}

      {page === "transactions" && (
        <Button className="bg-bluePostaam-600 rounded-md" onClick={handleOpen}>
          Withdraw Request
        </Button>
      )}
      <Dialog
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        size="sm"
        open={open}
        handler={handleOpen}
        className="p-4 transition-transform duration-100"
      >
        <DialogHeader>
          {page === "topup" ? "Send Topup" : "Withdraw Request"}
        </DialogHeader>
        <DialogBody className="space-y-4 pb-6">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="formStyle w-full flex flex-col gap-6"
          >
            {page === "topup" && (
              <div className="w-full">
                <label htmlFor="title" className="block mb-2">
                  Title<span className="text-red-500">*</span>:
                </label>
                <input
                  type="text"
                  id="title"
                  {...register("title", {
                    required: "Title is required",
                    maxLength: {
                      value: 100,
                      message: "Title must be less than 100 characters",
                    },
                  })}
                  className={`w-full bg-[#e9e9e9] h-16 px-3 rounded-md ${
                    errors.title ? "border-red-500 border" : ""
                  }`}
                />
                {errors.title && (
                  <p className="text-red-500 mt-1 text-sm">
                    {errors.title.message}
                  </p>
                )}
              </div>
            )}

            <div className="w-full">
              <label htmlFor="amount" className="block mb-2">
                Amount<span className="text-red-500">*</span>:
              </label>
              {page === "topup" && (
                <input
                  type="number"
                  id="amount"
                  {...register("amount", {
                    required: "Amount is required",
                    validate: (value) => {
                      const numericValue = Number(value); // Convert to number
                      if (!isNaN(numericValue)) {
                        return true;
                      } else {
                        return "Only numbers are allowed."; // Validation message for non-numeric input
                      }
                    },
                  })}
                  className={`w-full bg-[#e9e9e9] h-16 px-3 rounded-md ${
                    errors.amount ? "border-red-500 border" : ""
                  }`}
                />
              )}
              {page === "transactions" && (
                <input
                  type="number"
                  id="amount"
                  {...register("withdrawAmount", {
                    required: "Withdraw Amount is required",
                    validate: (value) => {
                      const numericValue = Number(value); // Convert to number
                      if (!isNaN(numericValue)) {
                        if (numericValue >= 1000) {
                          return true; // Valid input
                        } else {
                          return "Withdraw amount must be greater than 1000."; // Custom validation message
                        }
                      } else {
                        return "Only numbers are allowed."; // Validation message for non-numeric input
                      }
                    },
                  })}
                  className={`w-full bg-[#e9e9e9] h-16 px-3 rounded-md ${
                    errors.withdrawAmount ? "border-red-500 border" : ""
                  }`}
                />
              )}

              {errors.amount && (
                <p className="text-red-500 mt-1 text-sm">
                  {errors.amount.message}
                </p>
              )}
              {errors.withdrawAmount && (
                <p className="text-red-500 mt-1 text-sm">
                  {errors.withdrawAmount.message}
                </p>
              )}
            </div>

            <DialogFooter className="px-0">
              <div className="flex items-center justify-between gap-5 w-full">
                <Button
                  type="submit"
                  ripple={false}
                  className="bg-bluePostaam-600 w-1/2 text-sm capitalize tracking-wider py-5"
                >
                  {page === "topup" ? "Pay Now" : "request"}
                </Button>

                <Button
                  type="button"
                  ripple={false}
                  onClick={() => {
                    handleOpen();
                    reset();
                  }}
                  className="bg-gray-200 text-gray-900 w-1/2 text-sm tracking-wider py-5"
                >
                  Cancel
                </Button>
              </div>
            </DialogFooter>
          </form>
        </DialogBody>
      </Dialog>
    </>
  );
}
