import { Button } from "@material-tailwind/react";
import { FiSearch } from "react-icons/fi";
interface FilterProps {
  setProductName: (productName: string | undefined) => void;
  handleFilter: () => void;
}

const Filter = ({ setProductName, handleFilter }: FilterProps) => {
  return (
    <section>
      <div className="bg-white mb-3 p-3 rounded-md shadow-sm">
        <div className="flex items-center gap-3">
          <div className="w-full ">
            <input
              type="text"
              placeholder="Product Name"
              className="py-2 px-2 input outline-none rounded-md font-Roboto w-full border-2"
              onInput={(e) => setProductName(e.currentTarget.value)}
            />
          </div>

          <div className=" ">
            <Button
              className="bg-bluePostaam-600 w-full h-[43.8px] rounded-[5px] lg:w-full hover:shadow-none font-Roboto flex justify-center items-center"
              onClick={handleFilter}
            >
              <FiSearch size={23} />
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Filter;
